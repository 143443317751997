
const {useState, useEffect} = require('react');
const jwt_decode = require("jwt-decode").default;
const { getSettings } = require("../PNConfig");


// Return the current user token.
const getUserToken = () => {

    return new Promise(async (res, rej) => {

        try {
            if (typeof localStorage == 'object') {
                res(localStorage.getItem('_pn_241031_usertoken'))
            } else {
                res(undefined)
            }
        } catch(e) {
            rej(e)
        }
    })
}

const getUser = () => {
    return new Promise(async (res) => {
        try {
            res(jwt_decode(await getUserToken()))
        } catch(e) {
            res(undefined)
        }
    })
}

const useUser = () => {

    const [user, setUser] = useState()

    useEffect(async () => {
        try {
            setUser(jwt_decode(await getUserToken()));
        } catch(e) {
            if (e.message == 'Invalid token specified') {
                setUser(undefined)
            } else {
                throw new Error(e);
            }
        }
    }, [])

    return user;
}

module.exports = {
    getUserToken,
    getUser,
    useUser
}