import React, { createContext, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import MediaLibrary from '../layout/popups/MediaLibrary';
import usePopup from '../utils/hooks/usePopup';
import Icon from './Icon';

export default function ImageSelector({ onChange }) {
	const [open] = usePopup();

	const [image, setImage] = useState();

	useEffect(() => {
		if (onChange && image) onChange(image);
	}, [image]);

	return (
		<Style
			className={`${image ? 'has-image' : ''}`}
			onClick={() => {
				open(
					<MediaLibrary
						onChange={async (image) => {
							setImage(image);
						}}
					/>
				);
			}}
		>
			{!image && (
				<>
					<Icon>add</Icon>
					<h4>Lägg till bild..</h4>
				</>
			)}

			{image && (
				<>
					<img src={image?.sizes['1920']} alt="" />
				</>
			)}
		</Style>
	);
}

const Style = styled.div`
	background: red;
	background: #f5f5f5;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 10em !important;
	cursor: pointer;
	border-radius: 0.35em;
	position: relative;

	.icon {
		font-size: 2em;
	}

	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: inherit;
		transition: filter 300ms;
	}

	&.has-image {
		&:hover {
			img {
				filter: brightness(0.5);
			}
		}
	}
`;
