import React, { useContext, useEffect, useState } from 'react';
import Page from '../../layout/Page';
import Card from '../../layout/Card';
import styled from 'styled-components';
import AuctionSelect from '../../components/auction/AuctionSelect';
import { useHistory } from 'react-router-dom';
import SellerSelect from '../../components/sellers/SellerSelect';
import Button from '../../elements/Button';

export default function PageLists(props) {
	const history = useHistory();

	return (
		<Style>
			<Card size="full">
				<h2>Listor</h2>
				<div className="spacer s"></div>
				<p>
					Här är de olika listorna samlade för att kunna genomföra <br />
					exempelvis avprickningar eller sammanställningar.
				</p>
				<div className="spacer m"></div>

				<div className="tick-off">
					<h3>Auktionssummering</h3>
					<div className="spacer s"></div>
					<AuctionSelect
						onChange={(e) => {
							history.push(`/lists/auction-summary/${e.value}`);
						}}
					/>
				</div>

				<div className="spacer m"></div>

				<div className="tick-off">
					<h3>Avprickning</h3>
					<div className="spacer s"></div>
					<AuctionSelect
						onChange={(e) => {
							history.push(`/lists/tick-off/${e.value}`);
						}}
					/>
				</div>

				<div className="spacer m"></div>

				<div className="ticked">
					<h3>Avprickning Sammanställning</h3>
					<div className="spacer s"></div>
					<AuctionSelect
						onChange={(e) => {
							history.push(`/lists/ticked/${e.value}`);
						}}
					/>
				</div>

				<div className="spacer m"></div>

				<div className="ticked">
					<h3>Utropslista</h3>
					<div className="spacer s"></div>
					<AuctionSelect
						onChange={(e) => {
							history.push(`/lists/exclamation/${e.value}`);
						}}
					/>
				</div>

				<div className="spacer m"></div>

				<div className="ticked">
					<h3>Etikettlista</h3>
					<div className="spacer s"></div>
					<div className="row">
						<AuctionSelect
							onChange={(e) => {
								history.push(`/lists/labels/${e.value}`);
							}}
						/>
						<div className="spacer ws"></div>
						<button onClick={() => history.push('/lists/labels')} style={{flexShrink: 0}}>Tomma etiketter</button>
					</div>
				</div>

				<div className="spacer m"></div>

				<div className="ticked">
					<h3>Projektsummering</h3>
					<div className="spacer s"></div>
					<SellerSelect
						onChange={(e) => {
							history.push(`/lists/project-summary/${e.value}`);
						}}
					/>
				</div>

				<div className="spacer m"></div>

				<div className="ticked">
					<h3>Projektsummering (Gamla)</h3>
					<div className="spacer s"></div>
					<SellerSelect
						onChange={(e) => {
							history.push(`/lists/old-project-summary/${e.value}`);
						}}
					/>
				</div>
			</Card>
		</Style>
	);
}

const Style = styled(Page)``;
