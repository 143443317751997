import React from 'react';
import styled from 'styled-components';
import Icon from '../../elements/Icon';
import DeleteBid from '../../layout/popups/bids/DeleteBid';
import useAutoBids from '../../utils/bids/useAutoBids';
import useBids from '../../utils/bids/useBids';
import usePopup from '../../utils/hooks/usePopup';
import { formatPrice, isObjectEnded } from '../../utils/Util';

export default function BiddingPanel({ object }) {
	const bids = useBids(object);
	const maxBids = useAutoBids(object);

	const [open] = usePopup();

	let leadingBid = bids?.[0];
	let leadingMaxBid = maxBids?.[0];

	if (object.ending_data != undefined && object.ending_data.lastBid != undefined) {
		leadingBid = object.ending_data.lastBid;
	}

	return (
		<Style className="bidding-panel">
			<div className="leading-bid">
				<label>{isObjectEnded(object) ? 'vinnande bud' : 'ledande bud'}</label>
				<div className="value">{formatPrice(leadingBid?.amount)}</div>
			</div>
			<div className="leading-max-bid">
				<label>högsta maxbud</label>
				<div className="value">{formatPrice(leadingMaxBid?.amount)}</div>
			</div>
			<div className="bidding-table">
				<label>budgivning</label>
				<table className="bids" cellSpacing={0}>
					<thead>
						<tr>
							<th>Budgivare</th>
							<th>Tid</th>
							<th>Summa</th>
							<th></th>
						</tr>
					</thead>

					<tbody>
						{bids?.map((bid) => (
							<tr key={bid.id} className="bid">
								<td className="username">
									<span>{bid.username}</span>
									{bid.auto != false && <span className="auto">Höjning genom autobud</span>}
								</td>
								<td>{new Date(bid.time_placed).toJSON().replace('T', ' ').substr(0, 19)}</td>
								<td>{formatPrice(bid.amount)}</td>
								<td>{!object.ending_data && <Icon onClick={() => open(<DeleteBid bid={bid} />)}>delete</Icon>}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</Style>
	);
}

const Style = styled.div`
	.bids {
		th {
			text-align: left;
			padding-bottom: 1rem;
		}

		.bid {
			.username {
				height: 3.5rem;
				display: flex;
				flex-direction: column;
				justify-content: center;

				.auto {
					margin-top: 0.1rem;
					font-size: 0.75rem;
					color: #aaa;
				}
			}

			&:not(:last-child) td {
				border-bottom: solid 1px #eee;
			}
		}
	}
`;
