import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import API from '../../../utils/api/API';
import Button from '../../../elements/Button';
import Popup from '../../Popup';
import { useClosePopup } from '../../../utils/hooks/usePopup';
import Objects from '../../../utils/api/Objects';
import Images from '../../../utils/api/Images';
import Auctions from '../../../utils/api/Auctions';
import ImageSelector from '../../../elements/ImageSelector';
import { Context } from '../../../App';
import Customers from '../../../utils/api/Customers';
import CustomerSelect from '../../../components/CustomerSelect';
import PNTrading from 'src/pn-trading-sdk';

export default function CreateTrustee({ customer }) {
	let history = useHistory();
	const close = useClosePopup();

	const [error, setError] = useState({ message: '', visible: false });
	const [selectedCustomer, setSelectedCustomer] = useState(customer);

	const submit = async (e) => {
		e.preventDefault();
		let form = e.target;

		if (selectedCustomer == undefined) {
			setError({
				message: `Du måste välja en säljare som förvaltaren tillhör!`,
				field: 'customer',
				visible: true,
			});
			return;
		}

		let data = Object.fromEntries(new FormData(e.target).entries());

		let result = await PNTrading.post(`/customers/${selectedCustomer}/trustees`, data);

		if (result) {
			let cache = API.getCached(`/customers_`);
			if (cache) {
				cache.result = [...cache.result, result];
				API.updateCache(`/customers_`, cache);
			}
			close();
			window.location.reload();
		}
	};

	return (
		<Popup close={close} width="40em" maxWidth="80em">
			<Style onSubmit={submit}>
				<h2 className="title">Lägg till förvaltare</h2>
				<div className="spacer m"></div>

				<div className="fields">
					<label>Uppdragsgivare *</label>
					<br />
					<CustomerSelect defaultValueID={customer} onChange={({ value }) => setSelectedCustomer(value)} />
					<div className="spacer s"></div>

					<label htmlFor="name">Namn *</label>
					<br />
					<input required type="text" name="name" />
					<div className="spacer s"></div>

					<label htmlFor="address">Adress *</label>
					<br />
					<input required type="text" name="address" />
					<div className="spacer s"></div>

					<label htmlFor="zip">Postnummer *</label>
					<br />
					<input required type="text" name="zip" />
					<div className="spacer s"></div>

					<label htmlFor="city">Postort *</label>
					<br />
					<input required type="text" name="city" />
					<div className="spacer s"></div>

					<div className="spacer m"></div>

					<label htmlFor="phone">Telefonnummer *</label>
					<br />
					<input required type="text" name="phone" />
					<div className="spacer s"></div>

					<label htmlFor="email">E-post *</label>
					<br />
					<input required type="email" name="email" />
					<div className="spacer s"></div>

					{error?.visible && (
						<>
							<div className="spacer xs"></div>
							<p className="label error">{error?.message}</p>
						</>
					)}
				</div>

				<div className="spacer s"></div>

				<div className="buttons">
					<Button type={'secondary'} onClick={close}>
						Avbryt
					</Button>
					<input className="button" type="submit" value="Spara" />
				</div>
			</Style>
		</Popup>
	);
}

const Style = styled.form`
	.fields {
		width: 100%;

		input,
		textarea {
			width: 100%;
			resize: none;
		}

		.error {
			color: rgb(255, 161, 161);
		}
	}
`;
