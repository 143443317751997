import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import API from '../../utils/api/API';
import Button from '../../elements/Button';
import Popup from '../Popup';
import { useClosePopup } from '../../utils/hooks/usePopup';
import Objects from '../../utils/api/Objects';
import Images from '../../utils/api/Images';
import Auctions from '../../utils/api/Auctions';
import ImageSelector from '../../elements/ImageSelector';
import { Context } from '../../App';

export default function CreateAuction({}) {
	let history = useHistory();
	const close = useClosePopup();

	const [image, setImage] = useState();

	const { setAuctions } = useContext(Context);

	const submit = async (e) => {
		e.preventDefault();
		let form = e.target;

		let name = form.querySelector('input[name="auction_name"]').value;
		let description = form.querySelector('textarea[name="auction_description"]').value;

		let data = {
			name,
			description,
		};

		// If theres an image uploaded, insert it to the body.
		if (image) data.image = image.id;

		let result = await Auctions.create(data);

		if (result) {
			let cache = API.getCached(`/auctions_`);
			if (cache) {
				cache.result = [...cache.result, result];
				setAuctions(cache.result);
				API.updateCache(`/auctions_`, cache);
			}
			close();
			history.push(`/auctions/${result.id}`);
		}
	};

	return (
		<Popup close={close} width="40em" maxWidth="80em">
			<Style onSubmit={submit}>
				<h2 className="title">Skapa auktion</h2>
				<div className="spacer m"></div>

				<div className="fields">
					<label htmlFor="auction_name">Namn</label>
					<br />
					<input required type="text" name="auction_name" />

					<div className="spacer s"></div>

					<label htmlFor="auction_description">Beskrivning</label>
					<br />
					<textarea rows={8} name="auction_description"></textarea>
				</div>

				<div className="spacer s"></div>

				<div className="buttons">
					<Button type={'secondary'} onClick={close}>
						Avbryt
					</Button>
					<input className="button" type="submit" value="Spara" />
				</div>
			</Style>
		</Popup>
	);
}

const Style = styled.form`
	.fields {
		width: 100%;

		input,
		textarea {
			width: 100%;
			resize: none;
		}
	}
`;
