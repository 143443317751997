import React from 'react';
import { Event, EventStatus, EventType, updateEvent } from "src/utils/api/employees/Events";
import { X as DayHeaderContentArg } from "@fullcalendar/core/internal-common";
import { Employee, getWorkingHoursDuration } from 'src/utils/api/employees/Employees';
import { useUser } from 'src/pn-trading-sdk/utils/UserToken';

const ABSENCE_TYPES = [
    EventType.PARENTAL_LEAVE,
    EventType.SICK_LEAVE,
    EventType.VACATION
];

export default function EmployeeCalendarDayHeader({
    args,
    events,
    employee,
    setEvents
}: Props) {

    const user = useUser() as any;
    const employeeAdmin = user?.permissions.split(',')?.includes('employee_admin') as boolean;
    
    const dateSpan = {
        start: args.date,
        end: new Date(args.date.getTime() + 24 * 60 * 60 * 1000)
    }

    const dayEvents = events.filter(e => {
        return e.start >= dateSpan.start && e.end <= dateSpan.end;
    });

    const allEventsApproved = dayEvents.every(e => e.status === 'approved');

    const scheduledHours = getWorkingHoursDuration(employee, args.date) / 60
    const reportedHours = dayEvents.reduce((acc, e) => {
        const time = (e.end.getTime() - e.start.getTime()) / 1000 / 60 / 60;
        return acc + time - e.breakTime / 60;
    }, 0);
    const absenceHours = dayEvents.filter(e => ABSENCE_TYPES.includes(e.type)).reduce((acc, e) => acc + (e.end.getTime() - e.start.getTime()) / 1000 / 60 / 60, 0);
    const billableHours = dayEvents.reduce((acc, e) => acc + e.billableTime, 0) / 60;
    const unreportedHours = scheduledHours - reportedHours;

    async function handleApproveAll() {
        if (!employeeAdmin) return;
        
        const status = allEventsApproved ? EventStatus.PENDING : EventStatus.APPROVED;
        const updatedEvents = dayEvents.map(e => {
            return {
                ...e,
                status
            }
        });

        await Promise.all(updatedEvents.map(e => updateEvent(employee.id, e.id, e)));
        setEvents((events) => events.map(e => updatedEvents.find(u => u.id === e.id) || e));
    }

    return (
        <>
            <table>
                <tbody>
                    <tr>
                        <th colSpan={2}>
                            <span className="date-title-wrapper">
                                {args.date.toLocaleDateString('sv', {
                                    weekday: 'long',
                                    day: 'numeric',
                                    month: 'numeric'
                                })}
                            </span>
                        </th>
                    </tr>
                    {employeeAdmin && <tr>
                        <th colSpan={2}>
                            {dayEvents.length > 0 ? <button onClick={handleApproveAll} disabled={dayEvents.length === 0} className={`approve-all ${allEventsApproved ? 'outlined' : ''}`}>
                                {allEventsApproved ? 'Attesterade' : 'Attestera'}
                            </button> : <button className="outlined" disabled>Inga händelser</button>}
                        </th>
                    </tr>}
                    <tr>
                        <td>
                            SCH:
                        </td>
                        <td>
                            {scheduledHours.toFixed(1)} h
                        </td>
                    </tr>
                    <tr>
                        <td>
                            RAP:
                        </td>
                        <td>
                            {reportedHours.toFixed(1)} h
                        </td>
                    </tr>
                    <tr>
                        <td>
                            FRV:
                        </td>
                        <td>
                            {absenceHours.toFixed(1)} h
                        </td>
                    </tr>
                    <tr className={`absence ${unreportedHours < 0 ? 'positive' : (unreportedHours <= 0 ? '' : 'negative')}`}>
                        <td>
                            AVV:
                        </td>
                        <td>
                            {-unreportedHours.toFixed(1)} h
                        </td>
                    </tr>
                    <tr>
                        <td>
                            DEB:
                        </td>
                        <td>
                            {billableHours.toFixed(1)} h
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

type Props = {
    events: Event[];
    employee: Employee;
    args: DayHeaderContentArg;
    setEvents: React.Dispatch<React.SetStateAction<Event[]>>;
}