import React, { useContext, useEffect, useState } from 'react';
import Table from '../components/Table';
import Page from '../layout/Page';
import Card from '../layout/Card';
import { Context } from '../App';
import Icon from '../elements/Icon';
import usePopup from '../utils/hooks/usePopup';
import DeleteObject from '../layout/popups/DeleteObject';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import Button from '../elements/Button';
import CreateObject from '../layout/popups/CreateObject';
import Objects from '../utils/api/Objects';
import CategoryTable from '../components/CategoryTable';
import PNTrading from 'src/pn-trading-sdk';

export default function PageCategories(props) {
	let categorySlugs = props.match.params.category?.split('/');

	console.log(props.match.url);
	let parent = 0;

	const [categories, setCategories] = useState([]);

	useEffect(async () => {
		let cats = await PNTrading.get(`/categories`);

		cats = cats.filter((c) => c.parent == parent || (c.parent == undefined && parent == 0));

		setCategories(cats);
	}, []);

	return (
		<Style>
			<Card size="full">
				{JSON.stringify(categorySlugs)}

				<div className="table main-table">
					{categories.map((category) => (
						<div className="row">
							<Link to={`${props.match.url}/${category.id}`}>
								<a className="row-content">
									<h3 className="title">{`${category.id} - ${category.name}`}</h3>
								</a>
							</Link>
						</div>
					))}
				</div>
			</Card>
		</Style>
	);
}

const Style = styled(Page)`
	.key-values {
		h1 {
			font-size: 2.5em;
		}
	}

	.header {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	.row-icons {
		div {
			display: flex;
			justify-content: flex-end;
			font-size: 1.5em;

			.icon {
				margin-left: 0.5em;
			}
		}
	}

	.table.main-table {
		margin-left: 0;
	}

	.table {
		// border: red dashed 1px;

		margin-left: 2.5em;

		.row {
			display: flex;
			flex-direction: column;

			.row-content {
				max-width: 40em;
				background: #fbfbfb;
				border: solid 1px #eee;
				margin: 0.5em 0;
				padding: 1em;
				display: flex;
				align-items: center;
				cursor: pointer;

				&:hover {
					background: #f5f5f5;
				}

				.nav {
					display: flex;
					flex-direction: column;
				}

				.title {
					margin-left: 0.75rem;
				}

				.icon {
					width: 0.7em;
					height: 0.7em;
					display: flex;
					align-items: center;
					justify-content: center;
					opacity: 0.5;
					transition: opacity 150ms;
					font-size: 2.5em;

					&:hover {
						opacity: 1;
					}
				}
			}
		}
	}
`;
