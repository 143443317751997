import { useEffect, useState } from "react";
import { Expense, ExpenseListArgs, listExpenses } from "src/utils/api/expenses/Expenses";

export default function useExpenses(args?: ExpenseListArgs) {

    const [expenses, setExpenses] = useState<Expense[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const resp = await listExpenses(args);
            setExpenses(resp.expenses);
            setLoading(false);
        })();
    }, [args?.event_id, args?.user_id]);

    return {
        expenses,
        loading
    };
}