import { useEffect, useState } from 'react';
import API from './API';

export type Trustee = {
	id: number,
	name: string,
	address: string,
	zip: string,
	city: string,
	phone: string,
	mobile_phone: string,
	email: string,
	added: string,
	customer: number,
};

export type Seller = {
	id: number,
	name: string,
	org_number: string,
	vat_number: string,
	bank_account_number: string,
	notes: string,
	trustee: Trustee
	added: string,
	slug: string,
};

export default class Sellers {
	static async list({ page = 0, limit = 25, search = undefined, noCache = false } = { page: 0, limit: 25 }) {
		return await API.get(`/sellers?page=${page}&limit=${limit}${search ? `&search=${search}` : ''}${noCache ? `&noCache=true` : ''}`);
	}

	static async get(id: any) {
		return await API.get(`/sellers/${id}`);
	}

	static async create(data: any) {
		return await API.post(`/sellers`, data);
	}

	static async delete(id: any) {
		return await API.delete(`/sellers/${id}`);
	}
}

export function useSellers() {
	const [sellers, setSellers] = useState();

	useEffect(() => {
		(async () => {
			let resp = await Sellers.list({ limit: 10000 });
			setSellers(resp.data);
		})()
	}, []);

	return {
		sellers,
		setSellers,
	};
}
