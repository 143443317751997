import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './style/style.css';
import 'bp-date-util';
import { init } from 'src/pn-trading-sdk/PNConfig';

init({
	PN_SDK_RUNTIME: 'webb',
	PN_SDK_REST_URL: process.env.REACT_APP_PN_SDK_REST_URL,
	PN_SDK_WEBSOCKET: process.env.REACT_APP_PN_SDK_WEBSOCKET,
});

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);
