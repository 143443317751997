import React, { createContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Page from '../layout/Page';
import KeyValues from './dashboard/KeyValues';

import LatestBids from './dashboard/LatestBids';
import CurrentVisitorsCard from './dashboard/CurrentVisitorsCard';
import UsersStatsCard from './dashboard/UsersStatsCard';
import PNTrading from 'src/pn-trading-sdk';
import WelcomeCard from 'src/pages/dashboard/WelcomeCard';

export const DashboardContext = createContext();

export default function Dashboard() {
	const [latestBids, setLatestBids] = useState([]);


	useEffect(() => {
		const load = async () => {
			setLatestBids((await PNTrading.get(`/v2/bids/latest`)).bids)
		}

		load();
	}, []);

	return (
		<DashboardContext.Provider
			value={{
				latestBids
			}}
		>
			<Style>
				<WelcomeCard />

				<div></div>

				<CurrentVisitorsCard />

				<UsersStatsCard />

				<KeyValues />

				<LatestBids />
			</Style>
		</DashboardContext.Provider>
	);
}

const Style = styled(Page)`
	.header {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	.key-values {
		padding: 4rem;
		display: grid;
		gap: 2rem;
		grid-template-columns: 1fr 1fr;

		.key-value {
			label {
				font-size: 0.85rem;
			}

			.value {
				margin-top: 0.75rem;
				font-size: 2.5rem;
			}
		}

		.mobile & {
			grid-template-columns: 1fr;
			padding: 1rem;
		}
	}

	.row-icons {
		div {
			display: flex;
			justify-content: flex-end;
			font-size: 1.5em;

			.icon {
				margin-left: 0.5em;
			}
		}
	}
`;
