import React, { useState, useEffect } from 'react';
import Select, { Option } from '../elements/Select';
import { useCustomers } from '../utils/api/Customers';

export default function CustomerSelect({ defaultValueID, ...rest }) {
	let defaultValue = rest.defaultValue;

	if (defaultValue == -1) {
		defaultValue = undefined;
	}

	const { customers } = useCustomers();

	if (defaultValueID && customers) {
		defaultValue = customers.findIndex((c) => c.id == defaultValueID);
	}

	return (
		<Select {...rest} defaultValue={defaultValue}>
			{customers?.map((customer) => (
				<Option key={customer.id} value={customer.id}>{`${customer.name}`}</Option>
			))}
		</Select>
	);
}
