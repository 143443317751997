import React, { useContext, useEffect, useState } from 'react';
import PNTrading from 'src/pn-trading-sdk';
import usePopup from '../../utils/hooks/usePopup';
import Alert from '../../layout/popups/Alert';
import { dateToReadableDate } from '../../utils/Util';
import MediaLibrary from '../../layout/popups/MediaLibrary';

const WEBSITE_URL = 'https://riksauktioner.se';
const LOGO = 'http://server03.blackpixel.se/~pntrading/wp-content/uploads/2022/08/RA_Logga_Transparent_L.png';
const DEFAULT_HERO_IMAGE_ID = 13106;

export default function MailTemplate(props) {
	const [open] = usePopup();

	const [data, setData] = useState(undefined);
	const [date, setDate] = useState(new Date().toJSON().substring(0, 10));
	const [description, setDescription] = useState("En liten hälsning från oss på Riksauktioner för vi tycker att det Ibland är kul att hitta något oväntat. Eller så hittar du något som du velat ha länge till ett riktigt bra pris. Oavsett vad du söker så är vi glada att du är med oss på Riksauktioner och vi hoppas att just du hittar något användbart.")

	const [heroImage, setHeroImage] = useState();

	useEffect(() => {
		PNTrading.get(`/media/${DEFAULT_HERO_IMAGE_ID}`).then(setHeroImage);
	}, [])
	

	useEffect(() => {
		PNTrading.get(`/website/weekly-mail?date=${date}`).then(setData);
	}, [date]);

	const copy = () => {
		let el = document.getElementById('pn-mail-template');
		let styles = [...document.querySelectorAll('style')].map((n) => n.outerHTML).join('');

		// Copy innerHTML of el to clipboard
		navigator.clipboard.writeText(styles + el.innerHTML);
		open(<Alert title="Kopierat mall" text={`Mallen har kopierats och kan användas för att skicka ett mail till kunder.`} />);
	};

	const media = () => {
		open(
			<MediaLibrary
				defaultImage={heroImage?.id}
				onChange={async (image) => {
					setHeroImage(image);
				}}
			/>
		);
	}

	const urlSuffix = `utm_source=newsletter&utm_medium=email&utm_campaign=weekly_mail_${new Date().toJSON().substring(0, 10)}`

	if (!data)
		return (
			<div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
		);

	return (
		<>
			<div className="card" style={{ margin: 'auto', marginBottom: 70, marginTop: 30, width: 650 }}>
				<h2>Mejlmall</h2>
				<p>
					Använd denna mall för att kopiera HTML kod inför utskick av vecko-mejlet. <br /> OBS! Använd knappen "Kopiera HTML" för att få den genererade HTML koden.
				</p>
				<div className="spacer s"></div>
				<input type="date" value={date} onChange={(e) => setDate(e.target.value)} />
				<div className="spacer s"></div>
				<textarea onChange={(e) => setDescription(e.target.value)} style={{ width: '100%', height: 150, resize: 'none' }}>
				{description}
				</textarea>
				<div className="spacer s"></div>
				<button onClick={media}>Ändra hero bild</button>
				<div className="spacer s"></div>
				<button onClick={copy}>Kopiera HTML</button>
			</div>

			<div
				className="a4-page"
				id="pn-mail-template"
				dangerouslySetInnerHTML={{
					__html: `

<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html xmlns:v="urn:schemas-microsoft-com:vml">


<body bgcolor="#f4f4f4" style="margin:0; padding:0;">
	<table style="width: 100%; max-width: 650px;margin: auto;" border="0" align="center" cellpadding="0" cellspacing="0" bgcolor="#ffffff">
		
		<tr>
			<td style="text-align: center; padding-top: .5rem; padding-bottom: .5rem;">
				<img src="${LOGO}" width="200px" />
			</td>
		</tr>

		<tr>
			<td>
				${heroImage ? `<img src="${heroImage.sizes['1920']}" style="display: block; width: 100%; max-width: 1200px;" />` : ''}
			</td>
		</tr>

		<tr>
			<td style="padding: 2rem;">
				<h2>Hej, här kommer en sammanfattning!</h2>
				<br />
				<p>${description}</p>
				<br />
				<a class="button" style="display: inline-block;" href="${WEBSITE_URL}/alla-objekt?${urlSuffix}">Till objekten</a>
				<br />
				<br />
				<b>Ha en fin vecka!</b>
				<br />
				<br />
				Vänligen,
				<br />
				<b>Vi på Riksauktioner</b>
				</p>
			</td>
		</tr>

		<tr><td style="padding-left: 2rem; padding-right: 2rem;"><div style="border-bottom: solid 1px #DEDEDE; width: 100%;"  /></td></tr>
				
		${
			data.auctionsEndingToday.length > 0
				? `<tr>
			<td style="padding: 2rem;">
				<h2>Temaauktioner som avslutas idag</h2>
				<br />

				${data.auctionsEndingToday
					.map((auction) => {
						function getMeta(key) {
							let m = auction.data.meta.find((m) => m.key == key);
							return m ? m.value : undefined;
						}

						return `
						<a href="${WEBSITE_URL}/alla-objekt?auction=${auction.id}&${urlSuffix}" style="text-decoration: none; color: black;" target="_blank">
							<table>
								<tr>
									<td>
										<img src="${auction.embed.image.sizes['150x150']}" width="175px" />
									</td>
									<td style="vertical-align: top; padding: 1rem;">
										<h4 style="font-size: 18px;">${auction.name}</h4>
										<h4 style="font-size: 14px; color: #969696; font-weight: normal;">${auction.description}</h4>
										<br />
										<p style="font-size: 14px; "><b>Avslutas:</b> ${dateToReadableDate(new Date(auction.ending))}</p>

										${getMeta('Adress') ? `<p style="font-size: 14px; "><b>Adress:</b> ${getMeta('Adress')}</p>` : ''}
										${getMeta('Utlämning') ? `<p style="font-size: 14px; "><b>Utlämning:</b> ${getMeta('Utlämning')}</p>` : ''}

									</td>
								</tr>
							</table>
						</a>
					`;
					})
					.join('')}

			</td>
		</tr>
		<tr><td style="padding-left: 2rem; padding-right: 2rem;"><div style="border-bottom: solid 1px #DEDEDE; width: 100%;"  /></td></tr>
		`
				: ''
		}

		${
			data.objects?.length > 0
				? `<tr>
			<td style="padding: 2rem;">
				<h2>Populära objekt</h2>
				<br />

				${data.objects
					.filter((_, i) => i < 6)
					.map((object) => {
						return `
						<a href="${WEBSITE_URL}/objekt/${object.id}?${urlSuffix}" style="text-decoration: none; color: black; width: 30%; margin-right: 3%; display: block; float: left;" target="_blank">
							<img src="${object.embed.thumbnail.sizes['150x150']}" style="width: 100%" />
							
							<h4 style="width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">${object.title}</h4>
							<p style="font-size: 12px;"><b>Avslutas:</b> ${dateToReadableDate(new Date(object.ending))}</p>

							<br />
						</a>
					`;
					})
					.join('')}
			</td>
		</tr>
		<tr><td style="padding-left: 2rem; padding-right: 2rem;"><div style="border-bottom: solid 1px #DEDEDE; width: 100%;"  /></td></tr>
		`
				: ''
		}

		${
			data.otherAuctions.length > 0
				? `<tr>
			<td style="padding: 2rem;">
				<h2>Övriga auktioner ute nu</h2>
				<br />

				${data.otherAuctions
					.sort((a, b) => {
						return new Date(a.ending).getTime() - new Date(b.ending).getTime()
					})
					.map((auction) => {
						function getMeta(key) {
							let m = auction.data.meta.find((m) => m.key == key);
							return m ? m.value : undefined;
						}

						return `
						<a href="${WEBSITE_URL}/alla-objekt?auction=${auction.id}&${urlSuffix}" style="text-decoration: none; color: black;" target="_blank">
							<table>
								<tr>
									<td>
										<img src="${auction.embed.image.sizes['150x150']}" width="175px" />
									</td>
									<td style="vertical-align: top; padding: 1rem;">
										<h4 style="font-size: 18px;">${auction.name}</h4>
										<h4 style="font-size: 14px; color: #969696; font-weight: normal;">${auction.description}</h4>
										<br />
										<p style="font-size: 14px; "><b>Avslutas:</b> ${dateToReadableDate(new Date(auction.ending))}</p>

										${getMeta('Adress') ? `<p style="font-size: 14px; "><b>Adress:</b> ${getMeta('Adress')}</p>` : ''}
										${getMeta('Utlämning') ? `<p style="font-size: 14px; "><b>Utlämning:</b> ${getMeta('Utlämning')}</p>` : ''}

									</td>
								</tr>
							</table>
						</a>
					`;
					})
					.join('')}
			</td>
		</tr>`
				: ''
		}

		<tr>
			<td>
				<a href="https://apps.apple.com/se/app/riksauktioner/id1576332692">
					<img src="https://pn-trading.s3.eu-north-1.amazonaws.com/assets/images/RA_newsletter_banner_appstore_black1.png" style="width: 100%; display: block;" />
				</a>
			</td>
		</tr>

		<tr>
			<td style="padding: 2rem; background-color: #F4F4F4;">
				<h2>Vill du sälja via oss?<br/>Att frigöra kapital är enklare än du tror!</h2>
				<br />
				<p>Ofta har företag kapital bundet i t.ex. inventarier som inte används eller lager som blivit inkurant. Vi erbjuder företag en helhetslösning för att kunna frigöra kapital. På Riksauktioner så har vi redan kanalen, kunskapen och expertisen för att på ett smidigt sätt kunna hjälpa ditt företag sälja. Vi jobbar provisionsbaserat och tar hand om hela processen från start till mål. Allt du behöver göra är att kontakta oss idag, så sköter vi resten.
				<br />
				<br />
				<b>Kontakta oss idag på <a href="mailto:uppdrag@riksauktioner.se" style="color: black;">uppdrag@riksauktioner.se</a></b>
				</p>
			</td>
		</tr>

		<tr>
			<td style="padding: 0.5rem 2rem; background-color: #424242; color: white; text-align: center; font-size: 12px;">
				<unsubscribe>Skicka inga flera mail till mig, tack!</unsubscribe>
			</td>
		</tr>

	</table>
</body>
</html>     
                `,
				}}
			/>
		</>
	);
}
