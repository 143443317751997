import React from 'react';
import styled from 'styled-components';
import { useClosePopup } from '../../../utils/hooks/usePopup';
import Invoices from '../../../utils/api/Invoices';
import Button from '../../../elements/Button';
import Popup from '../../../layout/Popup';

export default function DeletePayment({ payment, onDeleted }) {
	const close = useClosePopup();

	const confirm = async () => {
		await Invoices.delete(payment.id);

        onDeleted && onDeleted();
		close();
	};

	return (
		<Popup close={close} width="40em" maxWidth="25em">
			<Style>
				<h2 className="title">Ta bort Faktura</h2>
				<div className="spacer s"></div>
				<p>Denna faktura och all dess data kommer att tas bort permanent, är du säker?</p>

				<div className="spacer xs"></div>

				<div className="buttons">
					<Button type="secondary" onClick={close}>
						Avbryt
					</Button>
					<Button onClick={confirm}>Ja, ta bort</Button>
				</div>
			</Style>
		</Popup>
	);
}

const Style = styled.div``;
