import { useEffect, useState } from 'react';
import Accounting, { AccountEvent } from '../Accounting';


export default function useAccountSummary() {
    const [events, setEntries] = useState<AccountEvent[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        setLoading(true);
        setError(null);
        Accounting.getAccountSummary()
            .then(setEntries)
            .catch(e => setError(e.message))
            .finally(() => setLoading(false));
    }, []);

    return { events, loading, error };
}