import React, { useContext, useEffect, useState } from 'react';
import Page from '../../layout/Page';
import Card from '../../layout/Card';
import styled from 'styled-components';
import AuctionSelect from '../../components/auction/AuctionSelect';
import { useHistory } from 'react-router-dom';
import PNTrading from 'src/pn-trading-sdk';
import { formatPrice } from '../../utils/Util';
import Objects from '../../utils/api/Objects';
import Table from '../../components/Table';
import { getUsernameByObject, sortByWinningUser } from './TickOff';

export default function PageTicked(props) {
	let id = props.match.params.id;

	const [objects, setObjects] = useState();
	const [auction, setAuction] = useState();

	useEffect(() => {
		PNTrading.get(`/auctions/${id}?embed=true&noCache=true`).then(setAuction);

		const fetchObjects = () => {
			PNTrading.get(`/auctions/${id}/objects?limit=10000&embed=true&noCache=true`).then((resp) => setObjects(resp.data));
		};

		fetchObjects();
		let intervalID = setInterval(fetchObjects, 5000);
		return () => clearInterval(intervalID);
	}, [id]);

	if (!objects || !auction)
		return (
			<div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
		);

	let wonObjects = objects.filter((o) => o.ending_data != null);
	let pickedObjects = wonObjects.filter((o) => o.tickoff);
	let nonPickedObjects = wonObjects.filter((o) => !o.tickoff);

	return (
		<Style>
			<Card size="small" className="stats-card">
				<h3>
					Vunna ({wonObjects.length} av {objects.length} st)
				</h3>
				<h1>{wonObjects != undefined ? formatPrice(wonObjects.reduce((tot, o) => tot + Number(o.ending_data.lastBid.amount), 0)) : '-'}</h1>
			</Card>

			<Card size="small" className="stats-card">
				<h3>Avprickat ({pickedObjects.length} st)</h3>
				<h1>{pickedObjects != undefined ? formatPrice(pickedObjects.reduce((tot, o) => tot + Number(o.ending_data.lastBid.amount), 0)) : '-'}</h1>
			</Card>

			<Card size="small" className="stats-card">
				<h3>Ej avprickat ({nonPickedObjects.length} st)</h3>
				<h1>{nonPickedObjects != undefined ? formatPrice(nonPickedObjects.reduce((tot, o) => tot + Number(o.ending_data.lastBid.amount), 0)) : '-'}</h1>
			</Card>

			<Card size="full">
				<h2>
					Sammanfattning (Avprickning) för <strong>{auction.name}</strong>
				</h2>
				<div className="spacer m"></div>

				<Table
					limit={-1}
					data={objects.sort(sortByWinningUser)}
					structure={[
						{
							heading: 'Position',
							key: 'position',
						},
						{
							heading: 'ID',
							key: 'id',
						},
						{
							heading: 'Vinnare',
							component: (object) => (object.ending_data ? getUsernameByObject(object) : ' - '),
						},
						{
							heading: 'Vinnande bud',
							component: (object) => (object.ending_data ? formatPrice(object.ending_data.lastBid.amount) : ' - '),
						},
						{
							heading: 'Objekt',
							key: 'title',
						},
						{
							heading: 'Status',
							component: (object) => {
								if (!object.ending_data) return <div className="badge gray">Ej vunnen</div>;

								return object.tickoff ? <div className="badge green">Avprickad</div> : <div className="badge red">Ej avprickad</div>;
							},
						},
					]}
				/>
			</Card>
		</Style>
	);
}

const Style = styled(Page)`
	.stats-card {
		h1 {
			margin-top: 0.5rem;
			font-size: 2.5rem;
		}
	}

	.objects {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 1rem;

		.object {
			padding: 1rem;
			border-radius: 1rem;

			&.nobids {
				opacity: 0.1;
				pointer-events: none;
			}

			&.picked {
				border-color: #21ab21;
				background-color: #d7ecd7;
			}

			img {
				width: 100%;
				height: auto;
				object-fit: cover;
				border-radius: 1rem;
			}

			.content {
				padding-top: 1rem;

				h4 {
					margin-bottom: 0.5rem;
				}

				select {
					margin-top: 0.5rem;
					width: 100%;
				}
			}
		}
	}

	.tablet & {
		.objects {
			grid-template-columns: repeat(3, 1fr);
		}
	}

	.mobile & {
		.objects {
			grid-template-columns: repeat(1, 1fr);
		}
	}
`;
