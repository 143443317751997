import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Card from '../../layout/Card';
import { DashboardContext } from '../Dashboard';
import { formatPrice } from '../../utils/Util';
import PNTrading from 'src/pn-trading-sdk';

export default function UsersStatsCard() {
	const [users, setUsers] = useState([]);

	useEffect(() => {
		const fetch = () => {
			PNTrading.get(`/admin/stats/users`).then(setUsers);
		};

		fetch();
		let interval = setInterval(fetch, 10 * 1000);
		return () => clearInterval(interval);
	}, []);

	return (
		<Style className={'key-values'} size="medium">
			<div className="key-value">
				<label>Nya användare</label>
				<div className="value">{users.new_users} st</div>
			</div>

			<div className="key-value">
				<label>Antal användare</label>
				<div className="value">{users.num_users} st</div>
			</div>
		</Style>
	);
}

const Style = styled(Card)``;
