import React, { useContext, useEffect, useState } from 'react';
import Page from '../../layout/Page';
import Card from '../../layout/Card';
import styled from 'styled-components';
import AuctionSelect from '../../components/auction/AuctionSelect';
import { useHistory } from 'react-router-dom';
import PNTrading from 'src/pn-trading-sdk';
import { caluclateCommissionAmount, dateToDatestring, dateToReadableDate, dateToString, formatPrice, getAuctionMetaValue, getLocalDate } from '../../utils/Util';
import Objects from '../../utils/api/Objects';
import Table from '../../components/Table';
import { sortByWinningUser } from './TickOff';

export default function PageObjectSummary(props) {
	let id = props.match.params.id;

	const [object, setObject] = useState();
	const [auction, setAuction] = useState();
	const [seller, setSeller] = useState();
	const [payment, setPayment] = useState();
	const [commission, setCommission] = useState(0);
	const [noCommission, setNoCommission] = useState('no');

	const load = () => {
		PNTrading.get(`/objects/${id}?embed=true&noCache=true`).then(setObject);
	};

	useEffect(() => {
		load();
	}, [id]);

	useEffect(() => {
		if (object?.seller) PNTrading.get(`/sellers/${object.seller}?embed=true&noCache=true`).then(setSeller);
		if (object?.auction) PNTrading.get(`/auctions/${object.auction}?embed=true&noCache=true`).then(setAuction);
		if (object) PNTrading.get(`/payments/object-payment/${object.id}`).then(setPayment);
	}, [object]);

	if (!object)
		return (
			<div style={{ width: '100%', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
		);

	const taxPercentage = object.no_tax === 'YES' ? 0 : 0.25;

	const winningBid = Number(payment?.objects?.find(o => o.id == object.id)?.amount || object.ending_data?.lastBid?.amount || 0);
	const fee = noCommission == 'yes' ? 0 : caluclateCommissionAmount([{...object, customAmount: winningBid}], Number(commission) / 100);

	const cost = object.meta
		? Object.entries(object.meta)
				.filter(([key, value]) => key.startsWith('_cost_') && value != '')
				.reduce((acc, [key, value]) => acc + Number(value), 0)
		: 0;

	const subtotal = winningBid - cost - fee;
	const tax = subtotal * taxPercentage;

	const total = winningBid * (1 + taxPercentage);

	const reductable = (fee + cost) * 1.25;

	return (
		<Style>
			<Card size="large" className="hide-print">
				<h2>Objektsummering</h2>
				<h5>(denna ruta visas inte vid utskrift)</h5>
				<div className="spacer s"></div>
				<p>Denna sida kan skrivas ut direkt i webbläsaren, antingen som en PDF eller via skrivare. Provisionen för projektet kan justeras här innan utskrift, eventuella kostnader på objekten kommer räknas av EFTER provision.</p>
				<div className="spacer s"></div>

				<div className="row" style={{ alignItems: 'flex-end' }}>
					<div className="column">
						<label htmlFor="commission">Provision (%)</label>
						<div className="spacer xs"></div>
						<input style={{ width: 120 }} type="number" min={0} max={100} defaultValue={0} onChange={(e) => setCommission(e.target.value ?? 0)} name="commission" placeholder="Provision (%)" />
					</div>
					<div className="spacer ws"></div>
					<div className="column">
						<label htmlFor="commission">Utan provision</label>
						<div className="spacer xs"></div>
						<select value={noCommission} onChange={e => setNoCommission(e.target.value)}>
							<option value="no">Nej</option>
							<option value="yes">Ja</option>
						</select>
					</div>
					<div className="spacer ws"></div>
					<button onClick={() => window.print()}>Skriv ut</button>
				</div>
			</Card>

			<Card size="full">
				{(() => {
					if (object && object.ending_data == null)
						return (
							<div style={{ width: '100%', height: '300px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
								<h3>Objektet är inte vunnet</h3>
								<div className="spacer s"></div>
								<p style={{ textAlign: 'center' }}>Objektet har inte avslutats än och därför kan inte en summering skrivas ut än.</p>
							</div>
						);

					// Render the actual object summary.
					if (object && auction && seller)
						return (
							<>
								<div className="header">
									<div className="logo">
										<img src="/ra-logga-invoice.png" alt="" />
									</div>

									<div className="document-info">
										<p>
											<b>Datum:</b> {new Date().getDateString()}
										</p>
										<p>
											<b>Kund:</b> {seller.name}
										</p>
										<p>
											{seller.trustee?.address}, {seller.trustee?.zip} {seller.trustee?.city}
										</p>
										<p>{seller.trustee?.email}</p>
									</div>
								</div>

								<div className="spacer m"></div>

								<p className="small-title metropolis">objekt översikt</p>
								<div className="spacer s"></div>

								<div className="object-info">
									<div className="image">
										<img src={object.embed?.thumbnail?.sizes?.['500x500']} alt="" />
									</div>

									<div className="content">
										<h1 className="title">{object.title}</h1>
										<div className="spacer s"></div>
										<p className="description">{object.description}</p>
										<div className="spacer s"></div>

										{object.meta && Object.entries(object.meta).length != 0 && (
											<table className="meta-table">
												<tbody>
													{Object.entries(object.meta)

														// Filter out empty values and cost meta.
														.filter(([key, value]) => {
															if (key.startsWith('_')) return false;
															if (value == '') return false;

															return true;
														})

														// Sort by key to prefer certain keys to be displayed first.
														.sort(([ak, av], [bk, bv]) => {
															if (ak == 'Registreringsnummer') return -1;
															if (ak == 'Modellår') return -1;
															if (ak == 'Svensksåld') return -1;
															if (ak == 'Mätarställning') return -1;

															return 0;
														})

														.map(([key, value], index) => {
															// Limit the result to 4 items.
															if (index >= 4) return null;

															return (
																<tr>
																	<th>
																		<p>{key}</p>
																	</th>
																	<td>
																		<p>{value}</p>
																	</td>
																</tr>
															);
														})}
												</tbody>
											</table>
										)}
									</div>
								</div>

								<div className="spacer l"></div>

								<div className="buyer-summary">
									<h1>Försäljningsinformation</h1>

									<div className="gray-card">
										<div className="row">
											<p>Slutpris</p>
											<p>{formatPrice(winningBid)}</p>
										</div>

										<div className="row">
											<p>Moms</p>
											<p>{formatPrice(winningBid * taxPercentage)}</p>
										</div>

										<div className="row bold border-bottom">
											<p>Summa</p>
											<p>{formatPrice(total)}</p>
										</div>

										<div className="row bold">
											<p>Avgår</p>
										</div>

										<div className="row">
											<p>Provision {commission || 0}%</p>
											<p>{formatPrice(fee.toFixed(0))}</p>
										</div>

										{object.meta &&
											Object.entries(object.meta)
												.filter(([key, value]) => key.startsWith('_cost_') && value != '')
												.map(([key, value]) => (
													<div className="row">
														<p>{key.replace('_cost_', '')}</p>
														<p>{formatPrice(value)}</p>
													</div>
												))}

										<div className="row">
											<p>Moms</p>
											<p>{formatPrice(((fee + cost) * 0.25).toFixed(0))}</p>
										</div>

										<div className="row bold">
											<p>Summa att avgå</p>
											<p>{formatPrice(reductable.toFixed(0))}</p>
										</div>

										<div className="row total">
											<p>Totalt att redovisa</p>
											<p>{formatPrice(Math.ceil(total - reductable).toFixed(0))}</p>
										</div>
									</div>
								</div>

								<div className="footer">
									<div className="meta-row">
										<p>Riksauktioner AB</p>
										<p>Org. 556236-6954</p>
										<p>Tel: 08 446 17 12</p>
										<p>BG: 5849-4808</p>
										<p>www.riksauktioner.se</p>
									</div>
								</div>

								{/* <pre>{JSON.stringify(object, null, 4)}</pre> */}
							</>
						);

					// Render an error if the object does not have a seller selected.
					if (object && auction && !seller)
						return (
							<div style={{ width: '100%', height: '300px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
								<h3>Ingen säljare</h3>
								<div className="spacer s"></div>
								<p style={{ textAlign: 'center' }}>
									Objektet som valts har ingen säljare, för att kunna generera <br />
									denna rapport så krävs det att objektet är kopplat mot en säljare.
								</p>
							</div>
						);

					// Default render the loading...
					return (
						<div style={{ width: '100%', height: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
							<div className="loader"></div>
						</div>
					);
				})()}
			</Card>
		</Style>
	);
}

const Style = styled(Page)`
	& > .wrapper {
		width: 900px;
	}

	p {
		font-size: 12px;
	}

	.header {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.logo {
			img {
				width: 6cm;
			}
		}

		.document-info {
			text-align: right;
		}
	}

	.small-title {
		text-transform: uppercase;
	}

	.object-info {
		display: flex;

		.image {
			margin-right: 2rem;

			img {
				width: 18rem;
				border-radius: 1rem;
			}
		}

		.content {
			.title {
				font-size: 27px;
				font-weight: 100;
			}

			.description {
				font-size: 16px;
				font-weight: 100;
				line-height: 150%;
			}

			.meta-table {
				border-top: solid 1px var(--color-light-gray);
				padding-top: 0.8rem;
			}
		}
	}

	.buyer-summary {
		.gray-card {
			background-color: #f5f5f5;
			padding: 1rem 1.5rem;
			border-radius: 1rem;
			margin-top: 0.5rem;

			.row {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0.2rem 1rem;

				p {
					font-size: 14px;
				}

				&.border-bottom {
					border-bottom: solid 1px var(--color-light-gray);
					margin-bottom: 0.5rem;
				}

				&.end-price {
					font-weight: 600;
					border-bottom: solid 1px var(--color-light-gray);
					margin-bottom: 0.5rem;
				}

				&.total {
					font-weight: 600;
					background-color: white;
					border-radius: 0.5rem;
					margin-top: 1rem;
					padding: 0.5rem 1rem;
				}
			}
		}
	}

	.footer {
		padding-top: 1rem;

		.meta-row {
			display: flex;
			align-items: center;
			justify-content: center;

			p {
				color: #555555;
				height: 1em;
				line-height: 1em;

				&:not(:last-child) {
					margin-right: 0.5rem;
					padding-right: 0.5rem;
					border-right: solid 1px currentColor;
				}
			}
		}
	}

	@media print {
		height: 100vh;

		padding-left: 4rem;
		padding-right: 4rem;

		.wrapper {
			height: 100%;

			& > .card {
				display: flex;
				flex-direction: column;

				.footer {
					margin-top: auto;
				}
			}
		}
	}
`;
