import React, { useState } from 'react';
import styled from 'styled-components';
import { useClosePopup } from '../../../utils/hooks/usePopup';
import Popup from '../../Popup';
import Button from '../../../elements/Button';
import sha256 from 'sha256';
import API from '../../../utils/api/API';
import Select, { Option } from '../../../elements/Select';
import PNTrading from 'src/pn-trading-sdk';

export default function SendNotificationPopup({ user, uid }) {
	const close = useClosePopup();

	const [type, setType] = useState('mail');
	const [subject, setSubject] = useState('');
	const [message, setMessage] = useState('');
	const [loading, setLoading] = useState(false);

	const confirm = async () => {
		if (!user) alert('Ingen användare hittad!');
		if (!message) alert('Inget meddelande skrivet!');
		if (!type) alert('Ingen typ vald!');
		if (!user || !message || !type) return;
		if (loading) return;


		let payload;
		switch (type) {
			case 'mail':
				payload = {
					"subject": subject,
					"template": "base",
					"body": [
						{
							"type": "text",
							"content": {
								"text": message.replace(/(?:\r\n|\r|\n)/g, '<br />'),
								"align": "left"
							}
						}
					]
				};
				break;

			case 'sms':
				payload = {
					"text": message
				}
				break;

			case 'push':
				payload = {
					"notification": {
						"title": subject,
						"body": message
					},
				}
				break;
		
			default:
				break;
		}

		if (!payload) return alert('Inget payload hittat!');

		setLoading(true);
		await PNTrading.post(`/admin/notifications/push/${user.id}/${type}`, payload)

		setLoading(false);
		close();
	};

	return (
		<Popup close={close} width="40em" maxWidth="45em">
			<Style>
				<h2 className="title">Skicka meddelande</h2>
				<div className="spacer s"></div>
				<p>Skicka ett meddelande till <b>{user.username}</b> via:</p>

				<div className="spacer xs"></div>

				<select value={type} onChange={(e) => setType(e.target.value)}>
					<option value="mail">E-post</option>
					<option value="sms">SMS</option>
					<option value="push">Push-notis</option>
				</select>
				
				<div className="spacer s"></div>

				{ (type == 'mail' || type == 'push') && <>
					<label>Ämne</label>
					<input
						type="text"
						placeholder="Ämne"
						value={subject}
						onChange={(e) => setSubject(e.target.value)}
						style={{ width: '100%' }}
					/>
				</>}

				<div className="spacer s"></div>

				<label>Meddelande</label>
				<textarea
					placeholder="Meddelande" 
					style={{ width: '100%', height: '10em', resize: 'none' }}
					value={message}
					onChange={(e) => setMessage(e.target.value)}
				/>


				<div className="buttons">
					<Button type="secondary" onClick={close}>
						Avbryt
					</Button>
					<Button onClick={confirm} className={`${
						loading ? 'loading' : ''
					}`}>Skicka</Button>
				</div>
			</Style>
		</Popup>
	);
}

const Style = styled.div``;
