import { useEffect, useRef } from "react";
import sha256 from "sha256";

/**
 * Hook for listening to a websocket and act on messages.
 */
export default function useWS() {

    const ws = useRef()
    const listeners = useRef([])

    /**
     * Called anywhere to listen for a specific type of packet.
     */
    function listen(type, cb) {
        let id = sha256(Math.random().toString())
        listeners.current.push({id, type, cb});
        return () => listeners.current = listeners.current.filter(l => l.id !== id);
    }

    useEffect(() => {

        let open = false;
        let tries = 0;
        let reconnectInterval;
    
        function connect() {
            if (open) return;
            if (ws.current) ws.current.close();
            ws.current = new WebSocket(process.env.REACT_APP_PN_SDK_WEBSOCKET, 'ra-ws')

            setTimeout(() => {
                if (ws.readyState == WebSocket.CONNECTING) {
                    ws.close();
                    console.log("[WS] Connection timeout.");
                }
            }, 1000);


            ws.current.onopen = () => {
                console.log('[WS] Connected')
                open = true;
                clearInterval(reconnectInterval);
            }

            ws.current.onclose = () => {
                console.log('[WS] Disconnected')
                open = false;

                let reconnectInterval = setInterval(() => {
                    tries++;
                    if (open) return;
                    console.log('[WS] Trying to reconnect...')
                    connect();
                    if (tries > 5) clearInterval(reconnectInterval)
                }, 2000)

            }

            ws.current.onmessage = (e) => {
                try {
                    let json = JSON.parse(e.data)
                    if (json.type !== undefined) {
                        for (let listener of listeners.current) {
                            if (listener.type == json.type) {
                                listener.cb && listener.cb(json)
                            }
                        }
                    }
                } catch (e) {
                    console.error(e)
                }
            }
        }

        connect();

        return () => {
            if (ws.current) ws.current.close();
        }
    
    }, [])

    return {
        listen
    }
}