import React, { useRef } from "react";
import Icon from "src/elements/Icon";
import Card from "src/layout/Card";
import { formatInvoiceNumber } from "src/utils/Util";
import { useSellers } from "src/utils/api/Sellers";
import { CustomerInvoice } from "src/utils/api/customer-invoices/CustomerInvoices";
import useCustomerInvoice from "src/utils/api/customer-invoices/hooks/useCustomerInvoice";
import styled from "styled-components";

export default function EditableCustomerInvoiceCard({ invoiceID }: { invoiceID: number }) {

    const {customerInvoice: invoice, setCustomerInvoice, save} = useCustomerInvoice(invoiceID);
    const timeoutID = useRef<NodeJS.Timeout | null>(null);

    const { sellers } = useSellers();
    const seller = (sellers as any)?.find((s: any) => s.id === invoice?.customerNumber);

    function getTotal(quantity: number, price: number, discount: number) {
        return quantity * price * (1 - discount / 100);
    }

    function handleUpdate(e: React.ChangeEvent<HTMLInputElement>) {
        setCustomerInvoice((prev) => {
            if (!prev) return null;

            const newInvoice = {
                ...prev,
                customerName: e.target.value
            }

            handleUpdatedField(newInvoice);

            return newInvoice;
        });
    }

    function handleUpdatedField(invoice: CustomerInvoice) {
        if (timeoutID.current) {
            clearTimeout(timeoutID.current);
        }

        timeoutID.current = setTimeout(() => {
            if (!invoice) return;
            save(invoice);
        }, 500);
    }

    function handleAddItemRow() {
        setCustomerInvoice((prev) => {
            if (!prev) return null;

            const invoice = {
                ...prev,
                items: [
                    ...prev.items,
                    {
                        eventID: null,
                        articleNumber: null,
                        description: '',
                        privateDescription: '',
                        discount: 0,
                        price: 0,
                        quantity: 0,
                        total: 0,
                        unit: ''
                    }
                ]
            }

            handleUpdatedField(invoice);

            return invoice;
        });

    }

    if (!invoice) {
        return null;
    }

    const disabled = invoice.status === 'sent';

    return (
        <Style size="full">

            <div className="invoice-grid">

                <div className="invoice-info-box">
                    <h1 className="invoice-label">
                        Fakturaunderlag
                    </h1>

                    <div className="labeled-box">
                        <div className="label">Fakturanummer</div>
                        <div>{invoice.id}</div>
                    </div>

                    <div className="labeled-box">
                        <div className="label">Kundnumer</div>
                        <div>{invoice.customerNumber}</div>
                    </div>

                    <div className="labeled-box">
                        <div className="label">Fakturadatum</div>
                        <div>{invoice.created_at ? new Date(invoice.created_at).toLocaleDateString('sv-SE') : '-'}</div>
                    </div>

                </div>

                {seller && <div className="invoice-customer-box">
                    <div className="label">
                        Kunduppgifter
                    </div>

                    <div className="field-wrapper">
                        <input
                            disabled={disabled}
                            className="hide-print"
                            type="text" 
                            defaultValue={seller.trustee.email}
                        />
                        <p className="hide-screen">
                            {seller.trustee.email}
                        </p>
                    </div>

                    <div className="field-wrapper">
                        <input
                            disabled={disabled}
                            className="hide-print"
                            type="text"
                            defaultValue={seller.trustee.phone}
                        />
                        <p className="hide-screen">
                            {seller.trustee.phone}
                        </p>
                    </div>

                    <br className="hide-screen" />

                    <div className="label">
                        Org Nr.
                    </div>


                    <div className="field-wrapper">
                        <input
                            disabled={disabled}
                            className="hide-print"
                            type="text"
                            defaultValue={seller.org_number}
                        />
                        <p className="hide-screen">
                            {seller.org_number}
                        </p>
                    </div>


                </div>}

                <div className="invoice-address-box">
                    <div className="label">
                        Fakturaadress
                    </div>

                    <div className="field-wrapper customerName">
                        <input
                            disabled={disabled}
                            type="text" 
                            className="hide-print"
                            name="customerName"
                            value={invoice.customerName}
                            onChange={handleUpdate}
                        />
                        <p className="hide-screen">
                            {invoice.customerName}
                        </p>
                    </div>

                    <div className="field-wrapper customerAddress">
                        <input
                            disabled={disabled}
                            type="text"
                            className="hide-print"
                            name="customerAddress"
                            value={invoice.customerAddress}
                            onChange={handleUpdate}
                        />
                        <p className="hide-screen">
                            {invoice.customerAddress}
                        </p>
                    </div>

                    <div className="field-wrapper customerZip">
                        <input
                            disabled={disabled}
                            type="text"
                            className="hide-print"
                            name="customerZip"
                            value={invoice.customerZip}
                            onChange={handleUpdate}
                        />
                        <p className="hide-screen">
                            {invoice.customerZip}
                        </p>
                    </div>

                    <div className="field-wrapper customerCity">
                        <input
                            disabled={disabled}
                            type="text"
                            className="hide-print"
                            name="customerCity"
                            value={invoice.customerCity}
                            onChange={handleUpdate}
                        />  
                        <p className="hide-screen">
                            {invoice.customerCity}
                        </p>
                    </div>
                </div>

                <div className="invoice-items-box">

                    <div className="table-wrapper">
                        <table>
                            <thead>
                                <tr>
                                    <th>Artikel</th>
                                    <th>Benämning</th>
                                    <th>Antal</th>
                                    <th>Enhet</th>
                                    <th>À-pris</th>
                                    <th>Rabatt %</th>
                                    <th>Summa</th>
                                    {!disabled && <th className="hide-print"></th>}
                                </tr>
                            </thead>
                            <tbody>
                                {invoice.items.map((item, i) => {
                                    
                                    function handleDelete() {
                                        if (!invoice) return;


                                        if (window.confirm('Är du säker på att du vill ta bort raden?') === false) {
                                            return;
                                        }


                                        const newItems = [...invoice.items];
                                        newItems.splice(i, 1);

                                        setCustomerInvoice((prev) => {
                                            if (!prev) return null;

                                            const newInvoice = {
                                                ...prev,
                                                items: newItems
                                            }

                                            handleUpdatedField(newInvoice);

                                            return newInvoice;
                                        });

                                    }

                                    function handleUpdateField(e: React.ChangeEvent<HTMLInputElement>) {
                                        if (!invoice) return;
                                        const newItems = [...invoice.items];
                                        newItems[i] = {
                                            ...newItems[i],
                                            [e.target.name]: isNaN(Number(e.target.value)) ? e.target.value : Number(e.target.value)
                                        }

                                        const quantity = newItems[i].quantity;
                                        const price = newItems[i].price;
                                        const discount = newItems[i].discount;

                                        newItems[i].total = getTotal(quantity, price, discount);

                                        setCustomerInvoice((prev) => {
                                            if (!prev) return null;

                                            const newInvoice = {
                                                ...prev,
                                                items: newItems
                                            }

                                            handleUpdatedField(newInvoice);

                                            return newInvoice;
                                        });

                                    }
                                    
                                    return (
                                        <tr key={i}>
                                            <td style={{ width: 90 }}>
                                                <input
                                                    disabled={disabled}
                                                    type="number"
                                                    className="hide-print"
                                                    name={`articleNumber`}
                                                    value={item.articleNumber || ''}
                                                    onChange={handleUpdateField}
                                                />
                                                <p className="hide-screen">
                                                    {item.articleNumber}
                                                </p>
                                            </td>
                                            <td>
                                                <input
                                                    disabled={disabled}
                                                    type="text"
                                                    className="hide-print"
                                                    name={`description`}
                                                    value={item.description}
                                                    onChange={handleUpdateField}
                                                />
                                                <p className="hide-screen">
                                                    {item.description}
                                                </p>
                                            </td>
                                            <td style={{ width: 100 }}>
                                                <input
                                                    disabled={disabled}
                                                    type="number"
                                                    className="hide-print"
                                                    name={`quantity`}
                                                    value={item.quantity}
                                                    onChange={handleUpdateField}
                                                />
                                                <p className="hide-screen">
                                                    {formatInvoiceNumber(item.quantity)}
                                                </p>
                                            </td>
                                            <td style={{ width: 120 }}>
                                                <input
                                                    disabled={disabled}
                                                    type="text"
                                                    className="hide-print"
                                                    name={`unit`}
                                                    value={item.unit}
                                                    onChange={handleUpdateField}
                                                />
                                                <p className="hide-screen">
                                                    {item.unit}
                                                </p>
                                            </td>
                                            <td style={{ width: 110 }}>
                                                <input
                                                    disabled={disabled}
                                                    type="number"
                                                    className="hide-print"
                                                    name={`price`}
                                                    value={item.price}
                                                    onChange={handleUpdateField}
                                                />
                                                <p className="hide-screen">
                                                    {formatInvoiceNumber(item.price)}
                                                </p>
                                            </td>
                                            <td style={{ width: 110 }}>
                                                <input
                                                    disabled={disabled}
                                                    type="number"
                                                    className="hide-print"
                                                    name={`discount`}
                                                    value={item.discount}
                                                    onChange={handleUpdateField}
                                                />
                                                <p className="hide-screen">
                                                    {formatInvoiceNumber(item.discount)}
                                                </p>
                                            </td>
                                            <td style={{ width: 110 }}>
                                                <input
                                                    type="text"
                                                    className="hide-print"
                                                    name={`total`}
                                                    disabled
                                                    style={{textAlign: 'right'}}
                                                    value={formatInvoiceNumber(item.total)}
                                                />
                                                <p className="hide-screen">
                                                    {formatInvoiceNumber(item.total)}
                                                </p>
                                            </td>
                                            {!disabled && <td className="hide-print">
                                                <div className="actions-wrapper">
                                                    <Icon onClick={handleDelete}>delete</Icon>
                                                </div>
                                            </td>}
                                        </tr>
                                    )
                                })}

                                {!disabled && <tr className="hide-print">
                                    <td colSpan={8} style={{ textAlign: 'right' }}>
                                        <button type="button" className="outlined" onClick={handleAddItemRow}>Lägg till rad</button>
                                    </td>
                                </tr>}

                                <tr className="summary">
                                    <td colSpan={6} style={{ textAlign: 'right' }}>Totalt</td>
                                    <td>
                                        <input className="hide-print" type="text" style={{textAlign: 'right'}} disabled value={formatInvoiceNumber(invoice.items.reduce((acc, item) => acc + Number(item.total), 0))} />
                                        <p className="hide-screen">
                                            {formatInvoiceNumber(invoice.items.reduce((acc, item) => acc + Number(item.total), 0))}
                                        </p>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

        </Style>
    )
}

const Style = styled(Card)`

    --border-color: #ddd;

    .invoice-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;
        padding: 20px;

        *:not(.material-icons) {
            font-family: Arial, sans-serif;
        }

        .invoice-info-box {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 5px;
            grid-column: 1 / span 2;

            & > * {
                border: var(--border-color) 1px solid;
            }

            .invoice-label {
                grid-column: 1 / span 3;
                padding: 10px;
                text-align: center;
            }

            .labeled-box {
                padding: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                div:not(.label) {
                    font-weight: bold;
                }
            }
        }

        .invoice-customer-box {
            border: var(--border-color) 1px solid;
            padding: 10px;
            grid-column: 1 / span 1;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            flex-wrap: wrap;
            gap: 5px;

            & > * {
                width: 100%;

                input {
                    width: 100%;
                }
            }
        }

        .invoice-address-box {
            border: var(--border-color) 1px solid;
            padding: 10px;
            grid-column: 2 / span 1;
            display: flex;
            flex-wrap: wrap;
            gap: 5px;

            .field-wrapper {
                flex-grow: 1;

                &.customerName, &.customerAddress {
                    width: 100%;
                }

                &.customerZip {
                    flex-grow: 0;
                }

                input {
                    width: 100%;
                }
            }
        }

        .invoice-items-box {
            grid-column: 1 / span 2;

            .table-wrapper {
                overflow-x: auto;
                border: var(--border-color) 1px solid;

                table {
                    width: 100%;
                    border-collapse: collapse;

                    th, td {
                        padding: 10px;
                        border-bottom: var(--border-color) 1px solid;
                    }

                    th {
                        background: #f0f0f0;
                        text-align: left;
                    }

                    td {
                        text-align: left;

                        & > * {
                            width: 100%;
                        }

                    }

                    tr {
                        &:last-child {
                            td {
                                border-bottom: none;
                            }
                        }
                    }
                }
            }
        }
    }


    @media print {
        padding: 5px;

        .invoice-grid {
            padding: 5px;

            * {
                font-size: 12px;
            }

            table {
                td, th {
                    &:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(4)) {
                        &, * {
                            text-align: right !important;
                        }
                    }
                }

                tr.summary {
                    td {
                        font-weight: bold;

                        * {
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
`;