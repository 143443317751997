import { useEffect, useState } from "react";
import { EmbeddedExpense, getExpense } from "src/utils/api/expenses/Expenses";

export default function useExpense(id: number | null) {

    const [expense, setExpense] = useState<EmbeddedExpense | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            if (id === null) return;
            const expense = await getExpense(id);
            setExpense(expense);
            setLoading(false);
        })();
    }, [id]);

    return {
        expense,
        loading
    };
}