import PNTrading from 'src/pn-trading-sdk';
import { useEffect, useState } from 'react';

export default function useUserMeta(id) {
	const [userMeta, _setUserMeta] = useState({});

	useEffect(() => {
		if (!id) return;
		PNTrading.get(`/users/${id}/meta`).then(_setUserMeta);
	}, [id]);

	const setUserMeta = async (meta, cb = () => {}) => {
		// Get the changes to the meta.
		let changes = {};
		for (let key in meta) {
			if (userMeta[key] !== meta[key]) {
				changes[key] = meta[key];
			}
		}

		_setUserMeta((m) => ({ ...m, ...meta }));

		let responses = {};

		// Update the meta.
		for (let key in changes) {
			responses[key] = await PNTrading.post(`/users/${id}/meta`, {
				meta_key: key,
				value: changes[key],
			});
		}

		cb && cb(responses);
	};

	return [userMeta, setUserMeta];
}
