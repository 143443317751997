import PNTrading from "src/pn-trading-sdk/index";


export const CustomerInvoiceStatus = {
    DRAFT: 'draft',
    SENT: 'sent',
} as const;

export type CustomerInvoice = {
    id: number | null;
    created_at: Date | null;
    customerNumber: number;
    customerName: string;
    customerAddress: string;
    customerZip: string;
    customerCity: string;
    customerReference: string;
    status: typeof CustomerInvoiceStatus[keyof typeof CustomerInvoiceStatus];
    sent_at: Date | null;
    items: CustomerInvoiceItem[];
    totalNetAmount: number;
    totalVatAmount: number;
    totalGrossAmount: number;
}

export type CustomerInvoiceItem = {

    /**
     * ID that refers to an employee Event. This is used by the backend to update the status of the event if
     * the invoice would be deleted, then restoring the event status.
     * 
     * @see src/utils/api/employees/Events.ts
     */
    eventID: number | null;
    articleNumber: number | null;
    description: string;
    privateDescription: string;
    quantity: number;
    unit: string;
    price: number;
    discount: number;
    total: number;
}

export type GetBillableSellersArgs = {
    after?: Date;
    before?: Date;
}

export async function getBillableSellers(args?: GetBillableSellersArgs) {
    const query = new URLSearchParams();
    let path = '/v2/customer-invoices/pending';

    if (args?.after) {
        query.append('after', args.after.toISOString());
    }

    if (args?.before) {
        query.append('before', args.before.toISOString());
    }

    if (query.toString()) {
        path += `?${query.toString()}`;
    }

    const resp = await PNTrading.get(path);

    if (resp.success !== true) {
        throw new Error(resp.message);
    }

    return resp.data as CustomerInvoice[];
}

export async function createCustomerInvoice(customerNumber: number) {
    const resp = await PNTrading.post(`/v2/customer-invoices/${customerNumber}`);

    if (resp.success !== true) {
        throw new Error(resp.message);
    }

    return resp.data as CustomerInvoice;
}

export async function getCustomerInvoices() {
    const resp = await PNTrading.get('/v2/customer-invoices');

    if (resp.success !== true) {
        throw new Error(resp.message);
    }

    return resp.data as CustomerInvoice[];
}

export async function getCustomerInvoice(id: number) {
    const resp = await PNTrading.get(`/v2/customer-invoices/${id}`);

    if (resp.success !== true) {
        throw new Error(resp.message);
    }

    return resp.data as CustomerInvoice;
}

export async function updateCustomerInvoice(id: number, invoice: Partial<Omit<CustomerInvoice, 'id'>>) {
    const resp = await PNTrading.put(`/v2/customer-invoices/${id}`, invoice);

    if (resp.success !== true) {
        throw new Error(resp.message);
    }

    return resp.data as CustomerInvoice;
}

export async function deleteCustomerInvoice(id: number) {
    const resp = await PNTrading.request(`/v2/customer-invoices/${id}`, undefined, { method: 'DELETE' });

    if (resp.success !== true) {
        throw new Error(resp.message);
    }
}