import React, { useState } from 'react';
import styled from 'styled-components';
import useCategories from '../../utils/hooks/useCategories';

export default function CategorySelect({ defaultValue, onChange }) {
	const [value, setValue] = useState(defaultValue);
	let categories = useCategories();

	const _onChange = (e) => {
		setValue(e.target.value);
		onChange && onChange(e);
	};

	return (
		<select value={value} onChange={_onChange}>
			<option value="none">Välj kategori...</option>
			{categories
				?.filter((c) => !c.parent)
				.map((c) => {
					let children = categories.filter((cc) => cc.parent == c.id);

					if (children.length != 0) {
						return (
							<optgroup label={c.name} key={c.id}>
								<option value={c.id}>
									{c.id} - Allt inom {c.name}
								</option>
								{children.map((cc) => (
									<option value={cc.id}>
										{cc.id} - {cc.name}
									</option>
								))}
							</optgroup>
						);
					}
					return (
						<option value={c.id} key={'option_' + c.id}>
							{c.id} - {c.name}
						</option>
					);
				})}
		</select>
	);
}

const Style = styled.div``;
