import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import API from '../../utils/api/API';
import Button from '../../elements/Button';
import Popup from '../Popup';
import { useClosePopup } from '../../utils/hooks/usePopup';
import Objects from '../../utils/api/Objects';

export default function RemoveObjectFromAuction({ id, auction, setObjects }) {
	const [object, setObject] = useState();
	let history = useHistory();

	const close = useClosePopup();

	const removeObject = async () => {
		await Objects.update(id, { auction: null });

		let cachedObjects = API.getCached(`/auctions/${auction}/objects_`);
		if (cachedObjects) {
			cachedObjects.result = cachedObjects.result.filter((o) => o.id != id);
			API.updateCache(`/auctions/${auction}/objects_`, cachedObjects);
			if (setObjects) setObjects(cachedObjects.result);
		}

		close();
	};

	useEffect(() => {
		(async () => {
			setObject(await Objects.get(id));
		})();
	}, [id]);

	return (
		<Popup close={close} width="40em" maxWidth="30em">
			<Style>
				<h2 className="title">
					Ta bort objekt <strong>{id}</strong> från auktionen?
				</h2>
				<div className="spacer s"></div>
				<p>
					<strong>"{object && object.title}"</strong> kommer att tas bort ifrån auktionen men finns kvar som objekt.
				</p>

				<div className="buttons">
					<Button type="secondary" onClick={close}>
						Nej
					</Button>
					<Button onClick={removeObject}>Ja</Button>
				</div>
			</Style>
		</Popup>
	);
}

const Style = styled.div``;
