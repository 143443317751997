import React from "react";
import Table from "src/components/Table";
import Icon from "src/elements/Icon";
import Card from "src/layout/Card";
import Page from "src/layout/Page";
import { useHistory } from 'react-router-dom';
import { formatPrice } from "src/utils/Util";
import { Expense } from "src/utils/api/expenses/Expenses";
import useExpenses from "src/utils/api/expenses/useExpenses";
import styled from "styled-components";

export default function PageExpenses() {

	const history = useHistory();

    const { expenses, loading } = useExpenses();

    if (loading) {
        return (
			<div style={{ paddingTop: 100, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
        )
    }
    
    return (
        <Style>
            <Card size="full">
				<div className="header">
					<h1>Utgifter</h1>
					<div className="spacer auto"></div>
				</div>

				<div className="spacer m"></div>

                <Table
					// pages={expenses ? expenses.pages : 1}
					// limit={limit}
					// tableID={undefined}
					// The table requests new data when the user interacts with it.
					// load={load}
					data={expenses}
					structure={[
						{
							heading: 'ID',
							key: 'id',
						},
						{
							heading: 'Beskrivning',
							key: 'description',
							component: (row: Expense) => row.description,
						},
						{
							heading: 'Registrerad',
							key: 'created_at',
                            sortable: true,
							component: (row: Expense) => {
								return new Date(row.created_at).toLocaleDateString();
							},
						},
						{
							heading: 'Summa',
							key: 'amount',
							component: (row: Expense) => {
								return row.amount ? formatPrice(row.amount) : '-';
							},
						},
						{
							heading: '',
							className: 'row-icons',
							component: (row: Expense) => {
								return (
									<div>
										<Icon onClick={() => history.push(`/expenses/${row.id}`)}>receipt</Icon>
										{/* <Icon>delete</Icon> */}
									</div>
								);
							},
						},
					]}
				/>

            </Card>
        </Style>
    )
}

const Style = styled(Page)`
	.key-values {
		h1 {
			font-size: 2.5em;
		}
	}

	.filters {
		/* padding: 2rem; */
		border-radius: 0.5rem;
		display: flex;
		flex-wrap: wrap;

		.filter {
			background: #f8f8f8;
			display: flex;
			flex-direction: column;
			margin-right: 1rem;
			margin-bottom: 1rem;
			border: #ccc solid 1px;
			padding: 1rem;
			border-radius: 0.5rem;

			label {
				margin-bottom: 0.35rem;
			}

			.sep {
				margin-left: 0.5rem;
				margin-right: 0.5rem;
			}
		}

		.mobile & {
			flex-direction: column;

			.row {
				flex-wrap: wrap;

				& > * {
					margin-bottom: 0.5rem;
				}
			}
		}
	}

	.header {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		form {
			display: flex;
			align-items: center;
		}
	}

	.row-icons {
		div {
			display: flex;
			justify-content: flex-end;
			font-size: 1.5em;

			.icon {
				margin-left: 0.5em;
			}
		}
	}
`;