import React, { useContext, useEffect, useState } from 'react';
import Table from '../../components/Table';
import Page from '../../layout/Page';
import Card from '../../layout/Card';
import Icon from '../../elements/Icon';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import usePopup from '../../utils/hooks/usePopup';
import Customers from '../../utils/api/Customers';
import DeleteCustomer from '../../layout/popups/customers/DeleteCustomer';

export default function PageCustomers() {
	const limit = 10;

	const history = useHistory();

	const [searchString, setSearchString] = useState();
	const [customers, setCustomers] = useState();
	const [open] = usePopup();

	const isLoading = customers == undefined;

	const load = async ({ sorting, filters, page } = {}) => {
		let customers = await Customers.list({ limit, page: page ?? 0, search: searchString, noCache: true });
		setCustomers(customers);

		return customers.data;
	};

	const search = (e) => {
		e.preventDefault();
		load();
	};

	useEffect(() => load(), []);

	if (isLoading)
		return (
			<div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
		);

	return (
		<Style>
			<Card size="full">
				<div className="header">
					<h1>Uppdragsgivare</h1>
					<div className="spacer auto"></div>

					<form onSubmit={search}>
						<input type="text" placeholder="Sök kund" onChange={(e) => setSearchString(e.target.value == '' ? undefined : e.target.value)} />

						<div className="spacer ws"></div>

						<button type="submit">Sök</button>
					</form>
				</div>

				<div className="spacer m"></div>

				<Table
					pages={customers ? customers.pages : 1}
					limit={limit}
					tableID={'customers'}
					// The table requests new data when the user interacts with it.
					load={load}
					data={customers?.data ?? []}
					structure={[
						{
							heading: 'ID',
							key: 'id',
						},
						{
							heading: 'Företagsnamn',
							fill: true,
							key: 'name',
						},
						{
							heading: '',
							className: 'row-icons',
							component: (row) => {
								return (
									<div>
										<Icon onClick={() => history.push(`/customers/${row.id}`)}>edit</Icon>
										<Icon onClick={() => open(<DeleteCustomer customer={row} onDeleted={() => load()} />)}>delete</Icon>
									</div>
								);
							},
						},
					]}
				/>
			</Card>
		</Style>
	);
}

const Style = styled(Page)`
	.key-values {
		h1 {
			font-size: 2.5em;
		}
	}

	.header {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		form {
			display: flex;
			align-items: center;
		}
	}

	.row-icons {
		div {
			display: flex;
			justify-content: flex-end;
			font-size: 1.5em;

			.icon {
				margin-left: 0.5em;
			}
		}
	}
`;
