import FullCalendar from "@fullcalendar/react";
import React, { useEffect, useRef, useState } from "react";
import { Employee } from "src/utils/api/employees/Employees";
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interaction from '@fullcalendar/interaction'
import { EventStatus, EventType, useEvents } from "src/utils/api/employees/Events";
import usePopup from "src/utils/hooks/usePopup";
import CreateEventModal from "src/pages/employees/components/CreateEventModal";
import styled from "styled-components";
import EmployeeCalendarDayHeader from "src/pages/employees/components/EmployeeCalendarDayHeader";


export default function EmployeeCalendar(props: Props) {

    const [dateSpan, setDateSpan] = useState<{ start: Date, end: Date } | null>(null);
    const { events, addEvent, removeEvent, updateEvent, setEvents } = useEvents(props.employee.id, {
        start: dateSpan?.start,
        end: dateSpan?.end,
        condition: dateSpan !== null
    });
    const [lockedDateSpans, setLockedDateSpans] = useState<{ start: Date, end: Date }[]>([]);

    const hasInit = useRef(false);

    const [open] = usePopup();

    function isDateLocked(date: Date) {
        return lockedDateSpans.some(span => {
            return date >= span.start && date <= span.end;
        });
    }


	async function handleDateSelect(selectInfo: any) {
		let calendarApi = selectInfo.view.calendar

        if (isDateLocked(selectInfo.start) || isDateLocked(selectInfo.end)) {
            calendarApi.unselect();
            alert('Du kan inte lägga till event under låsta perioder.');
            return;
        }

        open(<CreateEventModal
            employee={props.employee}
            startDate={selectInfo.start}
            endDate={selectInfo.end}
            onCreate={async (eventData) => {
                const {event} = await addEvent(eventData);

                if (calendarApi) {
                    calendarApi.unselect();
                }

                return event;
            }}
            onClose={() => {
                calendarApi.unselect();
            }}
        />);
	}

    async function handleEventClick(clickInfo: any) {
        open(<CreateEventModal
            event={events.find(e => e.id === clickInfo.event.id)}
            employee={props.employee}
            onUpdate={async (eventData) => {
                await updateEvent(clickInfo.event.id, eventData);
            }}
            onDelete={async () => {
                await removeEvent(clickInfo.event.id);
            }}
        />);
    }

    async function handleEventChange(changeInfo: any) {

        if (events.find(e => e.id === changeInfo.event.id)?.status !== EventStatus.PENDING) {
            changeInfo.revert();
            alert('Du kan inte ändra event som redan är godkända.');
            return;
        }

        if (isDateLocked(changeInfo.event.start) || isDateLocked(changeInfo.event.end)) {
            changeInfo.revert();
            alert('Du kan inte ändra event under låsta perioder.');
            return;
        }

        try {
            const data = {
                start: changeInfo.event.start,
                end: changeInfo.event.end,
            }

            await updateEvent(changeInfo.event.id, data);
        } catch (e) {
            changeInfo.revert();
            console.error(e);
        }
    }

    function handleResizeEvent(resizeInfo: any) {

        const width = window.innerWidth;

        if (width < 768) {
            resizeInfo.view.calendar.changeView('timeGridDay');
            resizeInfo.view.calendar.setOption('height', 'auto');

            // Remove view options from the headerToolbar
            resizeInfo.view.calendar.setOption('headerToolbar', {
                left: 'prev,next',
                center: 'title',
                right: ''
            });
        }

        if (width >= 768) {
            resizeInfo.view.calendar.changeView('timeGridWeek');
            resizeInfo.view.calendar.setOption('height', 'auto');
            resizeInfo.view.calendar.setOption('headerToolbar', {
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
            });
        }
    }

    useEffect(() => {
        if (dateSpan === null) return;

        function getEventsBetween(start: Date, end: Date) {
            return events.filter(e => {
                return e.start >= start && e.end <= end;
            });
        }

        const lockedSpans = [];

        const start = dateSpan.start;
        const end = dateSpan.end;

        let date = new Date(start);

        for (let i = 0; i < 31; i++) {
            if (date >= end) break;
            const start = new Date(date);
            date.setDate(date.getDate() + 1);

            const events = getEventsBetween(start, date);
            const allEventsApproved = events.length > 0 && events.every(e => e.status === 'approved');

            if (allEventsApproved) {
                lockedSpans.push({ start, end: new Date(date) });
            }
        }

        setLockedDateSpans(lockedSpans);

    }, [dateSpan, events])


    return (
        <Style>
        
           <FullCalendar
                viewDidMount={(args) => {
                    if (hasInit.current) return;
                    handleResizeEvent({ view: args.view });
                    hasInit.current = true;
                }}
                datesSet={(args) => {
                    const start = args.view.currentStart;
                    const end = args.view.currentEnd;
                    setDateSpan({ start, end });
                }}
                locale={'sv'}
                height={'auto'}
                slotDuration={'00:30:00'}
                plugins={[dayGridPlugin, timeGridPlugin, interaction]}
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                }}
                unselectCancel={'section.popup'}
                initialView='timeGridWeek'
                firstDay={1}
                dayHeaderContent={(args) => {

                    // Only show on week.
                    if (args.view.type === 'timeGridWeek') {
                        return <EmployeeCalendarDayHeader
                            employee={props.employee}
                            events={events}
                            args={args}
                            setEvents={setEvents}
                        />
                    } else {
                        return <>{args.text}</>;
                    }

                }}
                nowIndicator={true}
                windowResize={handleResizeEvent}
                events={events.map(event => {

                    let backgroundColor = 'var(--fc-event-bg-color)';
                    let borderColor = 'var(--fc-event-border-color)';
                    let textColor = 'var(--fc-event-text-color)';

                    if (event.type === EventType.SICK_LEAVE) {
                        backgroundColor = '#dd5f5f';
                        borderColor = '#b73838';
                    }

                    if (event.type === EventType.VACATION || event.type === EventType.PARENTAL_LEAVE) {
                        backgroundColor = '#cecece';
                        borderColor = '#8e8e8e';
                        textColor = '#000';
                    }


                    if (event.status === EventStatus.APPROVED) {
                        backgroundColor = '#eaeaea';
                        borderColor = '#cfcfcf';
                        textColor = '#c2c2c2';
                    }

                    return {
                        id: event.id,
                        title: (event.name === null ? 'Event' : event.name),
                        start: event.start,
                        end: event.end,
                        backgroundColor: backgroundColor,
                        borderColor: borderColor,
                        textColor: textColor,
                    }
                })}
                businessHours={props.employee.workingHours}
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                allDaySlot={false}
                select={handleDateSelect}
                eventClick={handleEventClick}
                eventChange={handleEventChange}
            />

        </Style>
    );
}

type Props = {

    employee: Employee,
}

const Style = styled.div`

    .fc .fc-scrollgrid-section-sticky > * {
        top: 86px;
        z-index: 1000;

        .fc-col-header-cell.fc-day {
            & > .fc-scrollgrid-sync-inner {
                & > a.fc-col-header-cell-cushion {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    padding: 0px;
                    gap: 5px;

                    span.date-title-wrapper {
                        text-transform: capitalize;
                    }

                    table {
                        tr {
                            th {
                                padding: 10px;
                            }

                            td {
                                padding: 10px;
                                font-size: 12px;
                                font-weight: normal;
                            }

                            &.positive {
                                color: green;
                            }

                            &.negative {
                                color: red;
                            }
                        }
                    }
                }
            }
        }
    }

    .mobile & {
        .fc .fc-scrollgrid-section-sticky > * {
            top: 70px;
        }
    }

`