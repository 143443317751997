import React, { useState } from 'react';
import styled from 'styled-components';
import { useClosePopup } from '../../../utils/hooks/usePopup';
import Popup from '../../Popup';
import Button from '../../../elements/Button';
import sha256 from 'sha256';
import API from '../../../utils/api/API';

export default function ChangePassword({ user = undefined, uid }) {
	const close = useClosePopup();

	const [password, setPassword] = useState();

	const confirm = async () => {
		if (!user && !uid) alert('ChangePassword error, neither "user" or "uid" parameters found!');

		// Update password
		await API.put(`/users/${user ? user.id : uid}`, { password: sha256(password) }, true); // nått fel här på id undefined

		close();
	};

	return (
		<Popup close={close} width="40em" maxWidth="25em">
			<Style>
				<h2 className="title">Ändra lösenord</h2>
				<div className="spacer s"></div>
				<p>Fyll i ett nytt lösenord nedanför:</p>

				<div className="spacer xs"></div>
				<input onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Lösenord" />

				<div className="buttons">
					<Button type="secondary" onClick={close}>
						Avbryt
					</Button>
					<Button onClick={confirm}>Byt lösenord</Button>
				</div>
			</Style>
		</Popup>
	);
}

const Style = styled.div``;
