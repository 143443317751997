import PNTrading from 'src/pn-trading-sdk';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Icon from '../../elements/Icon';
import Table from '../Table';
import { useHistory } from 'react-router-dom';
import DeleteTrustee from '../../layout/popups/customers/DeleteTrustee';
import usePopup from '../../utils/hooks/usePopup';

export default function TrusteesTable({ customer }) {
	const [trustees, setTrustees] = useState();

	const history = useHistory();

	const limit = 10;

	const [open] = usePopup();

	const load = async ({ sorting, filters, page } = {}) => {
		let trustees = await PNTrading.get(`/customers/${customer}/trustees?limit=${limit}&page=${page ?? 0}&noCache=true`);

		setTrustees(trustees);
		return trustees.data;
	};

	return (
		<Style>
			<Table
				pages={trustees ? trustees.pages : 1}
				limit={limit}
				// The table requests new data when the user interacts with it.
				load={load}
				data={trustees?.data ?? []}
				structure={[
					{
						heading: 'ID',
						key: 'id',
					},
					{
						heading: 'Namn',
						key: 'name',
					},
					{
						heading: 'E-post',
						key: 'email',
					},
					{
						heading: 'Telefonnummer',
						key: 'phone',
					},
					{
						heading: '',
						className: 'row-icons',
						component: (row) => {
							return (
								<div>
									<Icon onClick={() => history.push(`/customers/${customer}/trustees/${row.id}`)}>edit</Icon>
									<Icon
										onClick={() =>
											open(
												<DeleteTrustee
													customer={customer}
													trustee={row.id}
													onDeleted={() => {
														load();
													}}
												/>
											)
										}
									>
										delete
									</Icon>
								</div>
							);
						},
					},
				]}
			/>
		</Style>
	);
}

const Style = styled.div`
	.row-icons {
		div {
			display: flex;
			justify-content: flex-end;
			font-size: 1.5em;

			.icon {
				margin-left: 0.5em;
			}
		}
	}
`;
