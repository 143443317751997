import React, { useEffect, useState } from "react"
import Button from "src/elements/Button";
import Checkbox from "src/elements/Checkbox";
import Select, { Option } from "src/elements/Select";
import Popup from "src/layout/Popup"
import { minutesToHoursAndMinutes } from "src/utils/Util";
import { useSellers } from "src/utils/api/Sellers";
import { Employee } from "src/utils/api/employees/Employees";
import { useActivities } from "src/utils/api/employees/EventActivites";
import { AddEvent, Event, EventStatus, EventType, addEvent } from "src/utils/api/employees/Events";
import { CreateExpenseArgs, createExpense } from "src/utils/api/expenses/Expenses";
import { useClosePopup } from "src/utils/hooks/usePopup";
import styled from "styled-components"

type PartialExpense = {
    id?: number;
    description: string;
    amount: number;
    attachment: null | File;
    paid_by_employee?: boolean;
    file?: {
        url: string;
        name: string;
    }
}

export default function CreateEventModal(props: Props) {
	const close = useClosePopup();

    const [type, setType] = useState('project');
    const [sellerID, setSellerID] = useState<null | number>(null);
    const [activityID, setActivityID] = useState<null | number>(null);
    const [breakMinutes, setBreakMinutes] = useState(0)
    const [billableTimeMinutes, setBillableTimeMinutes] = useState<null | number>(null)
    const [travelInStockholm, setTravelInStockholm] = useState(false)
    const [travelOutsideStockholm, setTravelOutsideStockholm] = useState(false)
    const [travelKilometers, setTravelKilometers] = useState(0)

    const [expenses, setExpenses] = useState<PartialExpense[]>([])

    const [removeValidation, setRemoveValidation] = useState('');
    
    const [loading, setLoading] = useState(false)

    const { activities } = useActivities();

	const sellersResp = useSellers();
    const sellers = sellersResp.sellers as any;

    const start = props.startDate ?? props.event?.start;
    const end = props.endDate ?? props.event?.end;

    const locked = props.event !== undefined && props.event.status !== EventStatus.PENDING;

    const durationMinutes = (start && end) ? ((end.getTime() - start.getTime()) / 1000 / 60) - (isNaN(breakMinutes) ? 0 : breakMinutes) : 0;
    const billableHours = (billableTimeMinutes ?? durationMinutes) / 60;

    const seller = sellers?.find((s: any) => s.id === sellerID);
    const activity = activities?.find((a: any) => a.id === activityID);

    function _close() {
        props.onClose && props.onClose();
        close();
    }

    async function onSubmit(e: React.FormEvent) {
        e.preventDefault();


        try {
            setLoading(true);

            const form = e.currentTarget as HTMLFormElement;
            const data = new FormData(form);

            const type = data.get('type') as EventType;

            const event: AddEvent = {
                type,
                name: data.get('name') as string,
                description: data.get('description') as string,
                privateDescription: data.get('privateDescription') as string,
                sellerID: sellerID,
                activityID: activityID,
                breakTime: Number(breakMinutes),
                billableTime: Number(billableTimeMinutes ?? (type === EventType.PROJECT ? durationMinutes : 0)),
                traveledDistance: travelKilometers,
                travelInStockholm: travelInStockholm,
            }

            if (event.name === null) {
                if (sellerID !== null && activityID !== null) {
                    if (seller && activity) {
                        event.name = `${seller.name} - ${activity.name}`;
                    }
                } else {

                    const typeName = event.type ? {
                        normal: 'Normal',
                        project: 'Projekt',
                        parental_leave: 'Föräldraledig',
                        sick_leave: 'Sjuk',
                        vacation: 'Semester',
                        vab: 'VAB',
                        unpaid_leave: 'Obetald ledighet',
                        other: 'Annan',
                    }[event.type] : 'Händelse';

                    event.name = `${typeName}`;
                }
            }

            if (props.event !== undefined) {
                // If the event is defined, we are updating an existing event.
                if (props.onUpdate) {
                    await props.onUpdate(event);
                    close();
                }
            } else {

                event.start = props.startDate ?? new Date(data.get('start') as string);
                event.end = props.endDate ?? new Date(data.get('end') as string);

                if (props.onCreate) {
                    const newEvent = await props.onCreate(event);

                    // If the event is created, try to add the expenses.
                    if (newEvent !== null && typeof newEvent === 'object' && expenses.length > 0) {
                        for (const expenseData of expenses) {
                            const createExpenseData: CreateExpenseArgs = {
                                user_id: props.employee.id,
                                amount: expenseData.amount,
                                description: expenseData.description,
                                paid_by_employee: expenseData.paid_by_employee,
                                file: expenseData.attachment,
                                event_id: newEvent.id,
                            }
                            
                            await createExpense(createExpenseData);
                        }
                    }

                    close();
                } else {

                    const resp = await addEvent(props.employee.id, event);

                    if (resp.success) {
                        props.onSubmit && props.onSubmit(resp.event);
                        close();
                    } else {
                        alert('Kunde inte spara händelse');
                    }
                }
            }

        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
    }

    function addExpense() {
        setExpenses([...expenses, {
            description: '',
            amount: 0,
            attachment: null,
            paid_by_employee: false,
        }])
    }

    function removeExpense(index: number) {
        setExpenses(expenses.filter((_, i) => i !== index))
    }

    function updateSeller(e: any) {
        setSellerID(e.value);
    }

    function updateActivity(e: any) {
        setActivityID(e.value);
    }

    function updateBreakMinutes(e: any) {
        setBreakMinutes(e.target.value);
    }

    function updateBillableMinutes(e: any) {
        setBillableTimeMinutes(e.target.value*60);
    }

    function handleTravelInStockholmChange(e: React.ChangeEvent<HTMLInputElement>) {
        console.log('Travel in Stockholm:', e.target.checked);
        setTravelInStockholm(e.target.checked);
        if (e.target.checked) {
            setTravelOutsideStockholm(false);
        }
    }

    function handleTravelOutsideStockholmChange(e: React.ChangeEvent<HTMLInputElement>) {
        console.log('Travel outside Stockholm:', e.target.checked);
        setTravelOutsideStockholm(e.target.checked);
        if (e.target.checked) {
            setTravelInStockholm(false);
        }
    }

    function handlePaidByEmployee(expense: PartialExpense) {
        return (e: React.ChangeEvent<HTMLInputElement>) => {
            const newExpenses = [...expenses];
            const index = newExpenses.indexOf(expense);
            newExpenses[index].paid_by_employee = e.target.checked;
            setExpenses(newExpenses);
        }
    }

    useEffect(() => {
        if (props.event) {
            setType(props.event.type);
            setSellerID(props.event.sellerID);
            setActivityID(props.event.activityID);
            setBreakMinutes(props.event.breakTime);
            setBillableTimeMinutes(props.event.billableTime);
            setTravelInStockholm(props.event.travelInStockholm);
            setTravelOutsideStockholm(props.event.traveledDistance > 0);
            setTravelKilometers(props.event.traveledDistance);

            setExpenses(props.event.expenses?.map((e) => {
                return {
                    id: e.id,
                    description: e.description,
                    amount: e.amount,
                    attachment: null,
                    paid_by_employee: e.paid_by_employee,
                    file: e.file ? {
                        url: e.file.url,
                        name: e.file.name,
                    } : undefined,
                }
            }));
        }
    }, [props.event])

    return (
        <Popup close={_close} width="40em" maxWidth="80em">
            <Style onSubmit={onSubmit}>
				<h2 className="title">
                    {props.event ? 'Redigera händelse' : 'Ny händelse'}
                </h2>
				<div className="spacer s"></div>

                <div className="fields">

                    {props.startDate === undefined && props.event && (    
                        <div className="field">
                            <label htmlFor="start">Från</label>
                            <input type="datetime-local" name="start" disabled value={props.event.start.toLocaleDateString('sv-SE', { year: 'numeric', month: "2-digit", day: '2-digit', hour: '2-digit', minute: '2-digit' })} />
                        </div>
                    )}

                    {props.endDate === undefined && props.event && (    
                        <div className="field">
                            <label htmlFor="end">Till</label>
                            <input type="datetime-local" name="end" disabled value={props.event.end.toLocaleDateString('sv-SE', { year: 'numeric', month: "2-digit", day: '2-digit', hour: '2-digit', minute: '2-digit' })} />
                        </div>
                    )}

                    <div className="field">
                        <label htmlFor="type">Typ av händelse *</label>
                        <select required name="type" value={type} onChange={(e) => setType(e.target.value)} disabled={locked}>
                            <option value="project">Projekt</option>
                            <option value="parental_leave">Föräldraledig</option>
                            <option value="sick_leave">Sjuk</option>
                            <option value="vacation">Semester</option>
                            <option value="vab">VAB</option>
                            <option value="unpaid_leave">Obetald ledighet</option>
                            <option value="other">Annan</option>
                        </select>
                    </div>


                    {type === EventType.PROJECT && (
                        <>
                            <div className="field">
                                <label>Säljare *</label>
                                <div className="input">
                                    <Select onChange={updateSeller} defaultValue={seller != null ? (sellers?.indexOf(seller)) : undefined} disabled={locked || props.event !== undefined}>
                                        {sellers?.map((customer: any) => (
                                            <Option key={customer.id} value={customer.id}>{`${customer.org_number} - ${customer.name}`}</Option>
                                            ))}
                                    </Select>
                                </div>
                            </div>

                            <div className="field">
                                <label>Aktivitet *</label>
                                <div className="input">
                                    <Select onChange={updateActivity} defaultValue={activity != null ? (activities?.indexOf(activity)) : undefined} disabled={locked || sellerID === null || props.event !== undefined}>
                                        {activities?.map((activity) => (
                                            <Option key={activity.id} value={activity.id}>{`${activity.name}`}</Option>
                                            ))}
                                    </Select>
                                </div>
                            </div>

                            <div className="field">
                                <label htmlFor="name">Rast (minuter)</label>
                                <input type="number" name="breakTimeMinutes" min="0" disabled={locked || sellerID === null || activityID === null} value={breakMinutes} onChange={updateBreakMinutes} />
                            </div>

                            <div className="field">
                                <label htmlFor="name">Arbetad tid</label>
                                <input type="text" disabled value={minutesToHoursAndMinutes(durationMinutes)} />
                            </div>

                            <div className="field">
                                <label htmlFor="name">Fakturerbar tid (timmar)</label>
                                <input type="number" name="billableTimeMinutes" disabled={locked || sellerID === null || activityID === null} value={billableHours == 0 ? '' : billableHours.toPrecision(3)} onChange={updateBillableMinutes} />
                            </div>

                            <Checkbox name="travelInStockholm" label="Resa i Stockholm" onChange={handleTravelInStockholmChange} checked={travelInStockholm} disabled={locked} />
                            
                            <Checkbox name="travelOutsideStockholm" label="Resa utanför Stockholm" onChange={handleTravelOutsideStockholmChange} checked={travelOutsideStockholm} disabled={locked}/>

                            {travelOutsideStockholm && (
                                <div className="field">
                                    <label htmlFor="travelKilometers">Antal kilometer</label>
                                    <input type="number" name="travelKilometers" value={travelKilometers === 0 ? '' : travelKilometers} onChange={(e) => setTravelKilometers(Number(e.target.value))} disabled={locked} />
                                </div>
                            )}

                            <div className="field">
                                <label htmlFor="description">Kommentar</label>
                                <textarea rows={8} name="description" defaultValue={props.event?.description ?? ''} disabled={locked || sellerID === null || activityID === null}></textarea>
                            </div>

                            <div className="field">
                                <label htmlFor="privateDescription">Kommentar (intern)</label>
                                <textarea rows={8} name="privateDescription" defaultValue={props.event?.privateDescription ?? ''} disabled={locked || sellerID === null || activityID === null}></textarea>
                            </div>

                            {(props.event === undefined || expenses?.length > 0) && (
                                <div className="field">
                                    <label>Utlägg</label>
                                    <br />
                                    <div className="expenses">
                                        {expenses?.map((expense, index) => {


                                            if (expense.id !== undefined) {
                                                return (
                                                    <div key={index} className="expense existing">
                                                        <label>#{index + 1}</label>
                                                        <input type="text" name={`expense[${index}][description]`} placeholder="Beskrivning" defaultValue={expense.description} disabled />
                                                        <input type="number" name={`expense[${index}][amount]`} placeholder="Summa" defaultValue={expense.amount.toString()} disabled />

                                                        {expense.file ? (
                                                            <>
                                                                <div className="file-preview-wrapper">
                                                                    <label htmlFor="name">Bifogad fil</label>
                                                                    <br />
                                                                    {(expense.file.url.endsWith('.jpg') || expense.file.url.endsWith('.png')) && (
                                                                        <div className="image-preview">
                                                                            <img src={expense.file.url} alt={expense.file.name} />
                                                                        </div>
                                                                    )}
                                                                    <span>Ladda ner <a href={expense.file.url} target="_blank" rel="noreferrer">{expense.file.name}</a></span>
                                                                </div>
                                                            </>
                                                        ) : null}
                                                    </div>
                                                )
                                            }


                                            return (
                                                <div key={index} className="expense">
                                                    <label>#{index + 1}</label>
                                                    <input type="text" name={`expense[${index}][description]`} placeholder="Beskrivning" defaultValue={expense.description} disabled={locked} onChange={(e) => {
                                                        const newExpenses = [...expenses];
                                                        newExpenses[index].description = e.target.value;
                                                        setExpenses(newExpenses);
                                                    }} />
                                                    <input type="number" name={`expense[${index}][amount]`} placeholder="Summa" defaultValue={expense.amount.toString()} disabled={locked} onChange={(e) => {
                                                        const newExpenses = [...expenses];
                                                        newExpenses[index].amount = Number(e.target.value);
                                                        setExpenses(newExpenses);
                                                    }} />
                        
                                                    <Checkbox name="paid_by_employee" label="Privat utlägg" onChange={handlePaidByEmployee(expense)} checked={expense.paid_by_employee} disabled={locked} />

                                                    <input type="file" name={`expense[${index}][attachment]`} disabled={locked} onChange={(e) => {
                                                        const newExpenses = [...expenses];
                                                        newExpenses[index].attachment = e.target.files?.[0] ?? null;
                                                        setExpenses(newExpenses);
                                                    }} />
                                                    <button type="button" className="delete" onClick={() => removeExpense(index)} disabled={locked}>Ta bort</button>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="spacer xs"></div>
                                    {props.event === undefined && <button type="button" className="button" onClick={addExpense}>Lägg till utlägg</button>}
                                </div>
                            )}

                        </>
                    )}


                </div>

                <div className="spacer s"></div>

                {props.event && !locked && (<>
                    <div className="spacer m"></div>
                    <div className="divider"></div>
                    <div className="spacer s"></div>

                    <div className="remove-wrapper">
                        <label htmlFor="remove-validation">Ta bort händelse</label>
                        <br />
                        <input type="text" name="remove-validation" placeholder="Skriv 'ta bort' för att ta bort händelsen" value={removeValidation} onChange={(e) => setRemoveValidation(e.target.value)} />
                        <div className="spacer s"></div>
                        <button 
                            type="button" 
                            className={'button delete'} 
                            onClick={() => {
                                if (props.onDelete && props.event) {
                                    props.onDelete(props.event);
                                    close();
                                }
                            }}
                            disabled={removeValidation !== 'ta bort'}
                        >
                            Ta bort
                        </button>
                    </div>

                </>)}

                {!locked && <div className="buttons">
                    <Button type={'secondary'} onClick={_close}>
                        Avbryt
                    </Button>
                    <input className="button" type="submit" value="Spara" />
                </div>}

                {locked && <div className="buttons">
                    <Button type="button" onClick={_close}>
                        Stäng
                    </Button>
                </div>}
            </Style>
        </Popup>
    )
}

type Props = {
    /**
     * If an event is passed, the modal will be used to edit the event.
     */
    event?: Event;

    employee: Employee;
    startDate?: Date;
    endDate?: Date;
    onClose?: () => void,
    onSubmit?: (event: Event) => void,
    onCreate?: (event: AddEvent) => void | Promise<Event>,
    onUpdate?: (event: AddEvent) => void | Promise<void>,
    onDelete?: (event: Event) => void | Promise<void>,
}

const Style = styled.form`
    .fields {
		width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.75rem;

		input,
        select,
		textarea {
			width: 100%;
			resize: none;
		}

        .expenses {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            .expense {
                padding: 0.5rem;
                border: 1px solid #ccc;
                background-color: #f9f9f9;
                border-radius: 0.25rem;
                display: flex;
                flex-direction: column;
                gap: 0.5rem;

                .file-preview-wrapper {
                    width: 100%;

                    .image-preview {
                        width: 100%;
                    
                        img {
                            width: 100%;
                            object-fit: contain;
                            border: 1px solid #ccc;
                            border-radius: 0.25rem;
                        }
                    }
                }
            }
        }
	}

    .checkbox-container {
        display: inline-flex;
        flex-direction: column;
        align-items: flex-start;
        padding-left: 0;
        padding-bottom: 1.75rem;
        font-size: .75em;

        .checkmark {
            font-size: 1.5rem;
            top: unset;
            bottom: 0;
            transform: unset;
        }
    }

    .remove-wrapper {
        width: 100%;
        input {
            width: 100%;
        }
    }
`