import React, { useCallback, useEffect, useState } from 'react';
import Page from '../../layout/Page';
import Card from '../../layout/Card';
import styled from 'styled-components';
import Employees, { Employee } from '../../utils/api/employees/Employees';

import EmployeeCalendar from 'src/pages/employees/components/EmployeeCalendar';
import EmployeeStats from 'src/pages/employees/components/EmployeeStats';


export default function EmployeePage(props: any) {
	let id = props.match.params.id;
    
    const [employee, setEmployee] = useState<null | Employee>(null);

    const load = useCallback(async () => {
        let employee = await Employees.get(id);
        setEmployee(employee);
        return employee;
    }, [id]);

    useEffect(() => {
        load();
    }, [id, load])


    if (employee === null) {
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className="loader"></div>
            </div>
        )
    }

	return (
		<Style>
			<Card size="full">
				<div className="header">
					<h1>{employee.firstName} {employee.lastName}</h1>
					<div className="spacer auto"></div>
				</div>

				<div className="spacer m"></div>

				<EmployeeCalendar employee={employee} />

				<div className="spacer l"></div>
				<h2>Historik</h2>
				<div className="spacer s"></div>
				<EmployeeStats employee={employee} />

				
			</Card>
		</Style>
	);
}

const Style = styled(Page)`
	.key-values {
		h1 {
			font-size: 2.5em;
		}
	}

	.row-icons {
		div {
			display: flex;
			justify-content: flex-end;
			font-size: 1.5em;

			.icon {
				margin-left: 0.5em;
			}
		}
	}
`;
