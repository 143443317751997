import React from "react";
import Card from "src/layout/Card";
import Page from "src/layout/Page";
import useExpense from "src/utils/api/expenses/useExpense";
import styled from "styled-components";

export default function PageExpense(props: any) {
	const id = props.match.params.id;

	
    const { expense, loading } = useExpense(id);
	
    if (loading || !expense) {
        return (
			<div style={{ paddingTop: 100, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
        )
    }

	const createdAt = new Date(expense.created_at).toLocaleDateString('sv-SE', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit'
	});
    
    return (
        <Style>
            <Card size="full">
				<div className="header">
					<h1>Utgift</h1>
					{expense.paid_by_employee ? <>
						<div className="spacer ws"></div>
						<div className="badge yellow">Privat utlägg</div>
					</> : <>
						<div className="spacer ws"></div>
						<div className="badge gray">Företagskort</div>
					</>}
					<div className="spacer auto"></div>
				</div>

				<div className="spacer s"></div>

				<div className="expense">

					<div className="field">
						<label htmlFor="name">Registrerad</label>
						<br />
						<input type="datetime-local" name="createdAt" value={createdAt} disabled />
					</div>

					<div className="field">
						<label htmlFor="name">Kostnad (SEK)</label>
						<br />
						<input type="number" name="amount" value={expense.amount} disabled />
					</div>

					<div className="field wide">
						<label htmlFor="name">Beskrivning</label>
						<br />
						<textarea rows={4} style={{resize: 'none'}} name="description" value={expense?.description} disabled />
					</div>

					{expense.file && <div className="field wide">
						<label htmlFor="name">Bifogad fil</label>
						<br />
						{(expense.file.url.endsWith('.jpg') || expense.file.url.endsWith('.png')) && (
							<div className="image-preview">
								<img src={expense.file.url} alt={expense.file.name} />
							</div>
						)}
						<br />
						<span>Ladda ner <a href={expense.file.url} target="_blank" rel="noreferrer">{expense.file.name}</a></span>
					</div>}

				</div>

            </Card>
        </Style>
    )
}

const Style = styled(Page)`

	.expense {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 10px;

		& > .field {
			grid-column: span 1;

			& > * {
				width: 100%;
			}

			&.wide {
				grid-column: span 2;
			}

			.image-preview {
				width: 100%;
				max-width: 400px;

				img {
					width: 100%;
					border-radius: 5px;
				}
			}
		}
	}
`;