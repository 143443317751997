import { useEffect, useState } from 'react';
import Accounting, { AccountingEntry, GetAccountingEntriesArgs } from '../Accounting';


export default function useAccountingEntries(args?: GetAccountingEntriesArgs) {
    const [entries, setEntries] = useState<AccountingEntry[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        setLoading(true);
        setError(null);
        Accounting.getAccountingEntries(args)
            .then(setEntries)
            .catch(e => setError(e.message))
            .finally(() => setLoading(false));
    }, []);

    return { entries, loading, error };
}