import { useEffect, useState } from 'react';
import API from './API';

export default class Customers {
	static async list({ page = 0, limit = 25, search, noCache = false } = { page: 0, limit: 25 }) {
		return await API.get(`/customers?page=${page}&limit=${limit}${search ? `&search=${search}` : ''}${noCache ? `&noCache=true` : ''}`);
	}

	static async get(id) {
		return await API.get(`/customers/${id}`);
	}

	static async create(data) {
		return await API.post(`/customers`, data);
	}

	static async delete(id) {
		return await API.delete(`/customers/${id}`);
	}
}

export function useCustomers() {
	const [customers, setCustomers] = useState();

	useEffect(async () => {
		let resp = await Customers.list({ limit: 10000, noCache: true });
		setCustomers(resp.data);
	}, []);

	return {
		customers,
		setCustomers,
	};
}
