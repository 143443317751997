import styled from "styled-components";
import Icon from "../../elements/Icon";
import usePopup, { useClosePopup } from "../../utils/hooks/usePopup";
import Popup from "../../layout/Popup";
import { useEffect, useState } from "react";
import Button from "../../elements/Button";
import PNTrading from "src/pn-trading-sdk";

export default function DescriptionInput({ object, defaultValue, onChange, edit, toggleEdit, allowAuto = false }) {

    const [description, setDescription] = useState(defaultValue || "")

    const [ open ] = usePopup();

    function handleChange(e) {
        setDescription(e.target.value);
        onChange && onChange(e.target.value);
    }

    function handleAutoClick() {
        open(<DescriptionGeneratePopup object={object} onAccept={description => {
            setDescription(description);
            onChange && onChange(description);
        }} />)
    }

    return (
        <Style className="input text-area">
            <textarea rows="10" onChange={handleChange} disabled={!edit} value={description}></textarea>
            <Icon onClick={toggleEdit}>{edit ? 'save' : 'edit'}</Icon>
            {allowAuto && edit && <Icon className="auto" onClick={handleAutoClick}>tips_and_updates</Icon>}
        </Style>
    )
}

const Style = styled.div`
    position: relative;

    .icon {
        position: absolute;
        top: 1rem;
        right: 1rem;
        font-size: 1.5rem;

        &.auto {
            top: 3.5rem;
            right: 1rem;
        }
    }

    textarea {
        width: 100%;
        padding-right: 3.5rem;
        resize: none;
    }

`



function DescriptionGeneratePopup({ object, onAccept }) {
	const close = useClosePopup();

    const [loading, setLoading] = useState(false)
    const [description, setDescription] = useState('')

    useEffect(async () => {
        setLoading(true);
        try {
            let ep = `/v2/ai/object-description?title=${object.title}`;
            if (object.description && object.description.trim() != '') ep += `&description=${object.description.trim()}`;
            let response = await PNTrading.get(ep)
            setDescription(response.text);
        } catch (error) {
            console.error(error)
        } finally {
            setLoading(false);
        }

    }, [object.id])

    function handleConfirm() {
        onAccept && onAccept(description);
        close();
    }

    return (
		<Popup close={close} width="40em" maxWidth="80em">
            {loading && <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '3rem',
                paddingBottom: '3rem',
            }}>
                <div className="loader"></div>
            </div>}

            {!loading && <>
                <h2>Din beskrivning:</h2>
                <div className="spacer s"></div>
                <textarea style={{resize: 'none', width: '100%'}} disabled rows="10" defaultValue={description}></textarea>
                <div className="spacer s"></div>
                <div className="buttons">
                    <Button type="secondary" onClick={close}>Avbryt</Button>
                    <Button type="" onClick={handleConfirm}>Använd</Button>
                </div>
            </>}

        </Popup>
    )
}