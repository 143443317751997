import PNTrading from 'src/pn-trading-sdk';
import React, { createContext, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Icon from '../elements/Icon';

const CategoryContext = createContext();

function getChildCategories(_categories, id) {
	if (id == 0) id = undefined;

	return _categories
		.filter((c) => c.parent == id)
		.map((category, index, categories) => {
			if (category.position == undefined) {
				let prevCat = categories[index - 1];
				let nextCat = categories[index + 1];

				if (prevCat == undefined) category.position = 0;
				else category.position = prevCat.position + 1;
			}

			category.children = getChildCategories(_categories, category.id);

			return category;
		});
}

function Row({ category, index, categories, up }) {
	return (
		<div className="row">
			<div className="row-content">
				<h3 className="title">{`${category.id} - ${category.name}`}</h3>
			</div>

			{category.children.length != 0 && (
				<div className="table">
					{category.children
						.sort((a, b) => a.position - b.position)
						.map((cat, index, categories) => (
							<Row key={cat.id} category={cat} index={index} categories={categories} />
						))}
				</div>
			)}
		</div>
	);
}

function Table({ items, parent }) {
	const { categories, setCategories } = useContext(CategoryContext);

	const up = (category) => {
		let above = items.find((c) => c.position == category.position - 1);

		console.log(category.position);
		console.log(above);

		console.table(items);
	};

	return (
		<>
			{items.map((cat, index) => (
				<Row key={cat.id} category={cat} index={index} categories={categories} up={up} />
			))}
		</>
	);
}

export default function CategoryTable() {
	const [categories, setCategories] = useState([]);

	useEffect(async () => {
		setCategories(await PNTrading.get(`/categories`));
	}, []);

	return (
		<Style>
			<CategoryContext.Provider value={{ categories, setCategories }}>
				<div className="table main-table">
					<Table items={categories.filter((c) => c.parent == 0 || c.parent == undefined)} parent={0} />
				</div>
			</CategoryContext.Provider>
		</Style>
	);
}

const Style = styled.div`
	.table.main-table {
		margin-left: 0;
	}

	.table {
		// border: red dashed 1px;

		margin-left: 2.5em;

		.row {
			display: flex;
			flex-direction: column;

			.row-content {
				max-width: 40em;
				background: #fbfbfb;
				border: solid 1px #eee;
				margin: 0.5em 0;
				padding: 1em;
				display: flex;
				align-items: center;

				.nav {
					display: flex;
					flex-direction: column;
				}

				.title {
					margin-left: 0.75rem;
				}

				.icon {
					width: 0.7em;
					height: 0.7em;
					display: flex;
					align-items: center;
					justify-content: center;
					opacity: 0.5;
					transition: opacity 150ms;
					font-size: 2.5em;

					&:hover {
						opacity: 1;
					}
				}
			}
		}
	}
`;
