import React, { useState } from 'react';
import Page from '../../layout/Page';
import Card from '../../layout/Card';
import styled from 'styled-components';

export default function PageEmptyLabels() {

	const [numObjects, setNumObjects] = useState(12)
	const [auctionName, setAuctionName] = useState('Auktionens namn')

	let index = 0;

	return (
		<Style>
			<Card size="medium" className="hide-print">
				<h2>Etikettlista</h2>
				<h5>(denna ruta visas inte vid utskrift)</h5>
				<div className="spacer s"></div>
				<p>Denna sida kan skrivas ut direkt i webbläsaren, antingen som en PDF eller via skrivare. Summeringen baseras på inbetalningar och avprickade objekt.</p>
				<div className="spacer s"></div>
				<p>Antal etiketter:</p>
				<input type="number" value={numObjects} onChange={(e) => setNumObjects(e.target.value)} />

				<div className="spacer s"></div>
				<p>Auktion:</p>
				<input type="text" value={auctionName} onChange={(e) => setAuctionName(e.target.value)} />

				<div className="spacer s"></div>
				<button onClick={() => window.print()}>Skriv ut</button>
			</Card>

			<Card size="full" className="page-wrapper">
				{new Array(Math.ceil(Number(numObjects) / 14)).fill(0).map((_, i) => {
					return (
						<div className="a4-page">
							{new Array(Number(numObjects)).fill(0)
								.filter((_, oi) => oi >= i * 14 && oi < (i + 1) * 14)
								.map((object) => {
									index++;
									return (
										<div className="object" key={object.id}>
											<div className="content">
												<div className="id">
													{index}
												</div>
												<div className="title">{auctionName}</div>
											</div>
											<div className="qr">
												<img src="/ra-logga-invoice.png" alt="" />
											</div>
										</div>
									);
								})}
						</div>
					);
				})}
			</Card>
		</Style>
	);
}

const Style = styled(Page)`
	.page-wrapper {
		background-color: transparent;
		box-shadow: none;
	}

	.a4-page {
		width: 21cm;
		min-height: 29.6cm;
		padding: 5mm;
		margin-bottom: 2rem;
		background: white;

		.object {
			width: 50%;
			float: left;
			padding: 10px;
			padding-right: 15px;
			height: 154.5px;
			display: flex;

			.content {
				flex-grow: 1;
				width: 100%;
				height: 100%;
				display: flex;
				flex-direction: column;

				.id {
					font-size: 30px;
					font-weight: bold;
				}

				.title {
					font-size: 14px;
				}
			}

			.qr {
				display: flex;
				flex-direction: column;
				align-items: flex-end;

				canvas {
					width: 60% !important;
					height: unset !important;
					aspect-ratio: 1/1 !important;
				}

				img {
					align-self: flex-end;
					justify-self: flex-end;
					margin-top: auto;
					width: 140px;
				}
			}
		}
	}

	@media print {
		padding: 0;

		.a4-page {
			margin-bottom: 0;
		}
	}
`;
