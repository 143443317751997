import { useContext, useEffect, useState } from 'react';
import { Context } from '../../App';

/**
 *
 */
export default function usePopup() {
	const { setPopups } = useContext(Context);

	const open = (p) => {
		setPopups((popups) => [...popups, p]);
	};

	return [open];
}

export const useClosePopup = () => {
	const { setPopups } = useContext(Context);

	return () => {
		setPopups((popups) => popups.filter((p, i) => i < popups.length - 1));
	};
};
