import React from 'react';
import { Link } from 'react-router-dom';
import styled from "styled-components";

export default function AccountingSubmenu() {

    return (
        <Style>
            <Link to="/redovisning/auction" className="button outlined submenu-item">Auktioner</Link>
            <Link to="/redovisning/entries" className="button outlined submenu-item">Historik</Link>
            <Link to="/redovisning/klientmedelskonto" className="button outlined submenu-item">Klientmedelskonto</Link>
        </Style>
    );
}

const Style = styled.div`
    display: flex;
    gap: 20px;

`