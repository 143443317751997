import PNTrading from 'src/pn-trading-sdk';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import TrusteeSelect from '../../components/customers/TrusteeSelect';
import SellerSelect from '../../components/sellers/SellerSelect';
import Icon from '../../elements/Icon';
import Card from '../../layout/Card';
import Page from '../../layout/Page';
import usePopup from '../../utils/hooks/usePopup';
import TrusteeSelectPanel from './components/TrusteeSelectPanel';

export default function PageSeller(props) {
	let cid = props.match.params.id;

	const [open] = usePopup();

	const [seller, setSeller] = useState();
	const [originalSeller, setOriginalSeller] = useState();
	const [edit, setEdit] = useState({});

	useEffect(async () => {
		let seller = await PNTrading.get(`/sellers/${cid}?noCache=true`);
		setSeller(seller);
		setOriginalSeller(seller);
	}, [cid]);

	useEffect(async () => {
		if (JSON.stringify(seller) == JSON.stringify(originalSeller)) return;

		setOriginalSeller(seller);
		let resp = await PNTrading.put(`/sellers/${cid}`, { ...seller, trustee: undefined, id: undefined, added: undefined });
		console.log(resp);
	}, [edit]);

	async function removeTrusteeFromProject() {
		await PNTrading.put(`/sellers/${cid}`, { trustee: -1 });
		setSeller({ ...seller, trustee: undefined })
	}

	if (!seller)
		return (
			<div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
		);

	return (
		<>
			<TopNav></TopNav>

			<Style>
				{/* Seller information. */}
				<Card size="medium" className="seller-info">
					<h2>Säljarinformation</h2>
					<div className="spacer m"></div>

					<label htmlFor="title-field">Företagsnamn</label>
					<div className="title-row">
						<input id="title-field" type="text" className="title" value={seller.name} disabled={!edit.name} onChange={(e) => setSeller((c) => ({ ...c, name: e.target.value }))} />
						<Icon
							onClick={() => {
								if (!edit.name) {
									setTimeout(() => {
										let element = document.getElementById('title-field');
										element.focus();
										element.setSelectionRange(element.value.length, element.value.length);
									}, 0);
								}

								setEdit((e) => ({ ...e, name: !e.name }));
							}}
						>
							{edit.name ? 'save' : 'edit'}
						</Icon>
					</div>

					<div className="spacer s"></div>

					<label htmlFor="org-field">Org. nummer</label>
					<div className="name-row">
						<input id="org-field" type="text" className="org" value={seller.org_number} disabled={!edit.org} onChange={(e) => setSeller((c) => ({ ...c, org_number: e.target.value }))} />

						<Icon
							onClick={() => {
								if (!edit.org) {
									setTimeout(() => {
										let element = document.getElementById('org-field');
										element.focus();
										element.setSelectionRange(element.value.length, element.value.length);
									}, 0);
								}

								setEdit((e) => ({ ...e, org: !e.org }));
							}}
						>
							{edit.org ? 'save' : 'edit'}
						</Icon>
					</div>

					<div className="spacer xs"></div>

					<label htmlFor="vat-field">Momsnummer</label>
					<div className="name-row">
						<input id="vat-field" type="text" className="org" value={seller.vat_number} disabled={!edit.vat} onChange={(e) => setSeller((c) => ({ ...c, vat_number: e.target.value }))} />

						<Icon
							onClick={() => {
								if (!edit.vat) {
									setTimeout(() => {
										let element = document.getElementById('vat-field');
										element.focus();
										element.setSelectionRange(element.value.length, element.value.length);
									}, 0);
								}

								setEdit((e) => ({ ...e, vat: !e.vat }));
							}}
						>
							{edit.vat ? 'save' : 'edit'}
						</Icon>
					</div>

					<div className="spacer xs"></div>

					<label htmlFor="vat-field">Kontonummer (bank)</label>
					<div className="name-row">
						<input id="bank_account_number-field" type="text" className="org" value={seller.bank_account_number} disabled={!edit.bank_account_number} onChange={(e) => setSeller((c) => ({ ...c, bank_account_number: e.target.value }))} />

						<Icon
							onClick={() => {
								if (!edit.bank_account_number) {
									setTimeout(() => {
										let element = document.getElementById('bank_account_number-field');
										element.focus();
										element.setSelectionRange(element.value.length, element.value.length);
									}, 0);
								}

								setEdit((e) => ({ ...e, bank_account_number: !e.bank_account_number }));
							}}
						>
							{edit.bank_account_number ? 'save' : 'edit'}
						</Icon>
					</div>

					<div className="spacer xs"></div>

					<label htmlFor="vat-field">Anteckningar</label>
					<div className="name-row">
						<textarea id="notes-field" type="text" className="org" value={seller.notes} disabled={!edit.notes} onChange={(e) => setSeller((c) => ({ ...c, notes: e.target.value }))} rows={6}></textarea>

						<Icon
							onClick={() => {
								if (!edit.notes) {
									setTimeout(() => {
										let element = document.getElementById('notes-field');
										element.focus();
										element.setSelectionRange(element.value.length, element.value.length);
									}, 0);
								}

								setEdit((e) => ({ ...e, notes: !e.notes }));
							}}
						>
							{edit.notes ? 'save' : 'edit'}
						</Icon>
					</div>
				</Card>

				{/* Trustee information. */}
				<Card size="medium">
					{seller.trustee != undefined && <>
						<div className="row center">
							<h2>Förvaltare</h2>
							<div className="spacer ws"></div>
							<Link className="button" to={`/customers/${seller.trustee.customer}/trustees/${seller.trustee.id}`}>Visa</Link>
							<div className="spacer ws"></div>
							<button className="secondary" onClick={removeTrusteeFromProject}>Ta bort från projekt</button>
						</div>
						<div className="spacer m"></div>
						<h2 className="big-title">{seller.trustee.name}</h2>
						<div className="spacer s"></div>
						<h3>{seller.trustee.phone}</h3>
						<div className="spacer xs"></div>
						<h3>{seller.trustee.email}</h3>
					</>}

					{seller.trustee == undefined && <>
						<TrusteeSelectPanel seller={seller} onChange={(trustee) => {
							setSeller({ ...seller, trustee })
						}} />
					</>}
				</Card>
			</Style>
		</>
	);
}

const TopNav = styled.div`
	width: 1500px;
	max-width: 100%;
	margin: auto;
	padding-left: 2rem;
	padding-right: 2rem;
	padding-top: 1.5rem;

	display: flex;
	justify-content: flex-end;
	align-items: center;

	.icon-button {
		cursor: pointer;
		display: flex;
		align-items: center;
		margin-left: 3rem;

		.icon {
			font-size: 1.5rem;
			margin-right: 0.5rem;
		}

		span {
			font-size: 1rem;
			color: var(--color-link);
		}
	}
`;

const Style = styled(Page)`
	.big-title {
		font-size: 3rem;
	}

	.seller-info {
		textarea {
			width: 350px;
			max-width: calc(100% - 2.25rem);
			resize: none;
		}

		.title-row,
		.name-row {
			display: flex;
			align-items: center;

			input {
				padding: 0;
				border-radius: 0;
				border: none;
				max-width: calc(100% - 2.25rem);

				&:disabled {
					background: none;
					color: black;
				}
			}

			.title {
				font-size: 3rem;
			}

			.name {
				font-size: 1rem;
			}

			.icon {
				font-size: 1.25rem;
				margin-left: 1rem;
			}
		}

		.rows {
			border-top: 1px solid #eee;
			margin-top: 1rem;
			padding-top: 1rem;

			.row {
				color: #666;
				font-size: 0.85rem;
				margin-bottom: 0.5rem;

				a {
					color: inherit;
					margin-left: 0.3em;
				}
			}
		}
	}
`;
