import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useClosePopup } from '../../../utils/hooks/usePopup';
import Popup from '../../Popup';
import Button from '../../../elements/Button';
import sha256 from 'sha256';
import API from '../../../utils/api/API';

export default function UnblockUser({ onUnblock = () => {}, uid }) {
	const close = useClosePopup();

	const confirm = async () => {
		await API.post(`/users/${uid}/unblock`);
		onUnblock();
		close();
	};

	return (
		<Popup close={close} width="40em" maxWidth="25em">
			<Style>
				<h2 className="title">Avsluta spärr</h2>
				<div className="spacer s"></div>
				<p>Användaren kommer återigen få normal tillgång till plattformen</p>

				<div className="spacer xs"></div>

				<div className="buttons">
					<Button type="secondary" onClick={close}>
						Avbryt
					</Button>
					<Button onClick={confirm}>Ta bort spärr</Button>
				</div>
			</Style>
		</Popup>
	);
}

const Style = styled.div`
	textarea {
		width: 100%;
		resize: none;
	}
`;
