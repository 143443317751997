import { useEffect } from 'react';

/**
 * Uses a resizelistener on the window object to add a class on the body element.
 * This is used from css styling instead of regular breakpoints.
 */
export default function useResizeHandler() {
	useEffect(() => {
		let resize = () => {
			let width = window.innerWidth;

			let device = 'desktop';
			if (width < 1000) device = 'mobile';

			document.body.classList.remove('desktop');
			document.body.classList.remove('tablet');
			document.body.classList.remove('mobile');
			document.body.classList.add(device);
		};
		resize();

		window.addEventListener('resize', resize);
		return () => window.removeEventListener('resize', resize);
	}, []);
}
