import React from 'react';
import styled from 'styled-components';
import { useClosePopup } from '../../../utils/hooks/usePopup';
import Button from '../../../elements/Button';
import Popup from 'src/layout/Popup';
import { formatPrice } from 'src/utils/Util';
import Accounting, { AccountableAuction } from 'src/utils/api/accounting/Accounting';

export default function DiscloseItems({ auction, itemIds, commissionPercentage, commisionTotal, onComplete}: Props) {
	const close = useClosePopup();

	const confirm = async () => {
		await Accounting.discloseItems(auction.id, commissionPercentage, itemIds);
		if (onComplete) onComplete();
		close();
	};

	const ownedItems = auction.items.filter(i => i.owned === true && itemIds.includes(i.id));
	const ownedItemsTotal = ownedItems.reduce((acc, i) => acc + i.net_amount + i.vat_amount, 0);

	const toDisclose = commisionTotal * 1.25 + auction.service_fee_net + auction.service_fee_vat + ownedItemsTotal;

	return (
		<Popup close={close} width="40em" maxWidth="25em">
			<Style>
				<h2 className="title">Redovisa intäkt</h2>
				<div className="spacer s"></div>
				<p>Intäkten enligt nedan kommer att redovisas och de berörda objekten kommer att markeras som redovisade i systemet.</p>
				<div className="spacer s"></div>
				<pre>
					Provision: <strong>{formatPrice(commisionTotal * 1.25)}</strong><br />
					Serviceavgift: <strong>{formatPrice(auction.service_fee_net + auction.service_fee_vat)}</strong><br />
					Övrigt: <strong>{formatPrice(ownedItemsTotal)}</strong><br />
					<br />
					Att redovisa: <strong>{formatPrice(toDisclose)}</strong><br />
				</pre>

				<div className="spacer xs"></div>

				<div className="buttons">
					<Button type="secondary" onClick={close}>
						Avbryt
					</Button>
					<Button onClick={confirm}>Redovisa</Button>
				</div>
			</Style>
		</Popup>
	);
}

const Style = styled.div``;

type Props = {
	auction: AccountableAuction;
	itemIds: number[];
	commissionPercentage: number;
	commisionTotal: number;
	onComplete?: () => void;
};