import API from './API';

export default class Images {
	static async list({ page = 0, limit = 25, ids }) {
		let res = await API.get(`/media?page=${page}&limit=${limit}${ids ? `&ids=${ids.join(',')}` : ''}&noCache=true`, true);

		return res;
	}

	static async get(id) {
		return await API.get(`/media/${id}`);
	}

	static async delete(id) {
		return await API.delete(`/media/${id}`);
	}

	static async upload(data) {
		console.log([...data.entries()]);

		let response = await fetch(`${API.getURL()}/media`, {
			method: 'POST',
			body: data,
			headers: {
				'Content-Type': 'application/form-data',
				authorization: `Bearer ${localStorage.getItem('user')}`,
			},
		});

		return response;
	}
}
