import React, { useState } from 'react';
import Table from '../../components/Table';
import Page from '../../layout/Page';
import Card from '../../layout/Card';
import Icon from '../../elements/Icon';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Employees from '../../utils/api/employees/Employees';


export default function EmployeeList() {
    
    const history = useHistory();

    const [employees, setEmployees] = useState(null);

    async function load({ sorting, filters, page } = {}) {
		let employees = await Employees.list();
		setEmployees(employees);
		return employees;
	};

	return (
		<Style>
			<Card size="full">
				<div className="header">
					<h1>Anställda</h1>
					<div className="spacer auto"></div>
				</div>

				<div className="spacer m"></div>

				<Table
					pages={1}
					limit={-1}
					tableID={'main_employees_table'}
					// The table requests new data when the user interacts with it.
					load={load}
					data={employees ?? []}
					structure={[
						{
							heading: 'ID',
							key: 'id',
						},
						{
							heading: 'Namn',
							component: (user) => `${user.firstName} ${user.lastName}`,
						},
						{
							heading: '',
							className: 'row-icons',
							component: (row) => {
								return (
									<div>
										<Icon onClick={() => history.push(`/employees/${row.id}`)}>person</Icon>
										<Icon onClick={() => history.push(`/employees/${row.id}/report-card`)}>description</Icon>
									</div>
								);
							},
						},
					]}
				/>
			</Card>
		</Style>
	);
}

const Style = styled(Page)`
	.key-values {
		h1 {
			font-size: 2.5em;
		}
	}

	.row-icons {
		div {
			display: flex;
			justify-content: flex-end;
			font-size: 1.5em;

			.icon {
				margin-left: 0.5em;
			}
		}
	}
`;
