import {Objects} from 'src/pn-trading-sdk/Objects';
import { useEffect, useState } from 'react';

export default function useCategories() {
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		(async () => {
			let categories = await Objects.getCategories();
			setCategories(categories);
		})();
	}, []);

	return categories;
}
