import React, { useState } from "react";
import { Employee, useEmployeeStats } from "src/utils/api/employees/Employees";
import styled from "styled-components";

export default function EmployeeStats(props: Props) {

    const { stats } = useEmployeeStats(props.employee.id);

    const [expanded, setExpanded] = useState<null | number>(null);

    function handleClickRow(id: number) {
        if (expanded === id) {
            setExpanded(null);
        } else {
            setExpanded(id);
        }
    }

    if (stats === null) {
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div className="loader"></div>
            </div>
        )
    }

    return (
        <Style>
        
            <table>
                <thead>
                    <tr>
                        <th>Händelse</th>
                        <th>Tid</th>
                    </tr>
                </thead>

                <tbody>
                    {stats.map((stat, i) => {

                        const hasData = stat.data && Object.keys(stat.data).length > 0;

                        return (
                            <>
                                <tr key={'row' + i} className="stats-row" onClick={() => handleClickRow(i)}>
                                    <td>{stat.name}</td>
                                    <td>{stat.created_at.toLocaleDateString('sv-SE', { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</td>
                                </tr>

                                {expanded === i && (
                                    <tr key={'expanded' + i}>
                                        <td colSpan={2}>
                                            <div className="expanded">
                                                {hasData && (
                                                    <div className="key-values">
                                                        {Object.keys(stat.data).map((key, i) => {
                                                            return (
                                                                <div className="key-value" key={i}>
                                                                    <div className="key">{key}</div>
                                                                    <div className="value">{stat.data[key]}</div>
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                )}

                                                {!hasData && (
                                                    <div className="no-data">Ingen data</div>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            
                            </>
                        );
                    })}
                </tbody>

            </table>

        </Style>
    );
}

type Props = {
    employee: Employee,
}

const Style = styled.div`

    table {
        width: 100%;
        border-collapse: collapse;

        thead {
            background-color: #f0f0f0;
            border-bottom: 1px solid #d0d0d0;

            th {
                padding: 10px;
                text-align: left;
            }
        }

        tbody {
            tr {
                cursor: pointer;
                border-bottom: 1px solid #d0d0d0;

                &.stats-row {
                    &:hover {
                        background-color: #f0f0f0;
                    }
                }

                td {
                    padding: 10px;

                    &:last-child {
                        text-transform: capitalize;
                    }
                }
            }

            .expanded {
                padding: 10px;
                cursor: default;
                background-color: #f0f0f0;

                .key-values {

                    * {
                        font-family: monospace;
                    }

                    .key-value {
                        display: flex;
                        justify-content: space-between;
                        padding: 5px 0;

                        .key {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }

`