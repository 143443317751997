import { useEffect, useState } from 'react';

/**
 * Behaves just like a state but will keep the data stored in localstorage between sessions.
 *
 * @param {string} key The local storage key.
 * @param {object} defaultValue Initial value.
 */
export default function useStoredState(key, defaultValue) {
	// Load data from local storage.
	let _default = (typeof localStorage == 'object' && JSON.parse(localStorage.getItem(key))) || defaultValue;

	// Internal state.
	const [state, setState] = useState(_default);

	// Update local storage on state updates.
	useEffect(() => {
		if (typeof localStorage != 'object') return;

		// Remove if undefined.
		if (state == undefined) return localStorage.removeItem(key);

		let storedValue = localStorage.getItem(key);
		let stringified = JSON.stringify(state);

		// Check if changed from default.
		if (storedValue == stringified) return;

		// Update storage.
		localStorage.setItem(key, stringified);
	}, [state]);

	return [state, setState];
}
