import PNTrading from 'src/pn-trading-sdk';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Icon from '../elements/Icon';
import Page from '../layout/Page';
import BlockUser from '../layout/popups/user/BlockUser';
import ChangePassword from '../layout/popups/user/ChangePassword';
import UnblockUser from '../layout/popups/user/UnblockUser';
import VerifyUser from '../layout/popups/user/VerifyUser';
import usePopup from '../utils/hooks/usePopup';
import EndedObjects from './user/EndedObjects';
import SendNotificationPopup from '../layout/popups/user/SendNotificationPopup';
import UserDetailsCard from 'src/pages/user/UserDetailsCard';
import UserAddressCard from 'src/pages/user/UserAddressCard';

export default function PageUser(props) {
	let uid = props.match.params.id;

	const [open] = usePopup();

	const [user, setUser] = useState();

	useEffect(() => {
		(async () => {
			let user = await PNTrading.get(`/users/${uid}`);
			setUser(user);
		})()
	}, [uid]);


	if (!user)
		return (
			<div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
		);

	return (
		<>
			<TopNav>
				{user.verified == false && <div className="icon-button" onClick={() => open(<VerifyUser uid={uid} onConfirm={() => setUser(u => ({...u, verified: true}))} />)}>
					<button>Verifiera användare</button>
				</div>}

				<div className="icon-button" onClick={() => open(<SendNotificationPopup user={user} />)}>
					<Icon>message</Icon>
					<span>Skicka meddelande</span>
				</div>

				<div className="icon-button" onClick={() => open(<ChangePassword uid={uid} />)}>
					<Icon>edit</Icon>
					<span>Ändra lösenord</span>
				</div>

				{!user.blocked ? (
					<div
						className="icon-button"
						onClick={() =>
							open(
								<BlockUser
									uid={uid}
									onBlock={(reason) => {
										setUser((u) => ({
											...u,
											blocked: 1,
											blocked_reason: reason,
										}));
									}}
								/>
							)
						}
					>
						<Icon>block</Icon>
						<span>Spärra användare</span>
					</div>
				) : (
					<div
						className="icon-button"
						onClick={() =>
							open(
								<UnblockUser
									uid={uid}
									onUnblock={(reason) => {
										setUser((u) => ({
											...u,
											blocked: 0,
											blocked_reason: null,
										}));
									}}
								/>
							)
						}
					>
						<Icon>check_circle_outline</Icon>
						<span>Avsluta spärr</span>
					</div>
				)}
			</TopNav>

			<Style>
				<UserDetailsCard userID={uid} />

				<UserAddressCard userID={uid} />

				<EndedObjects user={user} />
			</Style>
		</>
	);
}

const TopNav = styled.div`
	width: 1500px;
	max-width: 100%;
	margin: auto;

	padding-top: 1.5rem;

	display: flex;
	justify-content: flex-end;
	align-items: center;

	.icon-button {
		cursor: pointer;
		display: flex;
		align-items: center;
		margin-left: 3rem;

		.icon {
			font-size: 1.5rem;
			margin-right: 0.5rem;
		}

		span {
			font-size: 1rem;
			color: var(--color-link);
		}
	}
`;

const Style = styled(Page)`
`;
