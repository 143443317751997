import PNTrading from "src/pn-trading-sdk/index";

export type UploadedFile = {
    url: string;
    name: string;
    id: number;
}

export async function uploadFile(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    const resp = await fetch(`${PNTrading.getURL()}/files`, {
        method: 'POST',
        body: formData,
        headers: {
            authorization: `Bearer ${localStorage.getItem('_pn_241031_usertoken')}`,
        }
    });

    const data = await resp.json();

    if (data.url) {
        return data as UploadedFile;
    }
}