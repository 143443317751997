import React from 'react';
import styled from 'styled-components';

export default function Page({ className = "", children }) {
	return (
		<PageStyle className={className}>
			<section className="wrapper">{children}</section>
		</PageStyle>
	);
}

const PageStyle = styled.main`
	padding: 2em;

	/* height: 100%; */
	/* overflow: auto; */

	& > .wrapper {
		width: 1500px;
		max-width: 100%;
		margin: auto;

		display: grid;
		// flex-wrap: wrap;
		gap: 2em;
		grid-template-columns: repeat(4, 1fr);
		// grid-template-columns: repeat(auto-fill, 200px);
	}

	body.tablet & {
		& > .wrapper {
			width: 100%;
			display: grid;
			gap: 2em;
			grid-template-columns: repeat(4, 1fr);
		}
	}

	body.mobile & {
		padding: 0.5rem;
		padding-top: 1rem;
		padding-bottom: 1rem;

		& > .wrapper {
			width: 100%;
			display: grid;
			gap: 1em;
			grid-template-columns: 1fr;
		}
	}
`;
