import React from "react";
import Table from "src/components/Table";
import Card from "src/layout/Card";
import Page from "src/layout/Page";
import styled from "styled-components";
import AccountingSubmenu from "src/pages/accounting/components/AccountingSubmenu";
import Icon from "src/elements/Icon";
import { Link } from "react-router-dom";
import useAccountSummary from "src/utils/api/accounting/hooks/useAccountSummary";
import { AccountEvent } from "src/utils/api/accounting/Accounting";
import { formatPrice } from "src/utils/Util";

export default function AccountSummaryPage() {

	const { events, loading } = useAccountSummary();

    if (loading) {
        return (
			<div style={{ paddingTop: 100, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
        )
    }

	console.log(events)
    
    return (
        <Style>
			<AccountingSubmenu />
            <Card size="full">
				<div className="header">
					<h1>Klientmedelskonto</h1>
					<div className="spacer auto"></div>
				</div>

				<p style={{maxWidth: 600}}>Summeringen av klientmedelskontot är en representation av inkomster och redovisade utgifter i CMS:et. Inbetalningar som görs av kunder räknas som inkomst och när en redovisning görs så blir det utgifter. Den senaste raden bör ha 0 kr i saldo, annars är det saker som inte redovisats!</p>


				<div className="spacer m"></div>

                <Table
					data={events}
					structure={[
						{
							heading: 'Händelse',
							key: 'title',
							component: (entry: AccountEvent) => {

								if (entry.id.startsWith('i')) {
									return (
										<Link to={`/invoices/${entry.id.replace('i', '')}`}>
											<span>{entry.title}</span>
										</Link>
									)
								}

								return (
									<span>{entry.title}</span>
								)
							}
						},
						{
							heading: 'Datum',
							key: 'date',
							sortable: true,
							sort: (a: AccountEvent, b: AccountEvent, order: string) => {

								if (order === 'asc') {
									return a.index - b.index;
								}

								if (order === 'desc') {
									return b.index - a.index;
								}
							},
							component: (entry: AccountEvent) => {
								return (
									<span>{new Date(entry.date).toLocaleDateString('sv-SE', {
										year: 'numeric',
										month: '2-digit',
										day: '2-digit',
										hour: '2-digit',
										minute: '2-digit'
									})}</span>
								)
							}
						},
						{
							heading: 'Summa',
							key: 'amount',
							sortable: true,
							cellStyle: {
								textAlign: 'right'
							},
							className: 'align-right',
							component: (entry: AccountEvent) => {
								return (
									<span>{formatPrice(entry.amount, false)}</span>
								)
							}
						},
						{
							heading: 'Saldo',
							key: 'sum',
							cellStyle: {
								textAlign: 'right'
							},
							className: 'align-right',
							component: (entry: AccountEvent) => {
								return (
									<span>{formatPrice(entry.sum, false)}</span>
								)
							}
						},
					]}
				/>

            </Card>
        </Style>
    )
}

const Style = styled(Page)`
	.key-values {
		h1 {
			font-size: 2.5em;
		}
	}

	.filters {
		border-radius: 0.5rem;
		display: flex;
		flex-wrap: wrap;

		.checkbox-container {
			font-size: 1em;
		}
	}

	.header {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		form {
			display: flex;
			align-items: center;
		}
	}

	.align-right {
		text-align: right;
	}

	.row-icons {
		div {
			display: flex;
			justify-content: flex-end;
			font-size: 1.5em;

			.icon {
				margin-left: 0.5em;
			}
		}
	}
`;