import React from 'react'
import usePopup from '../../../utils/hooks/usePopup'
import Alert from '../../../layout/popups/Alert'
import PNTrading from 'src/pn-trading-sdk'
import Icon from '../../../elements/Icon'

const SendWinningMailToUserButton = ({ id, user, username }) => {

    const [open] = usePopup()

    const onClick = () => {
        open(<Alert 
            title={`Detta kommer skicka ut mejl till ${username}`}
            text={'Mejlet kommer att skickas ut oavsett om användaren tidigare fått det eller inte, använd bara detta om något blivit fel när det automatiska mejlet gått ut!<br /><br />OBS! Tänk på att detta kommer skicka vinnarmejlet med uppgifterna som var när objektet avslutades, dvs. förändringar som skett på denna betalning kommer inte att reflekteras i mejlet.'}
            cancelText={'Avbryt'}
            confirmText={'Skicka mejl'}
            onConfirm={async () => {

                try {
                    await PNTrading.post(`/auctions/${id}/send-winning-mail?user=${user}`)
                    
                    open(<Alert 
                        title={'Mejlet har skickats!'}
                        text={'Mejlet har skickats till alla användare som vunnit auktionen.'}
                    />)
                } catch(e) {
                    open(<Alert 
                        title={'Mejlet kunde inte skickas'}
                        text={'Någonting har blivit fel! Mejlet kunde inte skickas, kolla konsollen för mer information.'}
                    />)
                }
            }}
        />)
    }

    return (
        <Icon onClick={onClick}>send</Icon>
    )
}


export { SendWinningMailToUserButton }