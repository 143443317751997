import PNTrading from "src/pn-trading-sdk";
import { useState } from "react";
import TrusteeSelect from "../../../components/customers/TrusteeSelect";
import CustomerSelect from "../../../components/CustomerSelect";
import SellerSelect from "../../../components/sellers/SellerSelect";

export default function TrusteeSelectPanel({ seller, onChange }) {

    const [customer, setCustomer] = useState(undefined)
    const [trustee, setTrustee] = useState(undefined)

    async function submit(e) {
        e.preventDefault();

		let resp = await PNTrading.put(`/sellers/${seller.id}`, { trustee });

        if (onChange && resp.trustee) {
            onChange(resp.trustee)
        }
    }

    return (
        <form style={{maxWidth: 350, margin: 'auto'}} onSubmit={submit}>
            <div className="spacer l"></div>
            <h2 style={{textAlign: 'center'}}>Välj förvaltare</h2>
            <div className="spacer s"></div>
            <p style={{textAlign: 'center'}}>Detta projekt har ingen säljare, välj en i listan nedanför</p>
            <div className="spacer s"></div>

            <CustomerSelect 
                onChange={(v) => {setCustomer(v.value); setTrustee(undefined)}} 
                defaultValueID={customer} 
            />
            <div className="spacer s"></div>
            {customer !== undefined && <TrusteeSelect 
                customer={customer} 
                onChange={(v) => setTrustee(v.value)} 
                defaultValueID={trustee} 
            />}

            <div className="spacer m"></div>

            <button disabled={trustee == undefined} style={{marginLeft: '50%', transform: 'translateX(-50%)'}}>Spara förvaltare</button>
        </form>
    )
}