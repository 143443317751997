import { useEffect, useState } from "react";
import { CustomerInvoice, getCustomerInvoices } from "src/utils/api/customer-invoices/CustomerInvoices";

export default function useCustomerInvoices() {

    const [customerInvoices, setCustomerInvoices] = useState<CustomerInvoice[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            setCustomerInvoices(await getCustomerInvoices());
            setLoading(false);
        })();
    }, []);

    return {
        customerInvoices,
        loading
    }
}