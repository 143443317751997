import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Button from '../../elements/Button'

export default function Gallery({images, loading = false}) {

    const [fullSize, setFullSize] = useState(false)

    const sliderRef = useRef()
    const previewRef = useRef()
    const gallery = useRef()
    const [imageIndex, setImageIndex] = useState(0);
    
    // Add scroll listener for image index.
    useEffect(() => {
        if (!sliderRef.current) return;

        // Get the index by dividing scroll position by container width.
        const scroll = e => {
            let scroll = e.target.scrollLeft / e.target.clientWidth;
            setImageIndex(scroll)
        }

        sliderRef.current.addEventListener('scroll', scroll, {passive: true})
        return () => sliderRef.current && sliderRef.current.removeEventListener('scroll', scroll, {passive: true})
    }, [sliderRef, previewRef])


    const handleGalleryClick = (e) => {
        if (gallery.current == e.target) {
            setFullSize(false)
        }
    }

    const openFullSize = () => {
        setFullSize(true)
    }

    const [mobile] = [false]



    const imageSize = fullSize ? 1024 : 500;
    const imageVariant = (fullSize || mobile) ? '1920' : '500x500'
    const imageFit = (fullSize || mobile) ? 'contain' : 'cover'
    
    return <>

        {!mobile && fullSize && <div style={{
            width: '100%',
            height: 0,
            paddingBottom: '100%',
            border: 'var(--color-light-gray) solid 1px',
            borderRadius: 'var(--radius)'
        }} />}

        <Style ref={gallery} className={`gallery ${!mobile && fullSize ? 'full-size' : ''}`} onClick={handleGalleryClick}>

            {!fullSize && <Button onClick={openFullSize}>
                Visa galleri
            </Button>}

            <div ref={sliderRef} className={`slider ${loading ? 'placeholder' : ''}`}>
                {images?.map(image => {

                    if (!image) return null;

                    return (
                        <div className="image-wrapper">
                            <img 
                                key={image.id}
                                src={image.sizes[imageVariant]}
                                width={imageSize}
                                height={imageSize}
                                objectFit={imageFit}
                                priority={true}
                                alt={''}
                            />
                        </div>
                    )
                })}
            </div>

            <div className={`index ${loading ? 'placeholder' : ''}`}>{Math.round(imageIndex) + 1} / {images.length}</div>
            
        </Style>
    </>
}

const Style = styled.div`

    width: 100%;

    display: flex;
    flex-direction: column;

    &.full-size {
        position: fixed;
        z-index: 10000;
        top: 0;
        left: 0;
        max-width: 100vw !important;
        width: 100vw !important;
        height: 100%;
        background: rgba(0, 0, 0, 0.9);
        cursor: zoom-out;

        * {
            cursor: auto;
        }

        .slider {
            position: fixed;
            top: 50%; 
            left: 50%;
            transform: translate(-50%, -50%);
            width: calc(100vw - 4rem);
            max-width: calc(100vh - 4rem);
            height: calc(100vh - 4rem);
            padding-bottom: 0;
            display: flex;

            & > * {
                width: 100% !important;
                padding-bottom: 0% !important;
            }
        }

        .index {
            position: fixed;
            bottom: 0.1rem;
            left: 50%;
            transform: translateX(-50%);
            color: white;
            display: block;
        }
    }

    .slider {
        scroll-behavior: smooth;
        border-radius: var(--radius);
        border: var(--color-light-gray) solid 1px;
        display: none;
        width: 100%;
        height: 0;
        padding-bottom: calc(100% - 2px);
        overflow-x: auto;
        overflow-y: hidden;
        scroll-snap-type: x mandatory;
        cursor: zoom-in;

        .image-wrapper {
            flex-shrink: 0 !important;
            scroll-snap-align: start !important;
            width: 100% !important;
            height: 0;
            padding-bottom: 100% !important;
            transition: opacity 500ms !important;
            position: relative;

            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        
        scrollbar-width: none;
        ::-webkit-scrollbar { display: none; }

        &.placeholder {
            & > * {
                opacity: 0;
            }
        }
    }

    .index {
        display: none;
        user-select: none;
        text-align: center;
        align-self: center;
        color: var(--color-subtext);
        font-size: calc(13rem / 16);
        margin: 0.6em;
    }


    &.full-size {
        img {
            animation: none !important;
            background: var(--color-dark-gray);
        }
    }

    .mobile & {
        img {
            animation: none !important;
            background: var(--color-light-gray);
        }
    }
`