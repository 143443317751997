import React from 'react';
import styled from 'styled-components';

export default function Card({ className = "", children, size = 'small' }) {
	return <CardStyle className={`card ${size} ${className}`}>{children}</CardStyle>;
}

const CardStyle = styled.div`
	background: white;
	padding: 2rem;
	box-sizing: border-box;
	box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.05);
	border-radius: 0.5rem;
	min-width: 0;

	&.small {
		grid-column: span 1;
	}

	&.medium {
		grid-column: span 2;
	}

	&.medium-large {
		grid-column: span 3;
	}

	&.large {
		grid-column: span 4;
	}

	&.full {
		grid-column: 1/-1;
	}

	body.mobile & {
		grid-column: span 1;
		padding: 1rem;
	}
`;
