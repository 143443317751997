import React, { useContext, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import API from '../../utils/api/API';
import Button from '../../elements/Button';
import Popup from '../Popup';
import { useClosePopup } from '../../utils/hooks/usePopup';

export default function UploadObjects({ auctionID, onComplete = () => {} }) {
	let history = useHistory();
	const close = useClosePopup();

	const [loading, setLoading] = useState();

	const controller = useRef(new AbortController());

	const abort = () => {
		controller.current.abort();
		close();
	};

	const submit = async (e) => {
		e.preventDefault();
		let form = e.target;

		const { signal } = controller.current;

		setLoading({
			message: 'Laddar upp filer...',
		});

		let formData = new FormData(e.target);

		fetch(`${API.getURL()}/auctions/${auctionID}/objects`, {
			method: 'POST',
			body: formData,
			headers: {
				authorization: `Bearer ${localStorage.getItem('_pn_241031_usertoken')}`,
			},
			signal,
		})
			.then(async (res) => {
				if (res.status != 200) {
					console.error(res.status, await res.text());
					alert('Uppladningen misslyckades, försök igen. Ett felmeddelande kan hittas i konsolen.');
					close();
					return;
				}

				async function readAllChunks(readableStream) {
					const reader = readableStream.getReader();
					const chunks = [];

					let done, value;
					while (!done) {
						({ value, done } = await reader.read());
						if (done) {
							return chunks;
						}

						recievedChunk(new TextDecoder().decode(value));
						chunks.push(value);
					}
				}

				let images = 1;
				let numImages = 0;
				const recievedChunk = (val) => {
					let lines = val.split('\n');

					for (let line of lines) {
						let parts = line.split('=');
						let id = parts[0];
						let data = parts[1];

						switch (id) {
							case 'objects_parsed':
								setLoading({ ...loading, message: 'Extraherar bilder...' });
								break;

							case 'num_images':
								numImages = Number(data);
								setLoading({ ...loading, message: `Formaterar och laddar upp bild 1 av ${data} (0%)...` });
								break;

							case 'image_uploaded':
								images++;
								setLoading({ ...loading, message: `Formaterar och laddar upp bild ${images} av ${numImages} (${Math.round((images / numImages) * 100)}%)...` });
								break;

							case 'invalid_sellers':
								alert('Objekten med följande placeringar har felaktiga säljare: ' + data);
								abort();
								break;

							default:
								break;
						}
					}
				};

				readAllChunks(res.body).then(async (chunks) => {
					console.log('done');
					setLoading(false);
					await onComplete();
					if (close) close();
				});
			})
			.catch((e) => {
				console.log(e);
			});
	};

	return (
		<Popup close={close} width="40em" maxWidth="80em">
			<Style onSubmit={submit}>
				<h2 className="title">Importera objekt</h2>
				<div className="spacer m"></div>

				{!loading && (
					<>
						<div className="fields">
							<label htmlFor="object_file">Objektfil (.xlsx)</label>
							<br />
							<div className="spacer xs"></div>
							<input required type="file" name="object_file" accept='.xlsx' />

							<div className="spacer m"></div>

							<label htmlFor="image_file">Bilder (.zip)</label>
							<br />
							<div className="spacer xs"></div>
							<input required type="file" name="image_file" accept='.zip' />
							<div className="spacer s"></div>
						</div>
					</>
				)}

				{loading && (
					<>
						<div className="spacer l"></div>
						<div className="loader" />
						<div className="spacer s"></div>
						<p className="status">{loading.message}</p>
						<div className="spacer l"></div>
					</>
				)}

				<div className="spacer s"></div>

				<div className="buttons">
					<Button type={'secondary'} onClick={abort}>
						Avbryt
					</Button>
					<input className="button" type="submit" value="Spara" />
				</div>
			</Style>
		</Popup>
	);
}

const Style = styled.form`
	.fields {
		width: 100%;

		input,
		textarea {
			width: 100%;
			resize: none;
		}
	}

	.loader {
		margin: auto;
	}

	.status {
		font-size: 0.8em;
		opacity: 0.6;
		margin: auto;
		text-align: center;
	}
`;
