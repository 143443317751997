import React from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import BackgroundLoader from '../layout/BackgroundLoader';
import Navbar from '../layout/Navbar';
import API from './api/API';

export const PrivateRoute = ({ location, blank = false, lockTo, component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) =>
				API.isSignedIn() && (lockTo ? API.lockTo(lockTo) : true) ? (
					<>
						{!blank && <Navbar />}
						{!blank && <BackgroundLoader />}
						<Component {...props} />
					</>
				) : (
					<Redirect to={{ pathname: '/login', state: { from: props.location } }} />
				)
			}
		/>
	);
};
