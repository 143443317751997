import React, { useContext, useEffect, useState } from 'react';
import Table from '../../components/Table';
import Page from '../../layout/Page';
import Card from '../../layout/Card';
import Icon from '../../elements/Icon';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import usePopup from '../../utils/hooks/usePopup';
import Sellers from '../../utils/api/Sellers';
import DeleteCustomer from '../../layout/popups/customers/DeleteCustomer';
import DeleteSeller from '../../layout/popups/sellers/DeleteSeller';

export default function PageSellers() {
	const limit = 10;

	const history = useHistory();

	const [sellers, setSellers] = useState();
	const [open] = usePopup();

	const isLoading = sellers == undefined;

	const load = async ({ search, sorting, filters, page } = {}) => {
		let sellers = await Sellers.list({ search, limit, page: page ?? 0, noCache: true });
		setSellers(sellers);

		return sellers.data;
	};

	useEffect(() => load(), []);

	function handleSearch() {
		let tid = undefined;
		return (event) => {
			clearTimeout(tid);
			tid = setTimeout(() => {
				load({ search: event.target.value });
			}, 500);
		}
	}

	if (isLoading)
		return (
			<div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
		);

	return (
		<Style>
			<Card size="full">
				<div className="header">
					<h1>Projekt</h1>
					<div className="spacer auto"></div>
					<input type="text" placeholder="Sök" onChange={handleSearch()} />
				</div>

				<div className="spacer m"></div>

				<Table
					pages={sellers ? sellers.pages : 1}
					limit={limit}
					tableID={'sellers'}
					// The table requests new data when the user interacts with it.
					load={load}
					data={sellers?.data ?? []}
					structure={[
						{
							heading: 'ID',
							key: 'id',
						},
						{
							heading: 'Org. Nr.',
							key: 'org_number',
						},
						{
							heading: 'Företagsnamn',
							key: 'name',
							fill: true,
						},
						{
							heading: 'Förvaltare',
							component: (seller) => (seller.trustee?.name ? seller.trustee?.name : '-'),
						},
						{
							heading: '',
							className: 'row-icons',
							component: (row) => {
								return (
									<div>
										<Icon onClick={() => history.push(`/sellers/${row.id}`)}>edit</Icon>
										<Icon onClick={() => open(<DeleteSeller seller={row} onDeleted={() => load()} />)}>delete</Icon>
									</div>
								);
							},
						},
					]}
				/>
			</Card>
		</Style>
	);
}

const Style = styled(Page)`
	.key-values {
		h1 {
			font-size: 2.5em;
		}
	}

	.header {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		form {
			display: flex;
			align-items: center;
		}
	}

	.row-icons {
		div {
			display: flex;
			justify-content: flex-end;
			font-size: 1.5em;

			.icon {
				margin-left: 0.5em;
			}
		}
	}
`;
