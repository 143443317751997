import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

export default function Popup({ close, children, width, maxWidth }) {
	const card = useRef();

	useEffect(() => {

		// Listen for escape key to close the popup
		const keyListener = (e) => {
			if (e.key === 'Escape' && close) close();
		};

		document.addEventListener('keydown', keyListener);

		return () => {
			document.removeEventListener('keydown', keyListener);
		};
	}, [])

	return (
		<Style className={`popup`}>
			<div className="card" ref={card} style={{ maxWidth, width }}>
				{children}
			</div>
		</Style>
	);
}

const Style = styled.section`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.2);
	z-index: 100000;

	& > .card {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-height: calc(100dvh - 140px);
		overflow-y: auto;
	}

	.buttons {
		margin-top: 1em;
		display: flex;
		justify-content: flex-end;

		button {
			margin: 0 0.5em;
		}
	}

	.mobile & {
		& > .card {
			max-width: 90vw !important;
			padding: 1rem;
		}
	}
`;
