import React, { useEffect, useState } from 'react';
import Table from '../components/Table';
import Page from '../layout/Page';
import Card from '../layout/Card';
import Users from '../utils/api/Users';
import Icon from '../elements/Icon';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import usePopup from '../utils/hooks/usePopup';
import ChangePassword from '../layout/popups/user/ChangePassword';
import DeleteUser from '../layout/popups/user/DeleteUser';
import PNTrading from 'src/pn-trading-sdk';
import Alert from '../layout/popups/Alert';

export default function PageUsers() {
	const limit = 10;

	const history = useHistory();

	const [searchString, setSearchString] = useState();
	const [users, setUsers] = useState();
	const [open] = usePopup();

	const isLoading = users == undefined;

	const load = async ({ sorting, filters, page } = {}) => {
		let users = await Users.list({ limit, page: page ?? 0, search: searchString });
		setUsers(users);

		return users.data;
	};

	const search = (e) => {
		e.preventDefault();
		load();
	};

	const exportNewUsers = async () => {
		let users = await PNTrading.get(`/users/export-new`);
		navigator.clipboard.writeText(users.join(`\n`));
		if (users.length != 0) open(<Alert title="Kopierat användare" text={`${users.length} användare som registrerat sig inom 7 dagar är kopierade till urklipp.`} />);

		if (users.length == 0) open(<Alert title="Inga nya användare" text={`Inga nya användare registrerade inom 7 dagar.`} />);
	};

	useEffect(() => load(), []);

	if (isLoading)
		return (
			<div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
		);

	return (
		<Style>
			<Card size="full">
				<div className="header">
					<h1>Användare</h1>
					<div className="spacer auto"></div>

					<form onSubmit={search}>
						<input type="text" placeholder="Sök användare" onChange={(e) => setSearchString(e.target.value == '' ? undefined : e.target.value)} />

						<div className="spacer ws"></div>

						<button type="submit">Sök</button>
					</form>

					<div className="spacer wm"></div>

					<button className="secondary" onClick={exportNewUsers}>
						Exportera nya användare
					</button>
				</div>

				<div className="spacer m"></div>

				<Table
					pages={users ? users.pages : 1}
					limit={limit}
					tableID={'main_users_table'}
					// The table requests new data when the user interacts with it.
					load={load}
					data={users?.data ?? []}
					structure={[
						{
							heading: 'ID',
							key: 'id',
							// sortable: true,
						},
						{
							heading: 'Användarnamn',
							key: 'username',
							fill: true,
						},
						{
							heading: 'Namn',
							component: (user) => `${user.first_name} ${user.last_name}`,
						},
						{
							heading: '',
							className: 'row-icons',
							component: (row) => {
								return (
									<div>
										<Icon onClick={() => history.push(`/users/${row.id}`)}>edit</Icon>
										<Icon onClick={() => open(<ChangePassword user={row} />)}>password</Icon>
										<Icon onClick={() => open(<DeleteUser user={row} />)}>delete</Icon>
									</div>
								);
							},
						},
					]}
				/>
			</Card>
		</Style>
	);
}

const Style = styled(Page)`
	.key-values {
		h1 {
			font-size: 2.5em;
		}
	}

	.row-icons {
		div {
			display: flex;
			justify-content: flex-end;
			font-size: 1.5em;

			.icon {
				margin-left: 0.5em;
			}
		}
	}
`;
