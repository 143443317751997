import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import API from '../../utils/api/API';
import Button from '../../elements/Button';
import Popup from '../Popup';
import { useClosePopup } from '../../utils/hooks/usePopup';
import Objects from '../../utils/api/Objects';
import Auctions from '../../utils/api/Auctions';

export default function Alert({ title, text, onConfirm = () => {}, confirmText = 'Okej', cancelText = undefined }) {
	let history = useHistory();

	const close = useClosePopup();

	const confirm = () => {
		onConfirm();
		close();
	};

	return (
		<Popup close={close} width="40em" maxWidth="25em">
			<Style>
				<h2 className="title">{title}</h2>
				<div className="spacer s"></div>
				<p dangerouslySetInnerHTML={{__html: text}}/>

				<div className="buttons">
					{cancelText && (
						<Button type="secondary" onClick={close}>
							{cancelText}
						</Button>
					)}
					<Button onClick={confirm}>{confirmText}</Button>
				</div>
			</Style>
		</Popup>
	);
}

const Style = styled.div``;
