import React from 'react';
import styled from 'styled-components';

export default function EditableField({ className, type = 'text', edit, data, temp, setTemp, field, placeholder, rows = 4 }) {
	const onChange = (e) => {
		let t = { ...temp };
		t[field] = e.target.value;
		if (setTemp) setTemp(t);
	};

	return (
		<Style className={`editable-field ${className} ${edit ? 'editable' : ''}`}>
			{type == 'textarea' ? <textarea rows={rows} disabled={!edit} onChange={onChange} type={type} placeholder={placeholder} defaultValue={data && data[field]}></textarea> : <input disabled={!edit} onChange={onChange} type={type} placeholder={placeholder} defaultValue={data && data[field]} />}
		</Style>
	);
}

const Style = styled.div`
	input,
	textarea {
		width: 100%;
		padding: 0;
		background: none;
		border: none;
		outline: none;
		transition: border 300ms;
		border-bottom: solid transparent 0.1rem;
		resize: none;

		&:disabled {
			color: inherit;
		}
	}

	&.editable {
		input,
		textarea {
			border-color: #e8e8e8;

			&:focus {
				border-color: #99b1ff;
			}
		}
	}
`;
