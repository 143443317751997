import React from 'react';
import styled from 'styled-components';

export default function Button({ children, className = "", onClick, type = 'primary', ...rest }) {
	return (
		<Style onClick={onClick} className={`${className} button type-${type}`} {...rest}>
			{children}
		</Style>
	);
}

const Style = styled.button``;
