import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import API from '../../utils/api/API';
import Button from '../../elements/Button';
import Popup from '../Popup';
import { useClosePopup } from '../../utils/hooks/usePopup';
import Objects from '../../utils/api/Objects';

export default function DeleteObject({ id }) {
	const [object, setObject] = useState();
	let history = useHistory();

	const close = useClosePopup();

	const deleteOrder = async () => {
		await Objects.delete(id);
		API.clearCache(`/objects`);
		close();
		history.push(`/objects`);
	};

	useEffect(() => {
		(async () => {
			setObject(await Objects.get(id));
		})();
	}, [id]);

	return (
		<Popup close={close} width="40em" maxWidth="20em">
			<Style>
				<h2 className="title">
					Ta bort objekt <strong>{id}</strong>?
				</h2>
				<div className="spacer s"></div>
				<p>
					Vill du verkligen ta bort <strong>"{object && object.title}"</strong>. Åtgärden är permanent och kan ej ångras!
				</p>

				<div className="buttons">
					<Button onClick={close}>Nej</Button>
					<Button onClick={deleteOrder}>Ja</Button>
				</div>
			</Style>
		</Popup>
	);
}

const Style = styled.div``;
