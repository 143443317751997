import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useClosePopup } from '../../../utils/hooks/usePopup';
import Popup from '../../Popup';
import Button from '../../../elements/Button';
import sha256 from 'sha256';
import API from '../../../utils/api/API';
import Users from '../../../utils/api/Users';
import { formatPrice } from '../../../utils/Util';
import PNTrading from 'src/pn-trading-sdk';

export default function DeleteBid({ bid, engine }) {
	const close = useClosePopup();

	const confirm = async () => {
		await PNTrading.request('/v2/bids/admin/', {
			bid_id: bid.id,
		}, {
			method: 'DELETE',
		})
		close();
	};

	console.log(bid)
	return (
		<Popup close={close} width="40em" maxWidth="25em">
			<Style>
				<h2 className="title">Ta bort bud</h2>
				<div className="spacer s"></div>

				<p>
					Bud: <b>{formatPrice(bid.amount)}</b>
				</p>
				<p>
					Användare: <b>{bid.username}</b>
				</p>

				<div className="spacer s"></div>
				<p>Detta bud samt eventuellt tillhörande maxbud av användaren kommer att tas bort.</p>
				<br />
				<p className="bold">Åtgärden är permanent och kan INTE ångras, är du säker?</p>

				<div className="spacer xs"></div>

				<div className="buttons">
					<Button onClick={close}>Avbryt</Button>
					<Button type="delete" onClick={confirm}>
						Ja, ta bort
					</Button>
				</div>
			</Style>
		</Popup>
	);
}

const Style = styled.div``;
