import React from 'react'
import usePopup from '../../../utils/hooks/usePopup'
import Alert from '../../../layout/popups/Alert'
import PNTrading from 'src/pn-trading-sdk'

const SendWinningMailToAllButton = ({ id }) => {

    const [open] = usePopup()

    const onClick = () => {
        open(<Alert 
            title={'Detta kommer skicka ut mejl till ALLA användare'}
            text={'Mejlet kommer skickas ut till alla användare som vunnit auktionen, oavsett om de tidigare fått mejlet eller inte. Använd bara detta om det automatiska utskicket inte har fungerat!'}
            cancelText={'Avbryt'}
            confirmText={'Skicka mejl'}
            onConfirm={async () => {

                try {
                    await PNTrading.post(`/auctions/${id}/send-winning-mail`)
                    
                    open(<Alert 
                        title={'Mejlet har skickats!'}
                        text={'Mejlet har skickats till alla användare som vunnit auktionen.'}
                    />)
                } catch(e) {
                    open(<Alert 
                        title={'Mejlet kunde inte skickas'}
                        text={'Någonting har blivit fel! Mejlet kunde inte skickas, kolla konsollen för mer information.'}
                    />)
                }
            }}
        />)
    }

    return (
        <button onClick={onClick} className='secondary'>Skicka vinnarmejl till alla</button>
    )
}


export { SendWinningMailToAllButton }