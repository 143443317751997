import React from "react";
import Page from "src/layout/Page";
import styled from "styled-components";
import BillableCustomersCard from "src/pages/customerInvoices/components/BillableCustomersCard";
import CustomerInvoicesCard from "src/pages/customerInvoices/components/CustomerInvoicesCard";

export default function PageCustomerInvoices() {
    
    return (
        <Style>
            <BillableCustomersCard />
            <CustomerInvoicesCard />
        </Style>
    )
}

const Style = styled(Page)`
`;