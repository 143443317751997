import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import Card from '../../layout/Card';
import { DashboardContext } from '../Dashboard';
import { formatPrice } from '../../utils/Util';
import { Objects } from 'src/pn-trading-sdk/Objects';

export default function LatestBids() {

	const { latestBids } = useContext(DashboardContext);
	const [objects, setObjects] = useState()

	useEffect(() => {
		async function load() {
			if (latestBids && latestBids.length > 0) {
				const bids = latestBids.filter((_, i) => i < 20)
				setObjects((await Objects.list({ limit: 10000 }, `&whitelist=${(bids ? [...new Set(bids.map(bid => bid.object))] : []).join(',')}`)).data);
			} else {
				setObjects([]);
			}
		}

		load();
	}, [ latestBids ]);

	if (!objects || objects.length === 0) return <></>;

	return (
		<Style className={'latest-bids'} size="large">
			<table className="bids" cellSpacing={0}>
				<thead>
					<tr>
						<th>Objekt</th>
						<th></th>
						<th>Budgivare</th>
						<th>Tid</th>
						<th>Summa</th>
					</tr>
				</thead>

				<tbody>
					{latestBids
						?.filter((_, i) => i < 20)
						.map((bid) => {
							return (
								<tr key={JSON.stringify(bid, null, 4)} className="bid" title={JSON.stringify(bid, null, 4)}>
									<td className="id">{objects?.find((o) => o.id == bid.object)?.id}</td>
									<td className="title">
										<div>{objects?.find((o) => o.id == bid.object)?.title}</div>
									</td>

									<td className="username">
										<span>{bid.username}</span>
										{bid.auto != false && <span className="auto">Höjning genom autobud</span>}
									</td>
									<td>{new Date(bid.time_placed + 60 * 60 * 1000).toJSON().replace('T', ' ').substr(0, 19)}</td>

									<td>{formatPrice(bid.amount)}</td>
								</tr>
							)
						})}
				</tbody>
			</table>
		</Style>
	);
}

const Style = styled(Card)`
	padding: 4rem;

	.bids {
		width: 100%;

		th {
			text-align: left;
			padding-bottom: 1rem;
		}

		.bid {
			.title {
				padding-right: 2rem;

				div {
					width: 20rem;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			.username {
				height: 3.5rem;
				display: flex;
				flex-direction: column;
				justify-content: center;

				.auto {
					margin-top: 0.1rem;
					font-size: 0.75rem;
					color: #aaa;
				}
			}

			&:not(:last-child) td {
				border-bottom: solid 1px #eee;
			}
		}
	}
`;
