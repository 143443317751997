import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Card from '../../layout/Card';
import PNTrading from 'src/pn-trading-sdk';

export default function CurrentVisitorsCard() {
	const [visitors, setVisitors] = useState([]);

	const users = visitors.filter((v) => v.user != null);

	useEffect(() => {
		const fetch = () => {
			PNTrading.get(`/admin/stats/visitors`).then(setVisitors);
		};

		fetch();
		let interval = setInterval(fetch, 10 * 1000);
		return () => clearInterval(interval);
	}, []);

	return (
		<Style className={'key-values'} size="medium">
			<div className="key-value">
				<label>Antal besökare</label>
				<div className="value">{visitors.length} st</div>
			</div>

			<div className="users">
				<label>Inloggade medlemmar ({users.length} st)</label>
				<div className="table-wrapper">
					<table>
						<tbody>
							{users
								.map((visitor) => (
									<tr key={visitor.id}>
										<td>{visitor.username}{visitor.platform === 'app' ? ' (app)' : ''}</td>
									</tr>
								))}
						</tbody>
					</table>
				</div>
			</div>
		</Style>
	);
}

const Style = styled(Card)`

	.users {
		.table-wrapper {
			max-height: 200px;
			overflow-y: auto;
		}
	}

`;
