import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useClosePopup } from '../../../utils/hooks/usePopup';
import Popup from '../../Popup';
import Button from '../../../elements/Button';
import sha256 from 'sha256';
import API from '../../../utils/api/API';

export default function BlockUser({ onBlock = () => {}, uid }) {
	const close = useClosePopup();

	const [reason, setReason] = useState();

	const confirm = async () => {
		await API.post(`/users/${uid}/block`, {
			reason: reason,
		});

		onBlock(reason);
		close();
	};

	return (
		<Popup close={close} width="40em" maxWidth="25em">
			<Style>
				<h2 className="title">Spärra användare</h2>
				<div className="spacer s"></div>
				<p>Användaren kommer inte ha tillgång till budgivning eller mina sidor tills spärren hävs, fyll i en anledning nedan som kommer visas för användaren:</p>

				<div className="spacer xs"></div>
				<textarea rows="10" onChange={(e) => setReason(e.target.value)}></textarea>

				<div className="buttons">
					<Button type="secondary" onClick={close}>
						Avbryt
					</Button>
					<Button onClick={confirm}>Spärra</Button>
				</div>
			</Style>
		</Popup>
	);
}

const Style = styled.div`
	textarea {
		width: 100%;
		resize: none;
	}
`;
