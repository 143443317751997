import { useCallback, useEffect, useState } from "react";
import API from "src/utils/api/API";

export type Activity = {
    id: number,
    name: string,
}

export async function getActivities(): Promise<Activity[]> {
    const resp = await API.get('/v2/employees/event-activities');
    if (resp.success !== true) return [];
    return resp.activities;
}

export function useActivities() {
    const [activities, setActivities] = useState<Activity[]>([]);
    const [loading, setLoading] = useState(true);

    const load = useCallback(async () => {
        setLoading(true);
        let activities = await getActivities();
        setLoading(false);
        setActivities(activities);
    }, []);

    useEffect(() => {
        load();
    }, [load])

    return {
        activities,
        loading,
    };
}