import { useEffect, useState } from "react";
import { CustomerInvoice, getCustomerInvoice } from "src/utils/api/customer-invoices/CustomerInvoices";
import { updateCustomerInvoice } from "src/utils/api/customer-invoices/CustomerInvoices";

export default function useCustomerInvoice(id: number) {

    // const [originalInvoice, setOriginalInvoice] = useState<CustomerInvoice | null>(null);
    const [customerInvoice, setCustomerInvoice] = useState<CustomerInvoice | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const invoice = await getCustomerInvoice(id);
            setCustomerInvoice(invoice);
            // setOriginalInvoice(invoice);
            setLoading(false);
        })();
    }, [id]);

    return {
        customerInvoice,
        setCustomerInvoice,
        loading,
        save: async (invoice: CustomerInvoice | undefined = undefined) => {
            if (!customerInvoice?.id) return;
            const resp = await updateCustomerInvoice(customerInvoice.id, invoice ?? customerInvoice);
            setCustomerInvoice(resp);
        }

    }
}