import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Card from '../../layout/Card';
import { useUser } from 'src/pn-trading-sdk/utils/UserToken';
import Icon from 'src/elements/Icon';

export default function WelcomeCard() {

	const user = useUser();

	return (
		<Style size="medium">
			<h1>Välkommen<span>{user ? (' ' + user.first_name) : ''}</span>!</h1>
			<div className="spacer s"></div>
			<Link to="/me/time" className="button"><Icon>schedule</Icon>Tidsrapportering</Link>
		</Style>
	);
}

const Style = styled(Card)`
	background-color: transparent;
	box-shadow: none;
	padding: 0 !important;
	display: flex;
	flex-direction: column;

	h1 {
		font-size: 2rem;
	}

	.button {
		align-self: flex-start;
	}
`;
