import React from "react";
import Table from "src/components/Table";
import Card from "src/layout/Card";
import Page from "src/layout/Page";
import styled from "styled-components";
import AccountingSubmenu from "src/pages/accounting/components/AccountingSubmenu";
import useAccountingEntries from "src/utils/api/accounting/hooks/useAccountingEntries";
import Accounting, { AccountingEntry } from "src/utils/api/accounting/Accounting";
import Icon from "src/elements/Icon";
import { Link } from "react-router-dom";

export default function AccountingEntriesPage() {

	const { entries, loading } = useAccountingEntries({
		limit: -1
	});

    if (loading) {
        return (
			<div style={{ paddingTop: 100, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
        )
    }

	function handlePrint(id: number) {
		return async () => {
			const url = await Accounting.getAccountingEntryInternalSummary(id);
			window.open(url, '_blank');
		}
	}
    
    return (
        <Style>
			<AccountingSubmenu />
            <Card size="full">
				<div className="header">
					<h1>Redovisningshistorik</h1>
					<div className="spacer auto"></div>
				</div>

				<div className="spacer m"></div>

                <Table
					data={entries}
					structure={[
						{
							heading: 'ID',
							key: 'id',
						},
						{
							heading: 'Auktion',
							component: (entry: AccountingEntry) => (
								<>
									{entry.auction ? (
										<Link to={`/auctions/${entry.auction.id}`}>{entry.auction_id} - {entry.auction.name}</Link>
									) : (
										<>-</>
									)}
								</>
							),
						},
						{
							heading: 'Redovisad',
							key: 'created_at',
							component: (entry: any) => new Date(entry.created_at).toLocaleDateString('sv-SE', {
								year: 'numeric',
								month: 'numeric',
								day: 'numeric',
								hour: 'numeric',
								minute: 'numeric',
							}),
						},
						{
							heading: 'Redovisad av',
							component: (entry: AccountingEntry) => (
								<>
									{entry.user ? (
										<Link to={`/employees/${entry.user.id}`}>{entry.user.username}</Link>
									) : (
										<>-</>
									)}
								</>
							),
						},
						{
							heading: ' ',
							key: 'id',
							component: (row: AccountingEntry) => {
								return (
									<div className="row-icons">
										<div>
											<Icon onClick={handlePrint(row.id)}>print</Icon>
										</div>
									</div>
								)
							}
						}
					]}
				/>

            </Card>
        </Style>
    )
}

const Style = styled(Page)`
	.key-values {
		h1 {
			font-size: 2.5em;
		}
	}

	.filters {
		border-radius: 0.5rem;
		display: flex;
		flex-wrap: wrap;

		.checkbox-container {
			font-size: 1em;
		}
	}

	.header {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		form {
			display: flex;
			align-items: center;
		}
	}

	.row-icons {
		div {
			display: flex;
			justify-content: flex-end;
			font-size: 1.5em;

			.icon {
				margin-left: 0.5em;
			}
		}
	}
`;