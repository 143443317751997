import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import API from '../../../utils/api/API';
import Button from '../../../elements/Button';
import Popup from '../../Popup';
import { useClosePopup } from '../../../utils/hooks/usePopup';
import Objects from '../../../utils/api/Objects';
import Images from '../../../utils/api/Images';
import Auctions from '../../../utils/api/Auctions';
import ImageSelector from '../../../elements/ImageSelector';
import { Context } from '../../../App';
import Customers from '../../../utils/api/Customers';
import CustomerSelect from '../../../components/CustomerSelect';
import PNTrading from 'src/pn-trading-sdk';
import TrusteeSelect from '../../../components/customers/TrusteeSelect';

export default function CreateSeller({ customer, trustee }) {
	let history = useHistory();
	const close = useClosePopup();

	const [error, setError] = useState({ message: '', visible: false });
	const [selectedCustomer, setSelectedCustomer] = useState(customer);
	const [selectedTrustee, setSelectedTrustee] = useState(trustee);

	const submit = async (e) => {
		e.preventDefault();

		if (selectedTrustee == undefined) {
			setError({
				message: `Du måste välja en förvaltare innan du kan gå vidare!`,
				field: 'trustee',
				visible: true,
			});
			return;
		}

		let data = Object.fromEntries(new FormData(e.target).entries());

		data.trustee = selectedTrustee;

		let result = await PNTrading.post(`/sellers`, data);

		if (result) {
			let cache = API.getCached(`/sellers_`);
			if (cache) {
				cache.result = [...cache.result, result];
				API.updateCache(`/sellers_`, cache);
			}
			close();
			window.location.reload();
		}
	};

	return (
		<Popup close={close} width="40em" maxWidth="80em">
			<Style onSubmit={submit}>
				<h2 className="title">Lägg till projekt</h2>
				<div className="spacer m"></div>

				<div className="fields">
					<label>Uppdragsgivare *</label>
					<br />
					<CustomerSelect defaultValueID={customer} onChange={({ value }) => setSelectedCustomer(value)} />

					{selectedCustomer && (
						<>
							<div className="spacer s"></div>
							<label>Förvaltare *</label>
							<br />
							<TrusteeSelect customer={selectedCustomer} defaultValueID={trustee} onChange={({ value }) => setSelectedTrustee(value)} />
						</>
					)}

					<div className="spacer l"></div>

					<label htmlFor="name">Namn *</label>
					<br />
					<input disabled={selectedTrustee == undefined} required type="text" name="name" />
					<div className="spacer s"></div>

					<label htmlFor="org_number">Org. Nummer *</label>
					<br />
					<input disabled={selectedTrustee == undefined} required type="text" name="org_number" />
					<div className="spacer s"></div>

					<label htmlFor="vat_number">Momsnummer</label>
					<br />
					<input disabled={selectedTrustee == undefined} type="text" name="vat_number" />
					<div className="spacer s"></div>

					<label htmlFor="bank_account_number">Kontonummer</label>
					<br />
					<input disabled={selectedTrustee == undefined} type="text" name="bank_account_number" />
					<div className="spacer s"></div>

					<label htmlFor="notes">Anteckningar</label>
					<br />
					<textarea disabled={selectedTrustee == undefined} type="text" name="notes" rows={6}></textarea>
					<div className="spacer s"></div>

					{error?.visible && (
						<>
							<div className="spacer xs"></div>
							<p className="label error">{error?.message}</p>
						</>
					)}
				</div>

				<div className="spacer s"></div>

				<div className="buttons">
					<Button type={'secondary'} onClick={close}>
						Avbryt
					</Button>
					<input className="button" type="submit" value="Spara" />
				</div>
			</Style>
		</Popup>
	);
}

const Style = styled.form`
	.fields {
		width: 100%;

		input,
		textarea {
			width: 100%;
			resize: none;
		}

		.error {
			color: rgb(255, 161, 161);
		}
	}
`;
