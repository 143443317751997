import API from './API';

const auctions = {time: null, data: null};

export default class Auctions {
	static async list() {
		if (auctions && auctions.time && Date.now() - auctions.time < 1000 * 5)
			return auctions.data;

		let _auctions = await API.get(`/auctions?noCache=true`);
		auctions.time = Date.now();
		auctions.data = _auctions;

		return _auctions;
	}

	static async get(id) {
		let auction = await API.get(`/auctions/${id}?noCache=true`);
		return auction;
	}

	static async objects(id, params = {}) {
		return await API.get(
			`/auctions/${id}/objects?${Object.keys(params)
				.map((key) => `${key}=${params[key]}`)
				.join('&')}`
		);
	}

	static async delete(id) {
		return await API.delete(`/auctions/${id}`);
	}

	static async update(id, data) {
		let cachedAuction = API.getCached(`/auctions/${id}`);
		if (cachedAuction) {
			cachedAuction.result = { ...cachedAuction.result, ...data };
			API.updateCache(`/auctions/${id}`, cachedAuction);
		}

		API.clearCache(`/auctions_`);
		return await API.put(`/auctions/${id}`, data, true);
	}

	static async create(data) {
		return await API.post(`/auctions`, data);
	}
}
