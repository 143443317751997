import React, { useContext } from 'react'
import usePopup, { useClosePopup } from '../../../utils/hooks/usePopup'
import Alert from '../../../layout/popups/Alert'
import PNTrading from 'src/pn-trading-sdk'
import Icon from '../../../elements/Icon'
import Popup from '../../../layout/Popup'
import Button from '../../../elements/Button'

const SendReminderMailToUserButton = ({ user, username }) => {

    const [open] = usePopup()

    const onClick = () => {
        open(<ConfirmSendPopup 
            username={username}
            cancelText={'Avbryt'}
            confirmText={'Skicka mejl'}
            onConfirm={async (body) => {

                try {
                    await PNTrading.post(`/v2/notifications/email/${user}`, {
                        body: body
                    })
                    
                    open(<Alert 
                        title={'Mejlet har skickats!'}
                        text={'Mejlet har skickats till användaren.'}
                    />)
                } catch(e) {
                    open(<Alert 
                        title={'Mejlet kunde inte skickas'}
                        text={'Någonting har blivit fel! Mejlet kunde inte skickas, kolla konsollen för mer information.'}
                    />)
                }
            }}
        />)
    }

    return (
        <Icon onClick={onClick}>schedule_send</Icon>
    )
}


export default function ConfirmSendPopup({ username, onConfirm = () => {}, confirmText = 'Okej', cancelText = undefined }) {

	const close = useClosePopup();

    const [value, setValue] = React.useState(`Hej, ${username}!
Vi vill bara påminna dig om att du har vunnit en auktion på PN Trading som inte är uthämtad än.
Vänligen kontakta oss så snart som möjligt för att få hjälp med att hämta ut dina objekt.

Med vänliga hälsningar,
Riksauktioner`)

	const confirm = () => {
		onConfirm(value);
		close();
	};

	return (
		<Popup close={close} width="40em" maxWidth="35em">
			<div style={{
                display: 'flex',
                flexDirection: 'column',
            }}>
				<h2 className="title">Skicka påminnelse till {username}</h2>
				<div className="spacer s"></div>
				<p>Detta kommer att skicka ett mejl till användaren.</p>
                <div className="spacer s"></div>
                <textarea 
                    style={{ resize: 'none' }} 
                    rows="10" 
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                />

				<div className="buttons">
					{cancelText && (
						<Button type="secondary" onClick={close}>
							{cancelText}
						</Button>
					)}
					<Button onClick={confirm}>{confirmText}</Button>
				</div>
			</div>
		</Popup>
	);
}

export { SendReminderMailToUserButton }