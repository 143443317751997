import React, { useEffect, useState } from "react";
import Card from "src/layout/Card";
import Users, { User } from "src/utils/api/Users";
import useUserMeta from "src/utils/hooks/useUserMeta";
import styled from "styled-components";

export default function UserAddressCard(props: Props) {
    const uid = props.userID;

	const [user, setUser] = useState<User | null>(null);
	const [meta, setMeta] = useUserMeta(uid) as any;

    useEffect(() => {
        if (uid) Users.get(uid).then(u => {
            setUser(u);
        });
    }, [uid]);

    const onSubmit = async (e: any) => {
		e.preventDefault();

		let meta = {};

		let formData = new FormData(e.target);
		formData.forEach((value: any, key: any) => {
			if (key.startsWith('_meta_')) {
                // @ts-ignore
				meta[key.replace('_meta_', '')] = value;
			}
		});

		// Update meta.
		if (Object.keys(meta).length) {
			setMeta(meta, (responses: any) => {
				console.log(responses);
				alert('Användarens metadata har uppdaterats!');
			});
		}
	};

    if (!user || !meta) return null;

    return (
        <Style size="medium" className="user-details">
            <form onSubmit={onSubmit}>
                <div className="input-group">
                    <label>Adress:</label>
                    <input type="text" name="_meta_address" defaultValue={meta.address} />
                </div>

                <div className="row">
                    <div className="input-group grow">
                        <label>Stad:</label>
                        <input type="text" name="_meta_city" defaultValue={meta.city} />
                    </div>

                    <div className="spacer ws"></div>

                    <div className="input-group">
                        <label>Postkod:</label>
                        <input type="text" name="_meta_zip" defaultValue={meta.zip} />
                    </div>
                </div>

                <div className="spacer s"></div>

                <button type="submit">Spara</button>
            </form>
        </Style>
    );
}

type Props = {
    userID: string | null;
};

const Style = styled(Card)`

    .input-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.5rem;

        &.grow {
            flex-grow: 1;
        }
    }

`