import { useEffect, useState } from "react";
import { useSocket } from "../../App";


export default function useBids(object) {

    const [bids, setBids] = useState(object?.bids || [])

    const ws = useSocket()

    useEffect(() => {

        if (object == undefined) return;

        setBids(object?.bids || [])

        const unsub = ws.listen('bids', (data) => {
            if (data.object != object.id) return;
            setBids(data.bids)
            object.bids = data.bids;
        })

        return () => unsub();
    }, [object])

    return bids;
}