import React, { useContext } from 'react';
import styled from 'styled-components';
import Card from '../../layout/Card';
import { DashboardContext } from '../Dashboard';
import { formatPrice } from '../../utils/Util';

export default function KeyValues() {
	const { latestBids } = useContext(DashboardContext);

	return (
		<Style className={'key-values'} size="large">
			<div className="key-value">
				<label>Antal bud senaste 7 dagarna</label>
				<div className="value">{latestBids?.length} st</div>
			</div>

			<div className="key-value">
				<label>Högsta bud senaste 7 dagarna</label>
				<div className="value">{formatPrice(latestBids?.reduce((top, b) => Math.max(top, b.amount), 0))}</div>
			</div>
		</Style>
	);
}

const Style = styled(Card)`
	padding: 4rem;
	display: grid;
	gap: 2rem;
	grid-template-columns: 1fr 1fr;
`;
