/**
 * This class contains random functions.
 */
export default class Util {}

export function searchString(needle, haystack, wrap = (s) => s) {
	let found = [0];
	let find = (offset = 0) => {
		if (needle == undefined) return;
		let pos = haystack.toLowerCase().indexOf(needle.toLowerCase(), offset);
		if (pos != -1 && pos < haystack.length) {
			found.push(pos);
			found.push(pos + needle.length);
			find(pos + 1);
		}
	};
	find();
	found.push(haystack.length);

	let parts = [];
	for (let i = 0; i < found.length - 1; i += 1) {
		let start = found[i];
		let end = found[i + 1];
		if (i % 2 == 1) {
			parts.push(wrap(haystack.substr(start, end - start)));
		} else {
			parts.push(haystack.substr(start, end - start));
		}
	}

	return {
		string: needle == undefined || needle == '' ? haystack : parts.join(''),
		found,
		hasFound: found.length > 2,
	};
}

export function isObjectEnded(object) {
	return new Date(object?.ending).isBefore(new Date()) || object?.status == 'ended';
}

export function numberWithSpaces(x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function formatInvoiceNumber(price) {
	if (isNaN(price)) return '';
	price = Number(price);

	const decimals = price % 1;
	const decimalString = (Math.round(decimals * 1000) / 1000).toFixed(2).replace('0.', ',');

	return `${numberWithSpaces(Math.floor(price))},${decimalString.substring(1)}`;
}

export const formatPrice = (value, shorten = true) => {
	if (isNaN(value)) return '-';

	value = Number(value);

	if (shorten && value > 999999999) value = `${Math.round(value / 100000000) / 10} Mdkr`;
	else if (shorten && value > 999999) value = `${Math.round(value / 100000) / 10} Mkr`;
	else value = `${numberWithSpaces(value)} kr`;

	return value;
};

export const dateToString = (date) => {
	if (!date) return;
	if (!(date instanceof Date && !isNaN(date))) return;
	return `${new Date(date).getDate()}/${new Date(date).getMonth() + 1} ${new Date(date).toISOString().substring(11, 16)}`;
};

export const dateToDatestring = (date) => {
	if (!date) return;
	if (!(date instanceof Date && !isNaN(date))) return;
	return `${new Date(date).getDate()}/${new Date(date).getMonth() + 1}-${new Date(date).getFullYear()}`;
};

export const dateToDayName = (date) => {
	if (!date) return;
	if (!(date instanceof Date && !isNaN(date))) return;
	return ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'][date.getDay()];
};

export const monthName = (month) => {
	return [
		'Januari',
		'Februari',
		'Mars',
		'April',
		'Maj',
		'Juni',
		'Juli',
		'Augusti',
		'September',
		'Oktober',
		'November',
		'December',
	][month];
};

export const dateToReadableDate = (date) => {
	date = new Date(date);

	let dayName = ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'][date.getDay()];

	let dayOfMonth = date.getDate();

	let char = 'e';
	if (`${dayOfMonth}`[`${dayOfMonth}`.length - 1] == 1 || `${dayOfMonth}`[`${dayOfMonth}`.length - 1] == 2) char = 'a';

	let monthName = ['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December'][date.getMonth()];

	return `${dayName} den ${dayOfMonth}:${char} ${monthName} klockan: ${date.getHours()}:${(date.getMinutes() < 10 ? '0' : '') + date.getMinutes()}`;
};

export const minutesToHoursAndMinutes = (minutes) => {
	let hours = Math.floor(minutes / 60);
	let mins = minutes % 60;

	return `${hours}h ${mins}m`;
}

export const getLocalDate = (date) => {
	return new Date(date.toString().replace('Z', ''));
};

export const getBiddingFee = (bid) => {
	return Math.min(10000, Math.max(100, bid * 0.1));
};

export const getAuctionMetaValue = (auction, key) => {
	if (!auction) return;
	if (!auction.data) return;
	if (!auction.data.meta || !Array.isArray(auction.data.meta)) return;

	let metaObject = auction.data.meta.find((m) => m.key == key);

	if (!metaObject) return;

	return metaObject.value;
};

export const getPaymentType = (payment) => {
	return {
		null: '-',
		onsite_card: 'På plats - Kort',
		onsite_cash: 'På plats - Kontant',
		onsite_swish: 'På plats - Swish',
		invoice_bank: 'Faktura - Inbetalning Bank',
		invoice_swish: 'Faktura - Swish',
	}[payment.type];
};

export const getPaymentGroup = (payment) => {
	return {
		card: 'Kort',
		cash: 'Kontant',
		swish: 'Swish',
		bank: 'Bank',
	}[payment.type ? payment.type.split('_')[1] : ''];
};

export const getVehicleCategories = () => {
	return [
		27,
		28,
		29,
		30,
		31,
		32,
		33
	];
};

/**
 * Iterates throug a list of objects and calculates the commission per object.
 * This handles max commission amounts for vehicles etc.
 *
 * @param {Array} objects The list of objects.
 */
export const caluclateCommissionAmount = (objects, commissionPercentage) => {
	const VEHICLE_MIN_COMMISSION = 1500;
	const VEHICLE_MAX_COMMISSION = 10000;

	let commission = 0;

	objects?.forEach((object) => {
		if (object?.customAmount == undefined && object?.ending_data?.lastBid == undefined) return;

		let amount = Number(object?.customAmount ?? object?.ending_data?.lastBid?.amount);

		let vehicle = getVehicleCategories().find((c) => c == object.category) != undefined;

		if (vehicle) {
			commission += Math.max(VEHICLE_MIN_COMMISSION, Math.min(amount * Number(commissionPercentage), VEHICLE_MAX_COMMISSION));
		} else {
			commission += amount * Number(commissionPercentage);
		}
	});

	return commission;
};

/**
 * Iterates throug a list of objects and calculates the commission per object.
 * This handles max commission amounts for vehicles etc.
 *
 * @param {Array} objects The list of objects.
 */
export const calculateServiceFee = (objects) => {
	let fee = 0;

	objects?.forEach((object) => {
		if (object?.ending_data?.lastBid == undefined) return;

		fee += getBiddingFee(Number(object.ending_data.lastBid.amount));
	});

	return fee;
};

/**
 * 
 * @param {Array} invoiceItems The list of invoice items.
 */
export const calculateServiceFeeFromInvoiceItems = (items) => {
	let fee = 0;

	items?.forEach((item) => {
		fee += getBiddingFee(Number(item.net_amount));
	});

	return fee;
};