import API from './API';

export default class Objects {
	static async list(page = 0, limit = 25, params = {}, noCahce = false) {
		let res = await API.get(
			`/objects?page=${page}&limit=${limit}&${Object.keys(params)
				.map((key) => `${key}=${params[key]}`)
				.join('&')}`,
			noCahce
		);

		return res;
	}

	static async create(data) {
		return await API.post(`/objects`, data);
	}

	static async get(id) {
		let object = await API.get(`/objects/${id}`);
		object['gallery'] = JSON.parse(object['gallery']);
		return object;
	}

	static async delete(id) {
		return await API.delete(`/objects/${id}`);
	}

	static async update(id, data) {
		let cachedObject = API.getCached(`/objects/${id}`);
		if (cachedObject) {
			cachedObject.result = { ...cachedObject.result, ...data };
			API.updateCache(`/objects/${id}`, cachedObject);
		}

		API.clearCache(`_/objects_`);
		return await API.put(`/objects/${id}`, data, true);
	}
}
