import { useCallback, useEffect, useState } from "react";
import API from "src/utils/api/API";
import { Seller } from "src/utils/api/Sellers";
import Employees, { Employee } from "src/utils/api/employees/Employees";
import { Expense } from "src/utils/api/expenses/Expenses";

export enum EventType {
    NORMAL = 'normal',
    PROJECT = 'project',
    PARENTAL_LEAVE = 'parental_leave',
    SICK_LEAVE = 'sick_leave',
    VACATION = 'vacation',
    VAB = 'vab',
    UNPAID_LEAVE = 'unpaid_leave',
    OTHER = 'other',
}

export enum EventStatus {
    PENDING = 'pending',
    APPROVED = 'approved',
    DECLINED = 'declined',
}

export type Activity = {
    id: number;
    name: string;
}

export type Event = {
    id: string,
    name: null | string,
    type: EventType,
    status: EventStatus,
    description: string,
    privateDescription: string,
    start: Date,
    end: Date,
    billableTime: number,
    breakTime: number,
    sellerID: null | number,
    activityID: null | number,
    seller: null | Seller,
    activity: null | Activity,
    travelInStockholm: boolean,
    traveledDistance: number,
    expenses: Expense[],
}

export type AddEvent = Partial<Omit<Event, "id">>;


export function addEvent(employeeId: number, event: AddEvent): Promise<{
    success: boolean,
    employee: Employee,
    event: Event,
}> {
    return API.post(`/v2/employees/${employeeId}/events`, event);
}

export function removeEvent(employeeId: number, eventId: string): Promise<{
    success: boolean,
    employee: Employee,
}> {
    return API.delete(`/v2/employees/${employeeId}/events/${eventId}`);
}

export function updateEvent(employeeId: number, eventId: string, event: AddEvent): Promise<{
    success: boolean,
    event: Event,
    error?: string,
}> {
    return API.put(`/v2/employees/${employeeId}/events/${eventId}`, event);
}

export function useEvents(id: number, args?: {
    start?: Date,
    end?: Date,
    condition?: boolean,
}) {
    const [events, setEvents] = useState<Event[]>([]);
    const [loading, setLoading] = useState(true);

    const start = args?.start;
    const end = args?.end;
    const condition = args?.condition;

    useEffect(() => {
        if (!condition) return;

        const load = async () => {
            let _args = {} as any;
            if (start) _args['start'] = start;
            if (end) _args['end'] = end;


            setLoading(true);
            let events = await Employees.getEvents(id, _args);
            setLoading(false);
            setEvents(events);
        }
        load();
    }, [id, start, end, condition])

    async function _addEvent(event: AddEvent) {
        const resp = await addEvent(id, event);

        if (resp.event) {
            setEvents([...events, {...resp.event, start: new Date(resp.event.start), end: new Date(resp.event.end)}]);
        }

        return resp;
    }

    async function _removeEvent(eventId: string) {
        const resp = await removeEvent(id, eventId);

        if (resp.success) {
            setEvents(events.filter(e => e.id !== eventId));
        }
    }

    async function _updateEvent(eventId: string, event: AddEvent) {
        const resp = await updateEvent(id, eventId, event);

        if (resp.success) {
            setEvents(events.map(e => e.id === eventId ? ({...resp.event, start: new Date(resp.event.start), end: new Date(resp.event.end)}) : e));
        } else {
            throw new Error('Failed to update event ' + resp.error);
        }
    }

    return {
        events,
        loading,
        addEvent: _addEvent,
        removeEvent: _removeEvent,
        updateEvent: _updateEvent,
        setEvents,
    };
}