import React, { useState } from "react";
import Table from "src/components/Table";
import Card from "src/layout/Card";
import Page from "src/layout/Page";
import { useHistory } from 'react-router-dom';
import styled from "styled-components";
import useAccountableAuctions from "src/utils/api/accounting/hooks/useAccountableAuctions";
import { AccountableAuctionsAuction } from "src/utils/api/accounting/Accounting";
import { formatPrice } from "src/utils/Util";
import Checkbox from "src/elements/Checkbox";
import Icon from "src/elements/Icon";
import AccountingSubmenu from "src/pages/accounting/components/AccountingSubmenu";

export default function PageAccountableAuctions() {

	const history = useHistory();

	const [hasAccountable, setHasAccountable] = useState(true)
	const [searchString, setSearchString] = useState('')

    const { auctions, loading } = useAccountableAuctions({
		hasAccountable,
		limit: -1
	});

    if (loading) {
        return (
			<div style={{ paddingTop: 100, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
        )
    }
    
    return (
        <Style>
			<AccountingSubmenu />
            <Card size="full">
				<div className="header">
					<h1>Redovisningsbara auktioner</h1>
					<div className="spacer auto"></div>
					<div className="search-wrapper">
						<form>
							<input
								type="text"
								placeholder="Sök auktion"
								value={searchString}
								onChange={(e) => setSearchString(e.target.value)}
							/>
						</form>
					</div>
				</div>

				<div className="spacer s"></div>

				<div className="filters">
					<Checkbox
						label="Visa endast redovisningsbara objekt"
						checked={hasAccountable}
						onChange={(e: any) => {
							setHasAccountable(e.target.checked)
							setSearchString('')
						}}
					/>
				</div>

				<div className="spacer m"></div>

                <Table
					data={auctions.filter(auction => {
						if (searchString.length === 0) {
							return true
						}

						return auction.name.toLowerCase().includes(searchString.toLowerCase()) || auction.id.toString().includes(searchString)
					})}
					structure={[
						{
							heading: 'Auktion ID',
							key: 'id',
						},
						{
							heading: 'Projekt',
							key: 'name',
						},
						{
							heading: 'Antal objekt',
							key: 'num_objects',
						},
						{
							heading: 'Vunna objekt',
							key: 'won_items',
						},
						{
							heading: 'Att redovisa',
							key: 'accountable_objects',
						},
						{
							heading: 'Ej hämtade',
							key: 'accounted_objects',
							component: (row: AccountableAuctionsAuction) => {
								return (
									<>
										{row.won_items - row.accountable_objects}
									</>
								)
							}
						},
						{
							heading: 'Totalt (inkl. moms)',
							key: 'total_net_amount',
							component: (row: AccountableAuctionsAuction) => {
								return (
									<>
										{formatPrice(Math.ceil(row.total_net_amount + row.total_vat_amount))}
									</>
								)
							}
						},
						{
							heading: ' ',
							key: 'id',
							component: (row: AccountableAuctionsAuction) => {
								return (
									<div className="row-icons">
										<div>
											<Icon onClick={() => history.push(`/redovisning/auction/${row.id}`)}>book</Icon>
										</div>
									</div>
								)
							}
						}
					]}
				/>

            </Card>
        </Style>
    )
}

const Style = styled(Page)`
	.key-values {
		h1 {
			font-size: 2.5em;
		}
	}

	.filters {
		border-radius: 0.5rem;
		display: flex;
		flex-wrap: wrap;

		.checkbox-container {
			font-size: 1em;
		}
	}

	.header {
		display: flex;
		justify-content: flex-end;
		align-items: center;

		form {
			display: flex;
			align-items: center;
		}
	}

	.row-icons {
		div {
			display: flex;
			justify-content: flex-end;
			font-size: 1.5em;

			.icon {
				margin-left: 0.5em;
			}
		}
	}
`;