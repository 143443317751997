import PNTrading from 'src/pn-trading-sdk';
import React, { useState, useEffect } from 'react';
import Select, { Option } from '../../elements/Select';

export default function TrusteeSelect({ customer, defaultValueID, ...rest }) {
	let defaultValue = rest.defaultValue;

	if (defaultValue == -1) {
		defaultValue = undefined;
	}

	const [trustees, setTrustees] = useState();

	useEffect(async () => {
		setTrustees((await PNTrading.get(`/customers/${customer}/trustees?limit=10000`)).data);
	}, [customer]);

	if (defaultValueID && trustees) {
		defaultValue = trustees.findIndex((c) => c.id == defaultValueID);
	}

	return (
		<Select {...rest} defaultValue={defaultValue}>
			{trustees?.map((trustee) => (
				<Option key={trustee.id} value={trustee.id}>{`${trustee.name}`}</Option>
			))}
		</Select>
	);
}
