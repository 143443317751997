import PNTrading from 'src/pn-trading-sdk';
import React, { useState, useEffect } from 'react';
import Checkbox from '../../elements/Checkbox';
import Table from '../Table';

export default function ObjectsTable({ structure, auction, limit = 10 }) {
	const [objects, setObjects] = useState();

	const loadObjects = async ({ sorting, filters, page }) => {
		let params = { page, limit };

		let objects;
		if (auction) {
			objects = await PNTrading.get(
				`/auctions/${auction}/objects?${Object.keys(params)
					.map((key) => `${key}=${params[key]}`)
					.join('&')}&noCache=true`
			);
		} else {
			objects = await PNTrading.get(
				`/objects?${Object.keys(params)
					.map((key) => `${key}=${params[key]}`)
					.join('&')}&noCache=true`
			);
		}

		setObjects(objects);
		return objects.data;
	};

	return (
		<Table
			pages={objects ? objects.pages : 1}
			limit={limit}
			load={loadObjects}
			data={objects?.data}
			tableID={`objects-table-${auction}`}
			structure={
				structure || [
					{
						heading: 'ID',
						key: 'id',
						// sortable: true,
					},
					{
						heading: 'Modell',
						key: 'title',
						// sortable: true,
						component: (row) => {
							let text = row.title;
							return <>{text.length > 50 ? text.substring(0, 50) + '...' : text}</>;
						},
					},
					{
						heading: 'Status',
						// sortable: true,
						component: (row) =>
							({
								available: 'Tillgänglig',
								ended: 'Avslutad',
							}[row.status]),
					},
				]
			}
		/>
	);
}
