import React, { useContext } from 'react';
import { Context } from '../App';
import DeleteObject from './popups/DeleteObject';

export default function PopupRenderer() {
	const { popups } = useContext(Context);

	if (!popups) return <></>;
	return popups;
}
