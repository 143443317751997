import PNTrading from 'src/pn-trading-sdk';
import React, { useEffect, useState, createContext, useContext } from 'react';
import styled from 'styled-components';
import Icon from '../../elements/Icon';
import Card from '../../layout/Card';
import Page from '../../layout/Page';
import DeleteTrustee from '../../layout/popups/customers/DeleteTrustee';
import usePopup from '../../utils/hooks/usePopup';

const Context = createContext();

export default function PageTrustee(props) {
	let cid = props.match.params.id;
	let id = props.match.params.trustee_id;

	const [open] = usePopup();

	const [trustee, setTrustee] = useState();
	const [originalTrustee, setOriginalTrustee] = useState();
	const [edit, setEdit] = useState(false);

	useEffect(async () => {
		let trustee = await PNTrading.get(`/customers/${cid}/trustees/${id}?noCache=true`);
		setTrustee(trustee);
		setOriginalTrustee(trustee);
	}, [cid]);

	useEffect(async () => {
		if (edit == true) return;
		if (JSON.stringify(trustee) == JSON.stringify(originalTrustee)) return;

		setOriginalTrustee(trustee);
		let resp = await PNTrading.put(`/customers/${cid}/trustees/${id}`, { ...trustee, id: undefined, added: undefined, customer: undefined });
		console.log(resp);
	}, [edit]);

	if (!trustee)
		return (
			<div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
		);

	return (
		<Context.Provider
			value={{
				trustee,
				setTrustee,
				edit,
				setEdit,
			}}
		>
			<TopNav>
				<div className="icon-button" onClick={() => open(<DeleteTrustee customer={cid} trustee={id} />)}>
					<Icon>delete</Icon>
					<span>Ta bort förvaltare</span>
				</div>
			</TopNav>

			<Style>
				<Card size="medium" className={`customer-info ${edit ? 'edit' : ''}`}>
					<Field dataKey="name" size="big" />

					<Field dataKey="address" />
					<Field dataKey="zip" />
					<Field dataKey="city" />
					<Field dataKey="phone" />
					<Field dataKey="email" />

					<div className="spacer m"></div>
					<button onClick={() => setEdit((e) => !e)}>{edit ? 'Spara' : 'Redigera'}</button>
				</Card>
			</Style>
		</Context.Provider>
	);
}

function Field({ dataKey: key, id, size = 'small' }) {
	const { trustee, setTrustee, edit } = useContext(Context);

	return (
		<div className="title-row" key={key}>
			<input id={id ?? `${key}-field`} type="text" className={size} value={trustee[key]} disabled={!edit} onChange={(e) => setTrustee((c) => ({ ...c, [key]: e.target.value }))} />
		</div>
	);
}

const TopNav = styled.div`
	width: 1500px;
	max-width: 100%;
	margin: auto;
	padding-left: 2rem;
	padding-right: 2rem;
	padding-top: 1.5rem;

	display: flex;
	justify-content: flex-end;
	align-items: center;

	.icon-button {
		cursor: pointer;
		display: flex;
		align-items: center;
		margin-left: 3rem;

		.icon {
			font-size: 1.5rem;
			margin-right: 0.5rem;
		}

		span {
			font-size: 1rem;
			color: var(--color-link);
		}
	}
`;

const Style = styled(Page)`
	.customer-info {
		.title-row,
		.name-row {
			display: flex;
			align-items: center;
			margin-bottom: 0.5rem;

			input {
				padding: 0;
				border-radius: 0;
				border: none;
				max-width: calc(100% - 2.25rem);
				border-bottom: solid 1px transparent;

				&:disabled {
					background: none;
					color: black;
				}
			}

			.big {
				font-size: 3rem;
			}

			.small {
				font-size: 1rem;
			}

			.icon {
				font-size: 1.25rem;
				margin-left: 1rem;
			}
		}

		&.edit {
			input {
				border-bottom: solid 1px #d3d3d3;
			}
		}

		.rows {
			border-top: 1px solid #eee;
			margin-top: 1rem;
			padding-top: 1rem;

			.row {
				color: #666;
				font-size: 0.85rem;
				margin-bottom: 0.5rem;

				a {
					color: inherit;
					margin-left: 0.3em;
				}
			}
		}
	}
`;
