const { useEffect, useState } = require("react");
const PNTrading = require(".");

/**
 *
 */
class Objects {

    static async list({page, limit, priceRange, shipping, ending, orderBy, order, auction, category, starred, meta, search, type, title} = {
        page: undefined, 
        limit: undefined, 
        priceRange: undefined,
        shipping: undefined,
        ending: undefined,
        orderBy: undefined,
        order: undefined,
        auction: undefined,
        category: undefined,
        starred: undefined,
        meta: undefined,
        search: undefined,
        type: undefined,
        title: undefined
    }, prependURL = '') {


        let ep = `/${search ? 'search' : 'objects'}?page=${(page != undefined) ? page : 0}&limit=${(limit != undefined) ? limit : 25}`

        if (priceRange) ep += `&priceRange=${priceRange.join(',')}`
        if (shipping) ep += `&shipping=${shipping}`
        if (ending) ep += `&ending=${ending}`
        if (orderBy) ep += `&orderBy=${orderBy}`
        if (order) ep += `&order=${order}`
        if (auction) ep += `&auction=${auction}`
        if (category) ep += `&category=${category}`
        if (starred) ep += `&starred=${starred ? 'true' : 'false'}`
        if (meta) ep += `&meta=${meta.join(',')}`
        if (search) ep += `&q=${search}`
        if (type) ep += `&type=${type}`
        if (title) ep += `&title=${title}`



        return await PNTrading.get(`${ep}&embed=true${prependURL}`);
    }

    static async get(id) {
        return await PNTrading.get(`/objects/${id}?embed=true`);
    }

    static async getCategories() {
        return await PNTrading.get(`/categories`);
    }

    static async getCategory(id) {
        return await PNTrading.get(`/categories/${id}`);
    }
}



function useObjects() {
        
    const [objects, setObjects] = useState([])

    useEffect(() => {
        
        (async () => {
            setObjects(await Objects.list())
        })()

    }, [])

    return objects;
}

function useObject(id) {
    
    const [object, setObject] = useState()

    useEffect(() => {
        
        (async () => {
            setObject(await Objects.get(id))
        })()

    }, [id])

    return object;
}

module.exports = {Objects, useObjects, useObject}