import React, { useContext, useEffect, useState } from 'react';
import Page from '../../layout/Page';
import Card from '../../layout/Card';
import styled from 'styled-components';
import AuctionSelect from '../../components/auction/AuctionSelect';
import { useHistory } from 'react-router-dom';
import PNTrading from 'src/pn-trading-sdk';
import { formatPrice, getAuctionMetaValue } from '../../utils/Util';
import Objects from '../../utils/api/Objects';
import Table from '../../components/Table';
import { sortByWinningUser } from './TickOff';

export default function PageExclamation(props) {
	let id = props.match.params.id;

	const [objects, setObjects] = useState();
	const [auction, setAuction] = useState();

	useEffect(() => {
		PNTrading.get(`/auctions/${id}/objects?limit=10000&embed=true&noCache=true`).then((resp) => setObjects(resp.data));
		PNTrading.get(`/auctions/${id}?embed=true&noCache=true`).then(setAuction);
	}, [id]);

	if (!objects || !auction)
		return (
			<div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<div className="loader"></div>
			</div>
		);

	return (
		<Style>
			<Card size="medium" className="hide-print">
				<h2>Så funkar utropslistan</h2>
				<h5>(denna ruta visas inte vid utskrift)</h5>
				<div className="spacer s"></div>
				<p>Denna sida kan skrivas ut direkt i webbläsaren, antingen som en PDF eller via skrivare. Objekten hämtas automatiskt, informationen om auktionen hämtas också automatiskt från auktionens "metadata". Respektive "nyckel" syns i tabellen nedanför:</p>
				<div className="spacer s"></div>
				<table className="auction-meta-table">
					<tr>
						<th>Namn på denna sida</th>
						<th style={{ textAlign: 'right' }}>Meta namn på auktionen</th>
						<th style={{ textAlign: 'right' }}>Standard</th>
					</tr>
					<tr>
						<th>Nedräkning startar</th>
						<td>Avslut</td>
					</tr>
					<tr>
						<th>Visningstid</th>
						<td>Visning</td>
					</tr>
					<tr>
						<th>Utlämning</th>
						<td>Utlämning</td>
					</tr>
					<tr>
						<th>Betalsätt</th>
						<td>Betalsätt</td>
						<td>Kontant eller kort (VISA/Mastercard)</td>
					</tr>
					<tr>
						<th>Adress för utlämning och visning</th>
						<td>Adress</td>
					</tr>
					<tr>
						<th>Övrig information</th>
						<td>Går inte att ändra</td>
					</tr>
				</table>
			</Card>

			<Card size="full">
				<div className="hide-print">
					<button onClick={() => window.print()}>Skriv ut</button>
					<div className="spacer m"></div>
				</div>

				<h2>
					<strong>{auction.name}</strong>
				</h2>
				<p>VIKTIG INFORMATION OM DENNA AUKTION</p>
				<div className="spacer m"></div>

				<table className="auction-meta-table">
					{getAuctionMetaValue(auction, 'Avslut') && (
						<tr>
							<th>Nedräkning startar:</th>
							<td>{getAuctionMetaValue(auction, 'Avslut')}</td>
						</tr>
					)}

					{getAuctionMetaValue(auction, 'Visning') && (
						<tr>
							<th>Visningstid:</th>
							<td>{getAuctionMetaValue(auction, 'Visning')}</td>
						</tr>
					)}

					{getAuctionMetaValue(auction, 'Utlämning') && (
						<tr>
							<th>Utlämning:</th>
							<td>{getAuctionMetaValue(auction, 'Utlämning')}</td>
						</tr>
					)}

					<tr>
						<th>Betalsätt:</th>
						<td>{getAuctionMetaValue(auction, 'Betalsätt') ? getAuctionMetaValue(auction, 'Betalsätt') : 'Kontant eller kort (VISA/Mastercard)'}</td>
					</tr>

					{getAuctionMetaValue(auction, 'Adress') && (
						<tr>
							<th>Adress för utlämning och visning:</th>
							<td>{getAuctionMetaValue(auction, 'Adress')}</td>
						</tr>
					)}

					<tr>
						<th>Övrig information:</th>
						<td>Endast utlämning på ovanstående tid och plats</td>
					</tr>
				</table>

				<div className="spacer m"></div>

				<h5>Registrera er på</h5>
				<h2>riksauktioner.se</h2>

				<div className="spacer m"></div>

				<table className="objects-table">
					{objects.map((o) => (
						<tr>
							<td>{o.position}</td>
							<td>{o.title}</td>
							<td></td>
						</tr>
					))}
				</table>
			</Card>
		</Style>
	);
}

const Style = styled(Page)`
	p {
		font-size: 12px;
	}

	.auction-meta-table {
		tr {
			*:first-child {
				padding-right: 3rem;
			}
		}
		th {
			text-align: left;
		}

		td {
			text-align: right;
		}
	}

	.objects-table {
		background-color: White;
		font-size: 12px;
		width: 100%;
		border-collapse: collapse;

		td {
			padding: 3px 10px;
			border-color: #666666;
			border-width: 1px;
			border-style: Solid;

			&:last-child {
				width: 50%;
			}
		}
	}
`;
