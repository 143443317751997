import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useClosePopup } from '../../../utils/hooks/usePopup';
import Popup from '../../Popup';
import Button from '../../../elements/Button';
import sha256 from 'sha256';
import API from '../../../utils/api/API';
import Users from '../../../utils/api/Users';
import Customers from '../../../utils/api/Customers';
import Sellers from '../../../utils/api/Sellers';

export default function DeleteSeller({ seller, onDeleted }) {
	let history = useHistory();
	const close = useClosePopup();

	const confirm = async () => {
		// Update password
		await Sellers.delete(seller.id);

		onDeleted && onDeleted();

		close();
	};

	return (
		<Popup close={close} width="40em" maxWidth="25em">
			<Style>
				<h2 className="title">Ta bort säljare</h2>
				<div className="spacer s"></div>
				<p>Denna säljare samt all data kommer att tas bort permanent, åtgärden kan inte ångras, är du säker?</p>

				<div className="spacer xs"></div>

				<div className="buttons">
					<Button type="secondary" onClick={close}>
						Avbryt
					</Button>
					<Button onClick={confirm}>Ja, ta bort</Button>
				</div>
			</Style>
		</Popup>
	);
}

const Style = styled.div``;
