import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Page from '../../layout/Page';
import { useUser } from 'src/pn-trading-sdk/utils/UserToken';
import Employees, { Employee } from 'src/utils/api/employees/Employees';
import EmployeeCalendar from 'src/pages/employees/components/EmployeeCalendar';
import Card from 'src/layout/Card';

export default function ProfileTimePage(props: any) {
	const authUser = useUser() as any;
	let uid = authUser?.id;


    
    const [employee, setEmployee] = useState<null | Employee>(null);

    const load = useCallback(async () => {
		if (!uid) return;
        let employee = await Employees.get(uid);
        setEmployee(employee);
        return employee;
    }, [uid]);


    useEffect(() => {
        load();
    }, [uid, load])


    if (employee === null) {
        return (
            <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '100' }}>
                <div className="loader"></div>
            </div>
        )
    }

	return (
		<>
			<Style>
				<Card size="full">
					<EmployeeCalendar employee={employee} />
				</Card>
			</Style>
		</>
	);
}


const Style = styled(Page)`

`;
