import API from './API';

export type User = {
	id: number;
	username: string;
	email: string;
	first_name: string;
	last_name: string;
	phone: string;
	company_name: string;
	org_nr: string;
	permissions: string;
	verified: boolean;
	email_confirm: boolean;
	verification_code: number;
	created: string;
	blocked: boolean;
	blocked_reason: string;
	account_type: "private" | "company";
};

export default class Users {
	static async list({ page = 0, limit = 25, search } = { page: 0, limit: 25, search: '' }) {
		return await API.get(`/users?page=${page}&limit=${limit}${search ? `&search=${search}` : ''}`);
	}

	static async get(id: any): Promise<User> {
		return await API.get(`/users/${id}`);
	}

	static async delete(id: any) {
		return await API.delete(`/users/${id}`);
	}
}
