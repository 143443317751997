import PNTrading from "src/pn-trading-sdk/index";
import { Event } from "src/utils/api/employees/Events";
import { uploadFile } from "src/utils/api/files/Files";


export type Expense = {
    id: number;
    created_at: Date;
    amount: number;
    description: string;
    event_id: string | null;
    file_id: number | null;
    user_id: number | null;
    paid_by_employee: boolean;
    file: null | {
        id: number;
        name: string;
        url: string;
        added: string;
    };
}

export type EmbeddedExpense = Expense & {
    user: null | {
        id: number;
        username: string;
        first_name: string;
        last_name: string;
        email: string;
        phone: string;
    };
    event: null | Event;
}

export type ExpenseListArgs = {
    user_id?: number;
    event_id?: number;
}

export type ExpenseListReturn = {
    expenses: Expense[];
}

export type CreateExpenseArgs = {
    amount: number;
    description: string;
    event_id?: string;
    file_id?: number;
    user_id?: number;
    paid_by_employee?: boolean;
    file?: File | null;
}

export async function createExpense(args: CreateExpenseArgs): Promise<EmbeddedExpense | null> {

    let _args = {
        ...args,
    }

    if (args.file) {
        const uploadedFile = await uploadFile(args.file);
        if (uploadedFile) {
            _args.file_id = uploadedFile.id;
        }
    }

    const resp = await PNTrading.post(`/v2/expenses`, _args);
    if (!resp.success) return null;
    return resp.expense;
}

export async function listExpenses(args?: ExpenseListArgs): Promise<ExpenseListReturn> {
    let url = `/v2/expenses`;

    if (args) {
        url += `?`;
        if (args.user_id) {
            url += `user_id=${args.user_id}&`;
        }
        if (args.event_id) {
            url += `event_id=${args.event_id}&`;
        }

        url = url.slice(0, -1);
    }

    const resp = await PNTrading.get(url);
    if (resp.success !== true) return {
        expenses: []
    };

    return {
        expenses: resp.expenses
    };
}

export async function getExpense(id: number): Promise<EmbeddedExpense | null> {
    const resp = await PNTrading.get(`/v2/expenses/${id}`);
    if (resp.success !== true) return null;
    return resp.expense;
}