import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useClosePopup } from '../../../utils/hooks/usePopup';
import Popup from '../../Popup';
import Button from '../../../elements/Button';
import sha256 from 'sha256';
import API from '../../../utils/api/API';
import Users from '../../../utils/api/Users';

export default function DeleteUser({ user }) {
	let history = useHistory();
	const close = useClosePopup();

	const confirm = async () => {
		// Update password
		await Users.delete(user.id);

		close();
	};

	return (
		<Popup close={close} width="40em" maxWidth="25em">
			<Style>
				<h2 className="title">Ta bort användare</h2>
				<div className="spacer s"></div>
				<p>Denna användare och all data kommer att tas bort, är du säker?</p>

				<div className="spacer xs"></div>

				<div className="buttons">
					<Button type="secondary" onClick={close}>
						Avbryt
					</Button>
					<Button onClick={confirm}>Ja, ta bort</Button>
				</div>
			</Style>
		</Popup>
	);
}

const Style = styled.div``;
