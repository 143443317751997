import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import API from '../utils/api/API';
import Button from '../elements/Button';
import { useClosePopup } from '../utils/hooks/usePopup';
import Objects from '../utils/api/Objects';
import Images from '../utils/api/Images';
import Icon from '../elements/Icon';

export default function MediaLibraryPane({
	// Override the images.
	images,

	// Override the load images function, should be an async function that returns an array of images.
	loadImages,

	// Will disable loading in the component, useful if the images should be overridden.
	preventLoading = false,

	// Set the default selected image.
	defaultImage,

	// Callback function once an image is choosen.
	onChange,

	// Callback function when an image is selected.
	onSelect,

	// Callback function when an image is clicked on.
	onClick,

	// Is selcting images enabled?
	allowSelect = true,

	// Is uploads enabled?
	allowUpload = true,

	// Is the sidepanel enabled?
	enableSidepanel = true,

	// Is the pagination function enabled?
	enablePagination = true,

	// If true, the height will be set to a fixed value.
	forcedHeight = true,

	// Set to a function that returns a component. This component will be added to the files element.
	appendFileElement,

	// Number of columns in the grid layout.
	columns = 8,
}) {
	const close = useClosePopup();

	const previewImage = useRef();

	const limit = enablePagination ? 50 : 100000;

	const [files, setFiles] = useState(images ? images : []);
	const [loading, setLoading] = useState(false);
	const [loadingPreview, setLoadingPreview] = useState(false);
	const [selected, setSelected] = useState(defaultImage);
	const [totalImages, setTotalImages] = useState(0);
	const [page, setPage] = useState(0);

	let pages = Math.ceil(totalImages / limit);

	useEffect(() => {
		if (images) setFiles(images);
	}, [images]);

	// Load images.
	useEffect(() => {
		if (preventLoading) return;

		(async () => {
			if (loadImages) setFiles(await loadImages());
			else {
				let res = await Images.list({ page, limit });
				setFiles(res.data);
				setTotalImages(res.total_items);
			}
		})();
	}, [page]);

	const upload = async (e) => {
		e.preventDefault();

		setLoading(true);

		let formData = new FormData(e.target);

		let response = await fetch(`${API.getURL()}/media`, {
			method: 'POST',
			body: formData,
			headers: {
				authorization: `Bearer ${localStorage.getItem('user')}`,
			},
		});

		let res = await Images.list({ page, limit });
		setFiles(res.data);
		setTotalImages(res.total_images);
		setLoading(false);

		console.log(response);
	};

	let selectedImage = files.find((f) => f?.id == selected);

	useEffect(() => {
		if (selected != undefined && onSelect) onSelect(files.find((i) => i.id == selected));

		if (previewImage.current == undefined) return;

		// Skip this if the image is already loaded.
		if (previewImage.current.complete && previewImage.current.naturalHeight !== 0) return;

		setLoadingPreview(true);
		const load = () => {
			setLoadingPreview(false);
		};

		previewImage.current.addEventListener('load', load);
		return () => previewImage.current && previewImage.current.removeEventListener('load', load);
	}, [selected]);

	return (
		<Style
			style={{
				['--columns']: columns,
			}}
			className={`${selectedImage && enableSidepanel ? 'has-selected' : ''} ${forcedHeight ? 'forced-height' : ''}`}
		>
			{allowUpload && (
				<>
					<form onSubmit={upload}>
						<input required multiple={false} name="image" type="file" />
						<input type="submit" value="Ladda upp" />
					</form>

					<div className="spacer s"></div>
				</>
			)}

			<div className="wrapper-row">
				<div className="wrapper">
					<div className="files">
						{loading && (
							<div className={`file loading`}>
								<img src="https://via.placeholder.com/150x150/eee?text=%20" alt="" />
								<h4>Laddar upp...</h4>
							</div>
						)}

						{files?.map((file) => {
							if (!file) return <></>;

							return (
								<div
									data-id={file.id}
									onClick={() => {
										if (onClick && typeof onClick == 'function') onClick(file);
										if (onClick && typeof onClick == 'object') onClick[0](file, onClick[1]);

										if (allowSelect) selected != file.id ? setSelected(file.id) : setSelected(undefined);
									}}
									key={file.id}
									className={`file ${selected == file.id ? 'selected' : ''}`}
								>
									<img src={file.sizes['150x150']} />
									<h4>{file.name.split('/').reverse()[0]}</h4>
								</div>
							);
						})}

						{appendFileElement && typeof appendFileElement == 'function' && appendFileElement()}
						{appendFileElement && typeof appendFileElement == 'object' && appendFileElement[0](appendFileElement[1])}
					</div>
				</div>

				<div className="side-panel">
					{selected && selectedImage && enableSidepanel && (
						<>
							<div className="image">{loadingPreview ? <div className="loader"></div> : <img ref={previewImage} src={selectedImage?.sizes['1920']} />}</div>

							<div className="spacer m"></div>
							<h3>{selectedImage.name}</h3>

							<div className="spacer auto"></div>

							<Button
								onClick={async () => {
									if (onChange) onChange(selectedImage);
									close();
								}}
							>
								Välj bild
							</Button>

							<div className="spacer s"></div>

							<Button
								type="delete"
								onClick={async () => {
									// Delete the image.
									let result = await Images.delete(selected);
									setFiles((images) => images.filter((i) => i.id != selected));
									setSelected(false);
								}}
							>
								Ta bort bild
							</Button>
							<div className="spacer xs"></div>
							<label style={{ textAlign: 'center' }}>
								Varning! Detta tar bort bilden från mediabiblioteket!
								<br />
								Denna åtgärd kan ej ångras!
							</label>
						</>
					)}
				</div>
			</div>

			{enablePagination && totalImages && !isNaN(pages) && (
				<div className="controls">
					<div className="wrapper">
						<Icon
							onClick={() => {
								if (page != 0) setPage(page - 1);
							}}
						>
							chevron_left
						</Icon>

						<div className="numbers">
							{(() => {
								let r = [];

								let offset = Math.max(page - pages + 3, 0);

								for (let i = 0; i < 5; i++) {
									let ii = i + Math.max(page, 2) - 2 - offset;

									r.push(
										<div key={ii} onClick={() => setPage(ii)} className={`${ii == page ? 'current' : ''}`}>
											{ii + 1}
											{/* {offset} */}
										</div>
									);
								}

								return r;
							})()}

							{/* {new Array(pages).fill(0).map((_,i) => 
                            <div key={i} onClick={() => setPage(i)} className={`${i == page ? 'current' : ''}`}>{i+1}</div>
                        )} */}
						</div>

						<Icon
							onClick={() => {
								if (page != pages - 1) setPage(page + 1);
							}}
						>
							chevron_right
						</Icon>
					</div>

					<p>
						{page * limit + 1} - {Math.min((page + 1) * limit, totalImages)} av {totalImages} bilder
					</p>
				</div>
			)}
		</Style>
	);
}

const Style = styled.div`
	& > .wrapper-row {
		display: flex;

		& > .side-panel {
			background: white;
			box-shadow: var(--shadow);
			border-radius: 1em;
			display: none;
			flex-direction: column;
			padding: 1em;
			width: 30em;
			flex-shrink: 0;
			margin-left: 1em;

			.image {
				background: #fafafa;
				border: solid #eee 2px;
				height: 20em;
				border-radius: 1em;
				display: flex;
				align-items: center;
				justify-content: center;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}

		& > .wrapper {
			overflow: auto;
			position: relative;

			.files {
				display: grid;
				gap: 0.5em;
				grid-template-columns: repeat(var(--columns), 1fr);

				.file {
					min-width: 0;
					min-height: 0;
					border: solid 0.15em transparent;
					border-radius: 0.5em;
					padding: 0.5em;
					cursor: pointer;

					img {
						width: 100%;
						border-radius: 0.5em;
						background: #eee;
					}

					h4 {
						text-align: center;
						font-size: 0.8em;
						opacity: 0.5;
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;
						width: 100%;
					}

					&.selected {
						border-color: var(--color-primary);
					}
				}
			}
		}
	}

	.controls {
		display: flex;
		align-items: center;
		flex-direction: column;
		margin-top: 1em;

		.wrapper {
			display: flex;
			align-items: center;

			.icon {
				font-size: 2em;
			}

			.numbers {
				display: flex;
				align-items: center;

				div {
					user-select: none;
					cursor: pointer;
					width: 2em;
					height: 2em;
					display: flex;
					align-items: center;
					justify-content: center;
					margin: 0 0.2em;

					&.current {
						background: var(--color-primary);
						border-radius: 0.4em;
						color: white;
					}
				}
			}
		}

		p {
			margin-top: 1em;
			opacity: 0.5;
			font-size: 0.75em;
		}
	}

	&.forced-height {
		& > .wrapper-row > .wrapper {
			height: 60vh;
		}
	}

	&.has-selected {
		.side-panel {
			display: flex;
		}

		.wrapper {
			.files {
				grid-template-columns: repeat(6, 1fr);
			}
		}
	}
`;
