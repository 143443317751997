import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import API from '../../utils/api/API';
import Button from '../../elements/Button';
import Popup from '../Popup';
import { useClosePopup } from '../../utils/hooks/usePopup';
import PNTrading from 'src/pn-trading-sdk';
import Table from '../../components/Table';
import ObjectsTable from '../../components/auction/ObjectsTable';
import Checkbox from '../../elements/Checkbox';
import AuctionSelect from '../../components/auction/AuctionSelect';

export default function CopyAuction({ auctionID, auction }) {
	let history = useHistory();
	const close = useClosePopup();

	const [state, setState] = useState('verify');
	const [targetAuctionID, setTargetAuctionID] = useState();

	const [itemsToCopy, setItemsToCopy] = useState({});

	const submit = async () => {
		setState('copy');
		let resp = await PNTrading.post(`/auctions/${auctionID}`, { exceptions: Object.fromEntries(Object.entries(itemsToCopy).filter(([k, v]) => v != undefined)) });
		setState('done');
	};

	const copyToAuction = async () => {
		const exceptions = Object.fromEntries(Object.entries(itemsToCopy).filter(([k, v]) => v != undefined));
		setState('copy');
		let resp = await PNTrading.post(`/auctions/${auctionID}/copy-objects-to/${targetAuctionID}`, { exceptions });
		setState('done');
	}


	return (
		<Popup close={close} width="60em" maxWidth="80em">
			<h2 className="title">{state == 'done' ? 'Auktionen kopierad!' : 'Kopiera auktion'}</h2>
			<div className="spacer m"></div>

			{state == 'done' && (
				<>
					<p>Auktionen är nu kopierad. Gå till auktionen och sätt ett nytt slutdatum samt publicera den för att den ska synas offentligt!</p>

					<div className="buttons">
						<Button type={'secondary'} onClick={close}>
							Stäng
						</Button>
						{/* <Button type={''} onClick={() => {
                        history.push(`/auctions/${newAuction.id}`)
                    }}>Till nya auktionen</Button> */}
					</div>
				</>
			)}

			{state == 'copy' && (
				<LoaderStyle>
					<div className="spacer l"></div>
					<div className="loader" />
					<div className="spacer s"></div>
					<p className="status">Kopierar auktionen och alla objekt...</p>
					<div className="spacer l"></div>
				</LoaderStyle>
			)}

			{state == 'verify' && (
				<>
					<p>
						Auktionen <strong>'{auction.name}'</strong> kommer att kopieras, välj alla objekt som ska kopieras nedan. Objekten som inte fått några bud är automatiskt ifyllda för kopiering men kan kryssas ur om de inte ska följa med.
					</p>
					<br />

					<ObjectsTable
						auction={auction.id}
						structure={[
							{
								heading: 'ID',
								key: 'id',
								component: (row) => <>{row.position} / {row.id}</>,
							},
							{
								heading: 'Modell',
								key: 'title',
								// sortable: true,
								component: (row) => {
									let text = row.title;
									return <>{text.length > 50 ? text.substring(0, 50) + '...' : text}</>;
								},
							},
							{
								heading: 'Status',
								// sortable: true,
								component: (row) =>
									({
										available: 'Tillgänglig',
										ended: 'Avslutad',
									}[row.status]),
							},
							{
								heading: '',
								// sortable: true,
								component: (row) => (!row.leading_bid ? 'Inga bud' : ''),
							},
							{
								heading: 'Hämtad',
								component: (row) => (row.tickoff ? 'Ja' : 'Nej'),
							},
							{
								heading: '',
								className: 'row-icons',
								component: (row) => {
									return (
										<div>
											<Checkbox
												onChange={(e) => {
													setItemsToCopy((i) => ({ ...i, [row.id]: e.target.checked ? (row.leading_bid ? true : undefined) : row.leading_bid ? undefined : false }));
												}}
												checked={!(itemsToCopy[row.id] == undefined ? row.leading_bid : !itemsToCopy[row.id])}
											/>
										</div>
									);
								},
							},
						]}
					/>

					<ButtonWrapper className="buttons">
						<Button type={'secondary'} onClick={close}>
							Avbryt
						</Button>
						<Button type={''} onClick={submit}>
							Okej
						</Button>
						<span class="sep">eller</span>
						<div className="copy-to-auction">
							<label>Kopiera till befintlig auktion</label>
							<AuctionSelect
								onSelect={({index, value}) => {
									setTargetAuctionID(value)
								}}
							/>
						</div>
						<Button type={''} onClick={copyToAuction}>
							Kopiera
						</Button>

					</ButtonWrapper>
				</>
			)}
		</Popup>
	);
}

const ButtonWrapper = styled.div`
	display: flex;
	align-items: flex-end;
	
	.sep {
		margin-left: 2rem;
		margin-right: 2rem;
		margin-bottom: 0.7rem;
	}

	.select {
		width: 300px;
	}
`

const LoaderStyle = styled.div`
	.loader {
		margin: auto;
	}

	.status {
		font-size: 0.8em;
		opacity: 0.6;
		margin: auto;
		text-align: center;
	}
`;
