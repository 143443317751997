import React from 'react';
import styled from 'styled-components';
import Icon from '../../elements/Icon';
import Page from '../../layout/Page';
import ChangePassword from '../../layout/popups/user/ChangePassword';
import usePopup from '../../utils/hooks/usePopup';
import { useUser } from 'src/pn-trading-sdk/utils/UserToken';
import UserDetailsCard from 'src/pages/user/UserDetailsCard';
import UserAddressCard from 'src/pages/user/UserAddressCard';

export default function MePage(props) {

	const authUser = useUser();

	let uid = authUser?.id;

	const [open] = usePopup();

	return (
		<>
			<TopNav>
				<div className="icon-button" onClick={() => open(<ChangePassword uid={uid} />)}>
					<Icon>edit</Icon>
					<span>Ändra lösenord</span>
				</div>
			</TopNav>

			<Style>
				<UserDetailsCard userID={uid} />

				<UserAddressCard userID={uid} />
			</Style>
		</>
	);
}

const TopNav = styled.div`
	width: 1500px;
	max-width: 100%;
	margin: auto;

	padding-top: 1.5rem;

	display: flex;
	justify-content: flex-end;
	align-items: center;

	.icon-button {
		cursor: pointer;
		display: flex;
		align-items: center;
		margin-left: 3rem;

		.icon {
			font-size: 1.5rem;
			margin-right: 0.5rem;
		}

		span {
			font-size: 1rem;
			color: var(--color-link);
		}
	}
`;

const Style = styled(Page)`

`;
