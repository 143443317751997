import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import API from '../../../utils/api/API';
import Button from '../../../elements/Button';
import Popup from '../../Popup';
import { useClosePopup } from '../../../utils/hooks/usePopup';
import Objects from '../../../utils/api/Objects';
import Images from '../../../utils/api/Images';
import Auctions from '../../../utils/api/Auctions';
import ImageSelector from '../../../elements/ImageSelector';
import { Context } from '../../../App';
import Customers from '../../../utils/api/Customers';

export default function CreateCustomer({}) {
	let history = useHistory();
	const close = useClosePopup();

	const [error, setError] = useState({ message: '', visible: false });

	const submit = async (e) => {
		e.preventDefault();
		let form = e.target;

		let name = form.querySelector('input[name="company_name"]').value;

		let data = {
			name,
		};

		let result = await Customers.create(data);

		if (result?.error == 'ER_DUP_ENTRY') {
			setError({
				message: `Organisationsnummret är redan taget och måste vara unikt!`,
				field: 'org_nr',
				visible: true,
			});
			return;
		}

		if (result) {
			let cache = API.getCached(`/customers_`);
			if (cache) {
				cache.result = [...cache.result, result];
				// setAuctions(cache.result);
				API.updateCache(`/customers_`, cache);
			}
			close();
			history.push(`/customers/${result.id}`);
		}
	};

	return (
		<Popup close={close} width="40em" maxWidth="80em">
			<Style onSubmit={submit}>
				<h2 className="title">Skapa Uppdragsgivare</h2>
				<div className="spacer m"></div>

				<div className="fields">
					<label htmlFor="company_name">Företagsnamn *</label>
					<br />
					<input required type="text" name="company_name" />

					{error?.visible && (
						<>
							<div className="spacer xs"></div>
							<p className="label error">{error?.message}</p>
						</>
					)}
				</div>

				<div className="spacer s"></div>

				<div className="buttons">
					<Button type={'secondary'} onClick={close}>
						Avbryt
					</Button>
					<input className="button" type="submit" value="Spara" />
				</div>
			</Style>
		</Popup>
	);
}

const Style = styled.form`
	.fields {
		width: 100%;

		input,
		textarea {
			width: 100%;
			resize: none;
		}

		.error {
			color: rgb(255, 161, 161);
		}
	}
`;
