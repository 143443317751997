import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import API from '../../utils/api/API';
import Button from '../../elements/Button';
import Popup from '../Popup';
import { useClosePopup } from '../../utils/hooks/usePopup';
import Objects from '../../utils/api/Objects';
import Images from '../../utils/api/Images';
import MediaLibraryPane from '../MediaLibraryPane';

export default function MediaLibrary({ defaultImage, onChange }) {
	const close = useClosePopup();

	return (
		<Popup close={close} width="80em" maxWidth="80em">
			<h2 className="title">Media bibliotek</h2>

			<div className="spacer l"></div>
			<MediaLibraryPane defaultImage={defaultImage} onChange={onChange} />
		</Popup>
	);
}
