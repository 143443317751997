const { useState, useEffect } = require("react");
const jwt_decode = require("jwt-decode");
const sha256 = require("sha256");
const { getUserToken } = require("./utils/UserToken");
const { getSettings } = require("./PNConfig");
// 
// import RNRestart from 'react-native-restart'; // Import package from node modules


/**
 * This is the official SDK for the PN Trading platform.
 * The SDK is used to comunicate with the backend API from all frontend projects.
 */

// const URL = `http://localhost:4000`
// const URL = 'http://192.168.1.126:4000';
// const URL = `http://172.20.10.4:4000`


let blockRequests = false;
class PNTrading {

    static request(ep, body, data) {
        if (!getSettings() && !getSettings().PN_SDK_REST_URL) throw new Error(`'URL' cannot be undefined, add 'PN_SDK_REST_URL' in your .env file!`)
        if (blockRequests) return undefined

        if (getSettings().DEBUG) console.log(`[SDK] [${new Date()}] - (${data.method ? data.method : 'GET'}) ${ep}`);
        
        return new Promise((res, rej) => {
            
            let auth = {}

            getUserToken()
                .then(async token => {

                    if (token) auth['Authorization'] = `Bearer ${token}`;

                    let response = undefined;

                    try {

                        response = await fetch(`${getSettings().PN_SDK_REST_URL}${ep}`, {
                            ...data, 
                            body: JSON.stringify(body), 
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                ...auth,
                                ...data ? data.headers : {},
                            }
                        })
                        // Catch http error codes.
                        if (![
                            200,
                            201,
                        ].includes(response.status)) throw response.status

                        let blocked = response.headers.get('PN-User-blocked');

                        if (blocked && blocked != '') {
                            return 'blocked';
                        }


                        res(await response.json())

                    } catch(e) {

                        console.error(e)

                        if (e == '401') {

                            blockRequests = true;
                            if (typeof localStorage == 'object') {
                                localStorage.removeItem('_pn_241031_usertoken')
                            }
                        
                            // Alert.alert(
                            //     'Ogiltig inloggning',
                            //     'Du har inte behörighet att skicka följande förfrågan till servern och har blivit utloggad!',
                            //     [
                            //         {
                            //             text: 'Okej',
                            //             style: 'default',
                            //             onPress: () => {
                            //                 RNRestart.Restart();
                            //             }
                            //         },
                            //     ]
                            // );

                            return '401';
                        }

                        let err = undefined;

                        try {
                            err = {
                                response: await response.json(),
                                ep,
                                error: e
                            }
                        } catch(ee) {
                            err = {
                                response: await response.text(),
                                ep,
                                error: e
                            }
                        }

                        console.error(err)
                        rej(err);
                    }




                })
                .catch(rej)

        })
    }

    static currentRequests = {};
    static async get(ep, body, data) {
        this.currentRequests[ep] = this.currentRequests[ep] || this.request(ep, body, {
            ...data,
            method: 'GET'
        });
        const response = await this.currentRequests[ep];
        delete this.currentRequests[ep];
        return response;
    }

    static async post(ep, body, data) {
        return await this.request(ep, body, {
            ...data,
            method: 'POST'
        });
    }

    static async put(ep, body, data) {
        return await this.request(ep, body, {
            ...data,
            method: 'PUT'
        });
    }

    static getURL() {
        return getSettings().PN_SDK_REST_URL;
    }
}

module.exports = PNTrading;

