import { createContext, useContext } from "react"
import useModel from "./hooks/useModel"

const context = createContext()

export default function Store({ children }) {
    const store = useModel({})
    return <context.Provider value={{store}} >{children}</context.Provider>
}

export function useStore() {
    const {store} = useContext(context)
    return store
}