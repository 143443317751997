import React, { useContext } from 'react';
import styled from 'styled-components';
import { Context } from '../App';

export default function BackgroundLoader() {
	const { loading } = useContext(Context);

	return <Style>{loading && <div className="loader"></div>}</Style>;
}

const Style = styled.div`
	position: fixed;
	z-index: 10;
	bottom: 1rem;
	right: 1rem;

	.loader {
		font-size: 2rem;
	}
`;
