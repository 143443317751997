import { useMemo, useState } from "react";

export default function useModel(o, { localStorageKey } = {}) {
    const [,setCount] = useState(0)
    const proxy = useMemo(createProxy(o, setCount, localStorageKey), [])
    return proxy
}

function createProxy(o, setCount, localStorageKey) {
    return () => new Proxy(o, {
        set: (target, key, v) => {

            let sync = true;
            let value = v;

            if (typeof value === 'object' && value != null) {
                if (value.modelNoSync === true && value.value !== undefined) {
                    sync = false;
                    value = v.value;
                } else if (value.modelNoSync === false && value.value !== undefined) {
                    sync = true;
                    value = v.value;
                }
            }

            if (localStorageKey && typeof localStorage === 'object') localStorage.setItem(`_model_${localStorageKey}_${key}`, JSON.stringify(v))

            if (sync) setCount(c => (c + 1) % Number.MAX_SAFE_INTEGER)

            return Reflect.set(target, key, value)
        },
        get: (target, key) => {
            
            let value = Reflect.get(target, key);

            if (localStorageKey && typeof localStorage === 'object') {
                let stored = JSON.parse(localStorage.getItem(`_model_${localStorageKey}_${key}`));
                if (stored != null) {
                    value = stored;
                }
            }

            if (value === null) return null;

            if (typeof value === 'object') {
                return createProxy(value, setCount, `${localStorageKey}_${key}`)()
            }

            return value;
        },
        has: (target, p) => {
            return Reflect.has(target, p)
        },
        ownKeys: (target) => {
            return Reflect.ownKeys(target)
        }
    })
}

export function dontRender(value) {
    return {modelNoSync: true, value}
}