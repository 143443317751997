import PNTrading from "src/pn-trading-sdk";
import { useEffect } from "react";
import { useStore } from "../Store";

/**
 * 
 */
export default function useAutoBids(object) {

    const store = useStore()
    const key = `useAutoBids_${object?.id}`

    useEffect(async () => {
        if (object == undefined || store[key]) return;

        if (object.user_auto_bids != undefined) {
            store[key] = object.user_auto_bids;
            return;
        }

        store[key] = await fetchAutoBids(object)
    }, [ object ])

    return store[key];
}


/**
 * Fetches the auto bids for the current user as these cannot be included 
 * in the original object request.
 * 
 * The auto bids are stored in the global store.
 */
export async function fetchAutoBids(object) {
    let resp = await PNTrading.get(`/v2/bids/auto/${object.id}`)
    return resp.autoBids
}